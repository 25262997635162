<template>
  <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Save'"
      :sync="show"
      :modalTitle="'Select Your Default View'"
      :modalType="'slot'"
      @confirmButtonClick="saveButtonClicked"
      @noButtonClick="cancelButtonClicked"
  >
    <md-radio v-model="selectedOption" class="radio-button" :value="options.document">
      Document View
    </md-radio>
    <md-radio v-model="selectedOption" class="radio-button" :value="options.data">
      Data View
    </md-radio>
    <md-radio v-model="selectedOption" class="radio-button" :value="options.pdf">
      PDF View
    </md-radio>
  </VasionGeneralModal>
</template>

<script>
import { DocumentViewOptions } from '@/enums/documentViewOptions.js'

export default {
  name: 'DefaultDocumentViewSelectionModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    currentSelection: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    currentSelection: function() {
      this.selectedOption = this.currentSelection
    }
  },
  data: function() {
    return {
      options:{
        data: DocumentViewOptions.Data,
        document: DocumentViewOptions.Document,
        pdf: DocumentViewOptions.Pdf
      },
      selectedOption: this.currentSelection
    }
  },
  methods: {
    cancelButtonClicked() {
      this.$emit('cancelButtonClicked')
    },
    async saveButtonClicked() {
      this.$emit('saveButtonClicked')
      await this.$store.dispatch('admin/setUserSetting', {settingName: 'defaultDocumentView', settingValue: this.selectedOption })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/light-theme-system-palette.scss';

.radio-button {
  display: flex;
  margin-left: 1.5rem;
}
::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
