<template>
  <div id="document-notes">
    <VasionGeneralModal
      id="notesModal"
      :modalType="'slot'"
      buttonGroup="center"
      :hideButtons="true"
      :sync="showModal"
      @noButtonClick="showModal = !showModal"
    >
      <div v-if="notes && notes.tableHeaders && noteMode === 'view'" class="notesGridDiv">
        <div v-if="notes.tableRows.length > 0" class="vasion-html-table-default">
          <VasionTable
            :headerColumns="notes.tableHeaders"
            :tableRows="notes.tableRows"
            :hideColumns="hideColumns"
            @vasion-selection="handleNoteSelect"
            @vasion-icon-click="handleIconClick"
          />
        </div>
        <div v-else>
          No notes available for this document
        </div>
        <div v-if="canAddEditDocumentNotes" class="notesFieldsDiv">
          <div class="textarea-title">
            Note
          </div>
          <textarea
            v-model="noteText"
            class="notes-textarea"
            placeholder="Enter New Note"
            rows="2"
          />
          <VasionInput
            v-model="noteAttachmentPageNumber"
            inputType="top-white"
            width="180"
            placeholder="Enter Page Number"
            title="Attachment Page Number"
            specialType="number"
            :minValue="0"
          />
        </div>
        <div class="alignRight">
          <VasionButton
            v-if="canAddEditDocumentNotes"
            id="save-document-note"
            :classProp="'primary'"
            :isDisabled="noteText === ''"
            @vasionButtonClicked="saveNote()"
          >
            Save
          </VasionButton>
          <VasionButton
            v-if="canAddEditDocumentNotes"
            id="clear-document-note"
            :classProp="'primary'"
            @vasionButtonClicked="clearNote()"
          >
            Clear
          </VasionButton>
          <VasionButton id="close" :classProp="'primary'" @vasionButtonClicked="showModal = !showModal">
            Close
          </VasionButton>
        </div>
      </div>
      <div v-if="noteMode === 'delete'">
          <div class="spacer">
            Are you sure you want to remove this note?
          </div>
          <div class="notes-modal-buttons">
            <VasionButton id="cancel" :classProp="'secondary'" @vasionButtonClicked="noteMode = 'view'">
              Cancel
            </VasionButton>
            <VasionButton id="remove" :classProp="'primary'" @vasionButtonClicked="deleteNote()">
              Remove
            </VasionButton>
          </div>
        </div>
    </VasionGeneralModal>

    <VasionSnackbar
      id="snack-bar"
      :showRawHtml="false"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

  </div>
</template>

<script>
export default {
  name: 'TheDocumentNotes',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    documentId: {
      type: Number,
      required: true,
    }
  },
  data: function () {
    return {
      noteAttachmentPageNumber: '',
      noteId: 0,
      noteMode: 'view',
      noteText: '',
      selectedDeleteNote: [],
      showModal: this.active,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    canAddEditDocumentNotes() { return this.$store.getters['common/canAddEditDocumentNotes'] },
    canDeleteDocumentNotes() { return this.$store.getters['common/canDeleteDocumentNotes'] },
    hideColumns() {
      var hide = ['_NoteID_']
      if (!this.canDeleteDocumentNotes) {
         hide.push('_VasionIconButton_')
      }
      return hide
    },
    notes() { return this.$store.state.document.notes },
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      }
    },
  },
  methods: {
    clearNote() {
      this.noteId = 0
      this.noteText = ''
      this.noteAttachmentPageNumber = ''
    },
    async deleteNote() {
      this.isLoading = true
      const error = await this.$store.dispatch('document/deleteDocumentNote', this.selectedDeleteNote[0])
      this.noteMode = 'view'
      if (error) {
        this.snackbarTitle = 'Deletion error'
        this.snackbarSubTitle = error
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }
      this.isLoading = false
    },
    handleIconClick(clickInfo) {
      if (clickInfo.index === 5) {
        this.noteMode = 'delete'
        this.selectedDeleteNote = clickInfo.item.Values
      }
    },
    handleNoteSelect(selectInfo) {
      if (!selectInfo.Values || selectInfo.Values.length <= 0 || selectInfo.Values[0] <= 0) {
        return
      }
      [this.noteId, this.noteText, this.noteAttachmentPageNumber] = [selectInfo.Values[0], selectInfo.Values[3], selectInfo.Values[4]]
      if (selectInfo.Index === 4) {
        this.$emit('goToPage', { pageNumber: this.noteAttachmentPageNumber })
        this.$emit('close')
      }
    },
    saveNote() {
      if (this.noteId !== 0) {
        const payload = {
          DocumentId: this.documentId,
          NoteId: this.noteId,
          NoteText: this.noteText,
          PageNumber: this.noteAttachmentPageNumber,
        }
        this.$store.dispatch('document/updateNote', payload)
      } else {
        const payload = {
          DocumentId: this.documentId,
          NoteText: this.noteText,
          PageNumber: this.noteAttachmentPageNumber,
        }
        this.$store.dispatch('document/saveNote', payload)
      }
      this.clearNote()
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .alignRight {
    text-align: right;
  }

  .notesGridDiv {
    padding: 10px 10px 0px 10px;
    overflow: auto;
    min-height: 50px;
    min-width: 550px;
  }

  .notesFieldsDiv {
    padding: 10px 10px 0px 10px;
  }

  .notes-textarea{
    width: 100%;
    border-radius: 8px;
    background-color: white;
    border-color: white;
    border: solid 1px #e4e5e6;
    padding: 10px;
    color: #172027;
    margin-bottom: 10px;
  }

  .vasion-textarea::placeholder{
    color: #999;
  }

  .textarea-title{
    height: 12px;
    font-family: "Gotham Medium", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #838587;
    margin-bottom: 8px;
    position: relative;
    bottom: 2px;
  }
  .spacer {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .notes-modal-buttons {
    text-align: center;
  }

  @media(max-width: $tablet){
    .notesGridDiv {
      min-width: auto;
    }
    .notesFieldsDiv {
      width: 100%;
      padding: 10px;
    }
  }
</style>,
