<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <VasionGeneralModal
      id="packagersModal"
      :sync="showModal"
      :modalType="'slot'"
      :hideButtons="true"
      :showTopRightCloseButton="true"
      :closeOnClickAway="false"
      @noButtonClick="showModal = false"
    >
      <div class="vap-page-content">
        <p v-if="packages.length < 1">
          There are no packages related to this document
        </p>
        <div v-if="packages.length > 1" class="packages-sidebar">
          <label class="packages-sidebar-label">Packages</label>
          <div class="packages-list">
            <ul>
              <li
                v-for="(pkg, index) in packages"
                :key="index"
                :class="{ 'selected-package': currentIndex === index}"
                class="package-group"
                @click="changePackage(index)"
              >
                <div>
                  <div
                    class="package-group-package-name"
                    @mouseover="toolTipDisplay($event)"
                    @mouseleave="toolTipClose($event)"
                  >
                    {{ pkg.PackageName }}
                    <span class="full-text" v-html="pkg.PackageName" />
                  </div>
                  <div v-if="pkg.IsSubmitted === true" class="check">
                    &check;
                  </div>
                  <div v-else class="badge" :style="{ width: badgeWidth(pkg) +'px' }">
                    {{ badgeText(pkg) }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="packages-table main-table-container vasion-html-table-default auto-height" :class="{'fullwidth': packages.length === 1}">
          <label class="current-package-label">{{ currentPackage.PackageName }}</label>
          <VasionTable
            :headerColumns="tableHeaders"
            :tableRows="tableRows"
            :tableColStyles="tableColStyles"
            :documentIDIndex="0"
            :floatingThumbnail="true"
            :clickableRows="false"
            :sameColumnSize="true"
            :hideColumns="['_DocID_']"
          />
        </div>
      </div>
      <div class="m-footer">
        <VasionButton
          v-show="true"
          id="close"
          class="submit-button"
          :isDisabled="!canSubmitPackage || !canSubmitDocumentPackages"
          :classProp="'primary'"
          submitPackage
          @vasionButtonClicked="submitPackage"
        >
          {{ buttonText }}
        </VasionButton>
      </div>
    </VasionGeneralModal>
    <VasionSnackbar
      id="snackbar-textract-mapping"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'TheDocumentPackagers',
  components: {
    Loading,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    currentDocId: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      default: () => { return [] }
    },
  },
  data: function () {
    return {
      buttonText: '',
      canSubmitPackage: false,
      currentIndex: 0,
      currentPackage: {},
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      packages: [],
      showModal: this.active,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableHeaders: ["_DocID_", "File", "Required", "Document Exists"],
      tableColStyles: ["", "width: 375px; max-width: 375px;", "width: 150px; min-width: 150px; vertical-align: baseline;", "width: 150px; min-width: 150px; vertical-align: baseline;"],
      tableRows: [],
    }
  },
  computed: {
    canSubmitDocumentPackages() {return this.$store.getters['common/canSubmitDocumentPackages']},
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      }
    },
    data: function () {
      this.setPackagesInitData()
    },
  },
  async created() {
    this.setPackagesInitData()
    this.currentIndex = this.$route.query.packageId ? this.$route.query.packageId : 0
  },
  methods: {
    badgeText(pkg) {
      const haveRequired = pkg.FieldValuesViewer.filter(v => v.Values[2] === "Yes"  && v.Values[3] === 'Yes').length
      const required = pkg.FieldValuesViewer.filter(v => v.Values[2] === "Yes").length
      return `${haveRequired}/${required}`
    },
    badgeWidth(pkg) {
      const badgeText = this.badgeText(pkg)
      return (badgeText.length - 1) * 8 + 16
    },
    async setPackagesInitData() {
      if (!this.data || !this.data?.length === 0) {
        this.showModal = false
        return
      }

      const newData = await this.buildValidDataForTable(cloneDeep(this.data))

      this.packages = newData
      this.changePackage(0)
    },
    async buildValidDataForTable(data) {
      let packages = data
      data.forEach((pack, idx1) => {
        let newData = []
        pack.FieldValuesViewer.forEach(row => {
          const fieldValue = `${row.FieldValue}<br/>`
          if (row.DocNames.length > 0) {
            let registries = {documentList: true, fieldValue: row.FieldValue, packageId: pack.PackageID, currentDocId: this.currentDocId, documents: []}
            row.DocNames.forEach(doc => {
              registries.documents.push({id: doc.DocumentId, name: doc.Name })
            })
            newData.push({"Values": [0, registries, row.IsRequired ? 'Yes' : 'No', row.DocExists ? 'Yes' : 'No'] })
          } else {
            newData.push({"Values": [0, `${fieldValue}`, row.IsRequired ? 'Yes' : 'No', row.DocExists ? 'Yes' : 'No'] })
          }
        })
        packages[idx1].FieldValuesViewer = newData
      });
      return packages
    },
    async changePackage(index) {
      this.currentIndex = index
      this.currentPackage = this.packages[index]
      this.tableRows = this.currentPackage.FieldValuesViewer
      const missingRequired = this.currentPackage.FieldValuesViewer.filter(v => v.Values[2] === "Yes"  && v.Values[3] === 'No').length
      const required = this.currentPackage.FieldValuesViewer.filter(v => v.Values[2] === "Yes").length
      this.canSubmitPackage = !this.currentPackage.IsSubmitted && missingRequired === 0
      this.buttonText = (this.currentPackage.IsSubmitted) ? 'Package Submitted  ✓' : `Submit Package (${required - missingRequired}/${required})`
    },
    async submitPackage() {
      const response = await this.$store.dispatch('document/submitPackage', { Value: this.packages[this.currentIndex].PackageID })
      this.isLoading = true
      if (response && response.Success) {
        setTimeout(() => {
          this.isLoading = false
          this.showSnackbar = true
          this.snackbarImage = true
          this.snackbarSubTitle = 'The documents have been packaged.'
          this.snackbarTitle = 'Package Submission'
          setTimeout(() => {
            this.showModal = false
          }, 1200);
        }, 600);
        this.$emit('submitted')
      } else {
        this.isLoading = false
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarSubTitle = response.Message
        this.snackbarTitle = 'Package Submission'
      }
    },
    toolTipClose(event) {
      event.target.classList.remove('show-tooltip')
    },
    toolTipDisplay(event) {
      var rect = event.target.getBoundingClientRect()
      const toolTip = event.target.querySelector('span')
      toolTip.style.cssText = `margin-left: ${rect.left + 20}px; margin-top: ${rect.top + 20}px;`
      if (event.target.scrollWidth != event.target.clientWidth)
        event.target.classList.add('show-tooltip')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep #packagersModal .modal-container {
  padding: 8px;
  min-width: 50vw;
  min-height: 600px;
  max-height: 600px;
}
::v-deep #packagersModal .modal-container .slot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 16px 2px 8px 6px;
}

::v-deep #packagersModal .modal-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.m-body {
  flex: 1 1 auto;
  overflow-y: hidden;
}
.m-footer {
  text-align: end;
}

.packages-sidebar {
  float: left;
  width: 240px;
  text-align: center;

  .packages-sidebar-label {
    width: 100%;
    font-size: 20px;
    font-weight: 100;
    padding: 10px;
    border-bottom: 1px solid #CBC4CF;
    margin-bottom: 0px;
  }

  .packages-list {
    overflow-y: auto;
    height: 440px;
  }

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      height: 48px;
      line-height: 48px;

      .package-group-package-name {
        float: left;
        margin-left: 10px;
        width: 144px;
        max-width: 144px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        cursor: default;
      }

      .badge {
        font-weight: 100;
        float: right;
        margin-top: 14px;
        margin-right: 10px;
        width: 56px;
        background-color: #00a19b;
      }

      .check {
        margin-right: 20px;
        float: right;
        color: #3D2562;
      }
    }

    .selected-package {
      border-radius: 8px;
      background-color: #ECE9EF;
    }
  }
}

.packages-table {
  float: right;
  width: 730px;

  .current-package-label {
    width: 100%;
    height: 100%;
    font-size: 24px !important;
    font-weight: 700;
    padding-bottom: 15px;
    padding-left: 10px;
    margin-bottom: 0px;
    border-left: 1px solid #F5F4F7;
  }

  div {
    height: 440px !important;
    overflow-y: auto !important;
  }

  ::v-deep table {
    cursor: default;
  }
}

.fullwidth {
  width: 940px !important;
}

.full-text {
    height: 30px;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    border-radius: 6px;
    display:none;
    position:fixed;
    z-index:1001;
    border:1px;
    background-color:black;
    border-style:solid;
    padding: 5px;
    color:white;
    top:20px;
    left:20px;
  }

.show-tooltip span.full-text {
  display: block;
}

#packagersModal ::v-deep .slot { max-height: unset;}
.auto-height {
  height: auto !important;
}

::v-deep .table-rows {
  height: 60px;
}
::v-deep .vasion-html-table-default {
  table {
    height: auto;
  }
}
</style>
