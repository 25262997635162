<template>
  <div>
    <VasionGeneralModal
      rejectButtonText="CLOSE"
      confirmButtonText="SAVE"
      modalTitle="Line Items"
      :sync="showModal"
      modalType="slot"
      :showConfirmButton="canEditDocumentLineItem"
      :confirmButtonDisabled="!lineItemisDirty"
      @noButtonClick="validateModalClose"
      @confirmButtonClick="saveLineItems"
    >
      <div v-if="hasLineItemData" class="modal-container">
        <VasionDropList
          id="tables-list"
          :key="`droplist-${droplistKey}`"
          v-slot="slotItem"
          v-model="selectedTable"
          :dataArray="tablesList"
          title="Select Line Item Table"
          inputType="plain-list"
          valueName="name"
          displayName="name"
          @input="setLineItem"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <div class="fields-table-container">
          <FieldsTable
            v-if="hasSelectedLineItem"
            :key="`ft-${fieldsTableKey}`"
            :documentId="documentId"
            :dataTable="selectedLineItem"
            :tableIndex="tableIndex"
            :includeValidationColors="false"
            :isEditable="canEditDocumentLineItem"
            @updateField="updateField"
          />
        </div>
      </div>
      <div v-else class="no-data">
        No line items available for this document or selected list.
      </div>
    </VasionGeneralModal>

    <md-dialog
      id="line-item-exit-dialog"
      :md-active.sync="showValidateModalClose"
      :md-click-outside-to-close="false"
      :md-backdrop="false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to exit without saving your changes to these line items?"
        @noButtonClick="cancelModalClose"
        @yesButtonClick="confirmModalClose"
      />
    </md-dialog>
    <md-dialog
      id="line-item-table-change-dialog"
      :md-active.sync="showValidateModalTableChange"
      :md-click-outside-to-close="false"
      :md-backdrop="false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to change Line Item Table without saving your changes to these line items?"
        @noButtonClick="cancelLineItemChange"
        @yesButtonClick="confirmLineItemChange"
      />
    </md-dialog>
    <VasionSnackbar
      id="line-items-snackbar"
      ref="lineItemsSnackbar"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import FieldsTable from '@/components/general/FieldsTable.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'TheDocumentLineItems',
  components: {
    FieldsTable,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    documentId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      droplistKey: 0,
      fieldsTableKey: 0,
      initialLineItemTables: [],
      lineItemChanges: {},
      lineItemisDirty: false,
      lineItemTables: [],
      prevSelectedTable: '',
      selectedLineItem: null,
      selectedTable: '',
      selectedTableBuffer: '',
      showModal: this.active,
      showSnackbar: false,
      showValidateModalClose: false,
      showValidateModalTableChange: false,
      snackbarImage: true,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableIndex: -1,
      tablesList: [],
    }
  },
  computed: {
    canEditDocumentLineItem() { return this.$store.getters['common/canEditDocumentLineItem'] },
    hasLineItemData() { return this.lineItemTables.length > 0 },
    hasSelectedLineItem() { return this.selectedLineItem && this.selectedLineItem !== '' },
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    documentId: async function () {
      this.lineItemTables = await this.getLineItemsTables(this.documentId)
      this.tablesList = this.setTableList(this.lineItemTables)
      this.initialLineItemTables = cloneDeep(this.lineItemTables)
      this.selectedTable = (this.tablesList.length > 0) ? this.tablesList[0] : ''
      this.setLineItem(this.selectedTable)
    },
    showModal(newVal) {
      if (!newVal) {
        this.$emit('close')
      }
    },
  },
  async created() {
    if (this.documentId > 0) {
      this.lineItemTables = await this.getLineItemsTables(this.documentId)
      this.tablesList = this.setTableList(this.lineItemTables)
      this.initialLineItemTables = cloneDeep(this.lineItemTables)
      this.selectedTable = this.tablesList.length > 0 ? this.tablesList[0] : ''
      this.setLineItem(this.selectedTable)
    }
  },
  methods: {
    cancelLineItemChange() {
      this.showValidateModalTableChange = false
      this.selectedTable = this.prevSelectedTable
      this.setLineItem(this.selectedTable, false)
      this.droplistKey++
      this.fieldsTableKey++
    },
    cancelModalClose() {
      this.showValidateModalClose = false
    },
    confirmLineItemChange() {
      this.showValidateModalTableChange = false
      this.lineItemTables = cloneDeep(this.initialLineItemTables)
      this.lineItemisDirty = false
      this.lineItemChanges = {}
      this.fieldsTableKey++
    },
    confirmModalClose() {
      this.lineItemisDirty = false
      this.lineItemChanges = {}
      this.showValidateModalClose = false
      this.showModal = false
    },
    async getLineItemsTables(documentId) {
      const payload = { DocumentId: documentId, IncludeConfidence: true }
      const result = await this.$store.dispatch('document/getDocumentLineItems2', payload)
      const { lineItemTables = [] } = result || {}
      return lineItemTables
    },
    async saveLineItems() {
      const lineItemData = this.splitLineItemChanges(this.lineItemChanges)
      const payload = {
        DocumentId: this.documentId,
        ...lineItemData,
      }

      const result = await this.$store.dispatch('document/saveDocumentLineItems2', payload)

      if (result.ResultStatus === 1) {
        this.showSnackbar = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Line Item updated successfully'
        this.snackbarImage = true
        this.lineItemisDirty = false
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = result?.ResultMessage || 'An error occurred while updating line item'
        this.snackbarImage = false
      }
    }, 
    setLineItem(value, callFromModal = true) {
      if (value === '') {
        this.selectedTable = this.selectedTableBuffer
        this.droplistKey++
        return
      }

      this.tableIndex = -1
      this.selectedLineItem = null

      for (let i = 0; i < this.lineItemTables.length; i++) {
        if (this.lineItemTables[i].Tablename === value.name) {
          this.tableIndex = i
          this.selectedLineItem = this.lineItemTables[i]
          break
        }
      }

      this.prevSelectedTable = this.selectedTableBuffer
      this.selectedTableBuffer = value

      if (this.lineItemisDirty && callFromModal) {
        this.showValidateModalTableChange = true
      } else {
        this.fieldsTableKey++
      }
    },
    setTableList(lineItemTables = []) {
      return lineItemTables.map(d => ({
        name: d.Tablename,
        value: d.Tablename,
      }));
    },
    splitLineItemChanges(changes) {
      let values = {}
      let newCells = []
      
      Object.keys(changes).forEach(key => {
        if (key.includes('NF-')) {
          const [tableId, row, col] = key.replace('NF-', '').split('-')
          newCells.push({
            TableId: tableId,
            row,
            col,
            page: 1,
            Value: changes[key],
          })
        } else {
          values[key] = changes[key]
        }
      })

      return { Values: values, NewCells: newCells }
    },
    updateField(documentId, tableIndex, rowIndex, columnIndex, cellId, value) {
      this.lineItemisDirty = true
      this.lineItemChanges[cellId] = value
    },
    validateModalClose() {
      if (this.lineItemisDirty) {
        this.showValidateModalClose = true
      } else {
        this.showModal = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .modal-container {
    overflow: auto;
    width: 1000px;
    max-width: 100vw;
    height: calc(100vh - 350px);
  }

  #line-item-exit-dialog,
  #line-item-table-change-dialog {
    z-index: 20;
  }

  .fields-table-container {
    overflow: auto;
    height: calc(100vh - 480px);
    min-height: 200px;
  }

  .no-data {
    margin: 5px 0px;
  }
</style>
