<template>
  <div id="document-attachments">
    <VasionGeneralModal
      v-show="!showMergeDeleteDialog && !showMergeDialog"
      id="attachedDocumentsModal"
      :modalType="'slot'"
      :hideButtons="true"
      buttonGroup="left"
      :sync="showModal"
      @noButtonClick="showModal = !showModal"
    >
      <div>
        <div v-if="attachmentMode === 'view'" class="attached-documents-grid-div">
          <span class="vasion-page-title">Attachments</span>
          <div v-if="attachedDocuments && attachedDocuments.tableHeaders && attachedDocuments.tableRows.length > 0" class="main-table-container vasion-html-table-default">
            <VasionTable
              :headerColumns="attachedDocuments.tableHeaders"
              :tableRows="attachedDocuments.tableRows"
              :hideColumns="hideColumns"
              @vasion-selection="handleAttachedDocumentSelect"
              @vasion-icon-click="handleIconClick"
            />
          </div>
          <div v-else class="content">
            No attached documents for this document
          </div>
        </div>
        <div v-if="attachmentMode === 'delete'">
          <div class="spacer">
            Are you sure you want to remove '{{ selectedDeleteAttachment[1] }}'?
          </div>
          <VasionCheckbox
            v-if="canDeleteDocument || canRecycleDocument"
            id="delete-attached-file-checkbox"
            name="delete-attached-file-checkbox"
            class="spacer"
            :checked="deleteAttachedFile"
            @change="deleteAttachedFile = !deleteAttachedFile"
          >
            Delete attached file as well
          </VasionCheckbox>
          <Loading
            id="delete-loading"
            :active.sync="isLoading"
            :is-full-page="false"
            loader="dots"
            :color="loaderColor"
            :background-color="loaderBackgroundColor"
          />
          <div class="attachments-modal-buttons">
            <VasionButton id="cancel" :classProp="'secondary'" @vasionButtonClicked="attachmentMode = 'view'">
              Cancel
            </VasionButton>
            <VasionButton id="remove" :classProp="'primary'" @vasionButtonClicked="deleteAttachedDocument()">
              Remove
            </VasionButton>
          </div>
        </div>
        <div v-if="attachmentMode === 'search'" class="attached-documents-grid-div">
          <span class="vasion-page-title">Linked Documents Search</span>
          <div v-if="searchResultDocuments && searchResultDocuments.tableHeaders && searchResultDocuments.tableRows.length > 0" class="content">
            <VasionTable
              :headerColumns="searchResultDocuments.tableHeaders"
              :tableRows="searchResultDocuments.tableRows"
              :hideColumns="['_DocID_']"
              @vasion-row-checked="handleSearchResultCheckChange"
            />
          </div>
          <div v-else class="content">
            No Results Found
          </div>
        </div>
        <div class="attachments-modal-buttons">
          <VasionButton
            v-show="attachmentMode === 'add' || attachmentMode === 'search'"
            id="cancel-add-document-button"
            :classProp="'primary'"
            @vasionButtonClicked="attachmentMode = 'view'"
          >
            Cancel
          </VasionButton>

          <VasionButton
            v-show="attachmentMode === 'search'"
            id="attach-search-button"
            :classProp="'primary'"
            :isDisabled="!checkedDocuments || checkedDocuments.length <= 0"
            @vasionButtonClicked="attachSearchResults"
          >
            Attach
          </VasionButton>

          <VasionButton
            v-show="attachmentMode === 'view'"
            id="add-document-button"
            :classProp="'primary'"
            @vasionButtonClicked="attachmentMode = 'add'"
          >
            Add Document
          </VasionButton>

          <VasionButton
            v-show="attachmentMode === 'view'"
            id="search-button"
            :classProp="'primary'"
            :isDisabled="!hasLinkedSearch"
            @vasionButtonClicked="runAttachmentSearch()"
          >
            <span v-if="!isSearchLoading">Search</span>
            <div v-else id="loader-box">
              <Loading
                :active.sync="isSearchLoading"
                :is-full-page="false"
                loader="dots"
                :color="'white'"
                :background-color="'#ff4702'"
              />
            </div>
          </VasionButton>

          <!--
            <VasionButton
              v-show="attachmentMode === 'view'"
              id="merge-delete-attachments"
              :classProp="'primary'"
              @vasionButtonClicked="showMergeDeleteDialog = !showMergeDeleteDialog">
              Merge &amp; Delete Attachments
            </VasionButton>

            <VasionButton
              v-show="attachmentMode === 'view'"
              id="merge-attachments"
              :classProp="'primary'"
              @vasionButtonClicked="showMergeDialog = !showMergeDialog">
              Merge Attachments
            </VasionButton>
            -->
          <VasionButton
            v-show="attachmentMode === 'view'"
            id="close"
            :classProp="'primary'"
            @vasionButtonClicked="showModal = !showModal"
          >
            Close
          </VasionButton>
        </div>
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="confirmAttachedDocumentsMerge"
      :rejectButtonText="'CANCEL'"
      :modalType="'message'"
      buttonGroup="center"
      :message="'This will take all Tiff and PDF Attached Documents and Merge them to the Base Document. Would you like to continue?'"
      :sync="showMergeDialog"
      @noButtonClick="showMergeDialog = !showMergeDialog"
      @confirmButtonClick="mergeAttachedDocuments()"
    />
    <VasionGeneralModal
      id="confirmAttachedDocumentsMergeDelete"
      :rejectButtonText="'CANCEL'"
      :modalType="'message'"
      buttonGroup="left"
      :message="'This will take all Tiff and PDF Attached Documents, Merge them to the Base Document, then Delete the Attached Documents.  Would you like to continue?'"
      :sync="showMergeDeleteDialog"
      @noButtonClick="showMergeDeleteDialog = !showMergeDeleteDialog"
      @confirmButtonClick="mergeAttachedDocumentsDelete()"
    />

    <VasionFileUploadDialog
      :allowMultiple="true"
      :show="attachmentMode === 'add'"
      :serverOptions="serverOptions"
      @allFilesUploaded="allAttachmentsUploaded"
      @update:show="toggleUploadDialog"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { parseISOString } from '@/store/helperModules/document.module'
import { formatDateTime } from '@/store/helperModules/common.module'

import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TheDocumentAttachments',
  components: {
    Loading,
   },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    documentId: {
      type: Number,
      required: true,
    },
    folderId: {
      type: Number,
      required: true,
    },
    hasLinkedSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      attachmentMode: 'view',
      checkedDocuments: [],
      deleteAttachedFile: false,
      isLoading: false,
      isSearchLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      loadingFullPage: false,
      searchArrayResult: [],
      searchResultDocuments: {},
      selectedDeleteAttachment: [],
      serverOptions: {
        process: this.processHandler,
      },
      showConfirmDeleteModal: false,
      showMergeDeleteDialog: false,
      showMergeDialog: false,
      showModal: this.active,
    }
  },
  computed: {
    attachedDocuments() { return this.$store.state.document.linkedDocuments },
    canDeleteDocument() { return this.$store.getters['common/canDeleteDocument'] },
    canRecycleDocument() { return this.$store.getters['common/canRecycleDocument'] },
    hideColumns() {
      var hide = ['_DocID_']
      if (!this.canDeleteDocument && !this.canRecycleDocument) {
         hide.push('_VasionIconButton_')
      }
      return hide
    },
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    documentId: async function (value) {
      this.searchArrayResult = []
      if (!value || value <= 0) {
        return
      }

      let data = await this.$store.dispatch('document/findLinkedDocuments', value)
      if (data && data.Items) {
        this.searchArrayResult = data.Items
      }
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      }
    },
  },
  methods: {
    allAttachmentsUploaded() {
      this.toggleUploadDialog(false)
      // TODO: snackbar
    },
    attachSearchResults() {
      this.linkDocumentsAndReturn(this.checkedDocuments)
    },
    async deleteAttachedDocument() {
      const payload = {
        ParentDocumentId: this.documentId,
        ChildDocumentId: this.selectedDeleteAttachment[0],
        DeleteChild: this.deleteAttachedFile,
      }
      this.isLoading = true
      await this.$store.dispatch('document/deleteDocumentLink', payload)
      await this.$store.dispatch('document/loadAndSetDocLinks', this.documentId)
      this.isLoading = false
      this.attachmentMode = 'view'
    },
    handleAttachedDocumentSelect(selectInfo) {
      if (!selectInfo.Values || selectInfo.Values.length <= 0 || selectInfo.Values[0] <= 0) {
        return
      }
      this.$store.dispatch('document/setBackRoute', `/document/${this.documentId}?ShowAttachments=true`)
      this.$router.push({ name: 'Document', params: { documentID: selectInfo.Values[0] } })
    },
    handleIconClick(clickInfo) {
      if (clickInfo.index === 4) {
        this.attachmentMode = 'delete'
        this.selectedDeleteAttachment = clickInfo.item.Values
      }
    },
    handleSearchResultCheckChange(checkedRows) {
      this.checkedDocuments = checkedRows.map(item => {
        return item.Values[1]
      })
    },
    async linkDocuments(documentIDs, copyData) {
      if (!documentIDs || documentIDs.length <= 0) {
        return
      }

      const linkDocumentPayload = {
          BaseDocumentID: this.documentId,
          LinkDocumentIDs: {
              Values: documentIDs,
          },
          CopyData: copyData
        }

        await this.$store.dispatch('document/linkDocuments', linkDocumentPayload)
        await this.$store.dispatch('document/loadAndSetDocLinks', this.documentId)
    },
    async linkDocumentsAndReturn(documentIDs) {
      if (!documentIDs || documentIDs.length <= 0) {
        this.attachmentMode = 'view'
        return
      }

      const linkDocumentPayload = {
          BaseDocumentID: this.documentId,
          LinkDocumentIDs: {
              Values: documentIDs,
          },
        }

        await this.$store.dispatch('document/linkDocuments', linkDocumentPayload)
        await this.$store.dispatch('document/loadAndSetDocLinks', this.documentId)
        this.attachmentMode = 'view'
    },
    mergeAttachedDocuments() {
      const payload = {
        DocumentId: this.documentId,
        DeleteAttachmentDocs: false,
      }
      this.$store.dispatch('document/mergeLinkedDocuments', payload)
      this.showMergeDialog = !this.showMergeDialog
      this.$emit('close')
    },
    mergeAttachedDocumentsDelete() {
      const payload = {
        DocumentId: this.documentId,
        DeleteAttachmentDocs: true,
      }
      this.$store.dispatch('document/mergeLinkedDocuments', payload)
      this.showMergeDeleteDialog = !this.showMergeDeleteDialog
      this.$emit('close')
    },
    populateLinkedSearchResultsTableData(valuesArray) {
      this.searchResultDocuments = {}
      if (!valuesArray || valuesArray.length === 0) {
        return
      }

      this.searchResultDocuments.tableHeaders = ['_VasionCheckBox_', '_DocID_', 'Name', 'Created', 'Modified']
      this.searchResultDocuments.tableRows = valuesArray.map((item) => {
          const createdDate = parseISOString(item.CreatedUTC)
          const modifiedDate = parseISOString(item.ModifiedUTC)
          return {
            Values: [{ show: true, value: false }, item.DocumentId, item.DisplayName, formatDateTime(createdDate, 'datetime'), formatDateTime(modifiedDate, 'datetime')],
            ValueType: 'String',
          }
      })
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress) {
      const payload = {
        file: file,
        folderId: this.folderId,
      }
      try {
        const newDocumentID = await this.$store.dispatch('storage/uploadFileToVasion', payload)
        progress(true, 0, 1024);
        load(file.name);

        if (!newDocumentID) {
          return
        }

        const linkDocIDs = [parseInt(newDocumentID, 10)]
        this.linkDocuments(linkDocIDs, true)
        await this.$amplitude.trackEvent('Document Added', {
          'source': 'Upload',
          'exportLocation': 'Attachment',
        })

      } catch (e) {
        console.warn(e)
      }
    },
    async runAttachmentSearch() {
      this.isSearchLoading = true
      this.searchResultDocuments = {}
      this.checkedDocuments = []

      if (this.searchArrayResult && this.searchArrayResult.length > 0) {
        this.populateLinkedSearchResultsTableData(this.searchArrayResult)
      }

      this.attachmentMode = 'search'
      this.isSearchLoading = false
    },
    toggleUploadDialog(show) {
      if (show) {
        this.attachmentMode = 'add'
      } else {
        this.attachmentMode = 'view'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .content {
    margin-top: 10px;
  }

  .attached-documents-grid-div {
    padding: 0px 10px 0px 10px;
    overflow: auto;
    width: 1000px;
    max-width: 100vw;
    margin-top: 10px;
    height: calc(100vh - 350px);
  }

  .file-pond-attachment-div {
    padding: 20px 10px 0px 10px;
    overflow: auto;
    width: 400px;
    max-width: 100vw;
    height: 250px;
  }

  .attachments-modal-buttons {
    text-align: right;
  }

  @media(max-width: $tablet) {
    .attached-documents-grid-div {
      width: 100%;
    }

    .file-pond-attachment-div {
      width: 100%;
    }
  }

  @media(max-width: $phone) {
    .attachments-modal-buttons{
      display: flex;
      flex-direction: column;
    }
    .attached-documents-grid-div {
      height: 350px;
    }

    .file-pond-attachment-div {
      height: 100px;
    }
  }

  #loader-box {
    min-height: 36px;
    min-width: 60px;
    z-index: 30;
  }

  .spacer {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  #delete-loading{
    position: relative;
    height: 50px;
  }

</style>
