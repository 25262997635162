<template>
  <div>
    <VasionGeneralModal
      id="coc-modal"
      :sync="showModal"
      :modalType="'slot'"
      :hideButtons="true"
      :closeOnClickAway="false"
      @noButtonClick="showModal = false"
    >
      <div class="m-body">
        <div class="coc-container">
          <iframe
            id="coc-doc"
            marginheight="0"
            frameborder="0"
            :src="streamPDFData"
          />
        </div>
      </div>
      <div class="m-footer">
        <VasionButton
          id="close-coc"
          classProp="primary"
          :isFullWidth="false"
          @vasionButtonClicked="showModal = false"
        >
          CLOSE
        </VasionButton>
      </div>
    </VasionGeneralModal>
  </div>
</template>
<script>

export default {
  name: 'TheCoCDocument',
  components: {
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    documentId: {
      type: Number,
      require: true,
      default: null,
    },
    documentName: {
      type: String,
      require: true,
      default: null,
    },
  },
  data: function () {
    return {
      showModal: this.active,
      streamPDFData: '',
    }
  },
  computed: {
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      }
    },
  },
  async created() {
    let streamPDFRequestController = new AbortController()
    let payload = {
      documentID: this.documentId,
      requestController: streamPDFRequestController
    }
    let streamData = await this.$store.dispatch('document/tryCoCStreamPDF', payload)

    streamPDFRequestController = null
    const file = new Blob([streamData], { type: 'application/pdf' })
    this.streamPDFData = URL.createObjectURL(file) + '#navpanes=0'
  },
  methods: {
    downloadCoCFile() {
      const link = document.createElement('a')
      link.href = this.streamPDFData
      link.download = `coc-${this.documentName}${!this.documentName.toLowerCase().endsWith(".pdf") ? '.pdf' : ''}`
      link.dispatchEvent(new MouseEvent('click'))
      link.remove()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

::v-deep #coc-modal .modal-container {
  padding: 8px;
}

::v-deep #coc-modal .modal-container .slot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 16px 2px 8px 6px;
}

.m-body {
  flex: 1 1 auto;
  overflow-y: hidden;
}

.m-footer {
  text-align: end;
}

.coc-container {
  width: calc(100vw - 100px);
  height: calc(100vh - 270px);
}

.coc-container iframe {
  width: 100%;
  height: 100%;
}

// md: 768px
@media (min-width: $breakpoint-md) {
  .coc-container {
    width: 620px;
    height: calc(100vh - 270px);
  }
}
// lg: 992px
@media (min-width: $breakpoint-lg) {
  .coc-container {
    width: 860px;
  }
}
// xl: 1200px
@media (min-width: $breakpoint-xl) {
  .coc-container {
    width: 1040px;
  }
}
// xxl: 1400px
@media (min-width: $breakpoint-xxl) {
  .coc-container {
    width: 1220px;
  }
}
</style>
