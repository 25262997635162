<template>
  <div id="the-document-data">
    <div class="md-layout-item attributes-tab-div">
      <div class="attributes-padding-div">
        <div id="attribute-top-section">
          <div class="vasion-flex-row">
            <VasionDropList
              v-slot="slotItem"
              v-model="localCurrentAttributeFormId"
              :dataArray="attributeForms"
              :title="`${$formsLabel.toUpperCase()} TYPE`"
              inputType="plain-list"
              valueName="value"
              displayName="name"
              class="attribute-droplist"
              @input="markDirty"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionButton
              v-show="showLookupButton"
              id="database-lookup-button"
              :classProp="'secondary'"
              @vasionButtonClicked="attributeFieldLookUp"
            >
              Database Lookup
            </VasionButton>
          </div>
        </div>
        <div class="index-field-list-padding">
          <IndexFieldList
            ref="attributeFieldsComponent"
            :fields="indexFields"
            :enforceFieldSecurity="true"
            :selectorOnly="false"
            :showSubmit="false"
            :fieldWidth="225"
            :useMultiSelectLists="true"
            @fieldValueUpdated="updateLocalFieldValues"
            @clean="markClean"
            @dirty="markDirty"
            @notValid="markNotValid"
            @isValid="markIsValid"
          />
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="vasion-snackbar"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <md-dialog id="multipleLookupDialog" :md-active.sync="showMultipleLookupDialog" :md-click-outside-to-close="false">
      <div>
        <MultipleLookups
          :tableValues="localFieldValues"
          @yesButtonClick="updateLookupValues"
          @noButtonClick="showMultipleLookupDialog = !showMultipleLookupDialog"
        />
      </div>
    </md-dialog>
  </div>
</template>

<script>
import IndexFieldList from '@/components/IndexFieldList.vue';
import MultipleLookups from '@/components/vault/MultipleLookups.vue'
import VasionSnackbar from '@/components/shared/VasionSnackbar.vue';
import { removeIllegalChars, testDownloadHasFileExtension } from '@/store/helperModules/common.module'

export default {
  name: 'TheDocumentData',
  components: {
    IndexFieldList,
    MultipleLookups,
    VasionSnackbar,
  },
  props: {
    currentAttributeFormId: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      dirtyAttributeState: false,
      indexFields: [],
      indexFilters: [],
      localCurrentAttributeFormId: {},
      localFieldValues: [],
      showLookupButton: false,
      showMultipleLookupDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      snackbarText: 'Attributes Saved!',
    }
  },
  computed: {
    attributeForms() {
      let returnArray = []

      if (this.document && this.document.IndexForms && this.document.IndexForms.Values) {
        returnArray = this.document?.IndexForms?.Values.map((v) => {
          return {
            name: v.Value,
            value: v.Key,
          }
        })
      }

      return returnArray
    },
    documentAttributeFields() {
      return !this.document || !this.document.IndexFormFields || !this.document.IndexFormFields.Values
        ? []
        : this.document.IndexFormFields.Values
    },
    documentAttributeFormId() { return !this.document || !this.document.FormId ? 0 : this.document.FormId },
    documentID() { return !this.document || !this.document.DocumentId ? 0 : this.document.DocumentId },
  },
  watch: {
    currentAttributeFormId: function () {
      this.localCurrentAttributeFormId = this.currentAttributeFormId
    },
    indexFields: function () {
      if (this.indexFields) {
        this.indexFields.map((field) => {
          const foundField = this.documentAttributeFields.find((f) => {
            return f.FieldName === field.value
          })

          field.filterValue = this.getValueFromDocField(foundField)
          field.readOnly = field?.filterValue === '' || field?.filterValue === null ? false : field.readOnly
          return true
        })
      }
      this.localFieldValues = []
    },
    localCurrentAttributeFormId: async function () {
      this.showLookupButton = false
      if (this.localCurrentAttributeFormId || this.localCurrentAttributeFormId.value > 0) {
        this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.localCurrentAttributeFormId.value)
        const result = await this.$store.dispatch('document/getDefaultLookupId', this.localCurrentAttributeFormId.value)
        if (result && result.Value) {
          this.showLookupButton = Number(result.Value) > 0
        }
      }
    },
    localFieldValues: function () { this.dirtyAttributeState = this.localFieldValues.length > 0 },
  },
  async created() {
    this.localCurrentAttributeFormId = this.currentAttributeFormId
  },
  methods: {
    async attributeFieldLookUp() {
     const FieldValues = {
        Values: this.getLocalIndexFieldValuePairs(),
      }

      const results = await this.$store.dispatch('attributeForm/attributeFieldLookUp', {
        IndexFormId: this.localCurrentAttributeFormId ? this.localCurrentAttributeFormId.value : this.document.FormId,
        FieldValues,
      })
      switch (results.Rows?.length) {
        case 0:
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'No Results Found.'
          this.snackbarImage = false
          this.showSnackbar = true
          return
        case 1:
          this.$refs.attributeFieldsComponent.updateFieldsFromLookup(results)
          break
        default:
          this.localFieldValues = results
          this.showMultipleLookupDialog = true
          break
      }
    },
    async createVersionAttributeFormPDF() {
      await this.saveAttributeData()
      await this.$store.dispatch('document/createVersionAttributeFormPDF', this.documentID)
      await this.$store.dispatch('document/getDocumentVersions', this.documentID)
    },
    async downloadAttributeFormDocument(documentName) {
      const newFileName = await removeIllegalChars(documentName)

      const downloadResult = await this.$store.dispatch('document/downloadAttributeFormDocument', this.documentID)
      if (downloadResult) {
        // eslint-disable-next-line
        const fileDownload = require('js-file-download')
        let localFileExtension = '.pdf'
        if (newFileName.includes('.')) {
          localFileExtension = newFileName.substring(newFileName.lastIndexOf('.'))
        }

        testDownloadHasFileExtension(downloadResult, newFileName, localFileExtension)
      } else {
        this.snackbarText = 'Unable to download the Document'
      }
    },
    getLocalIndexFieldValuePairs() {
      if (!this.localFieldValues) {
        return []
      }

      return this.localFieldValues.map(e => {
        if (Object.prototype.toString.call(e.value) === '[object Array]') {
          const dropdownValues = []
          e.value.forEach(v => {
            if (v.name) {
              dropdownValues.push(v.name)
            }
            else {
              dropdownValues.push(v)
            }
          })
          const dropDownSingleString = dropdownValues.join(';')
          return {
            Key: e.name,
            Value: dropDownSingleString,
          }
        } else {
          return {
            Key: e.name,
            Value: e.value,
          }
        }
      })
    },
    getStringFromIndexValue(indexValue) {
      let text = ''

      if (!indexValue) {
        return text
      }

      if (Array.isArray(indexValue)) {
        indexValue.map((value) => {
          if (value.name) {
            // .value is an array of objects, each with it's own name and value properties.
            // This is in support of multi-select VasionDroplists
            text += `${value.name};`
          } else {
            // .value is a string array ...
            text += `${value};`
          }
          return true
        })

        if (text !== '') {
          // remove the trailing semi-colon
          text = text.substring(0, text.length - 1)
        }
      } else {
        text = indexValue
      }

      return text
    },
    getValueFromDocField(docField) {
      let ret = ''

      try {
        if (docField) {
          if (docField.Control === 2) {
            ret = docField.DropDownSelectedValues.Values
          } else if (docField.Control === 3) {
            ret = docField.DateValueUTC
          } else {
            ret = docField.TextValue
          }
        }
      } catch {
        ret = ''
      }

      return ret
    },
    hideSnackbar() { this.showSnackbar = false },
    markClean() { this.$emit('clean') },
    markDirty() {
      this.$emit('dirty')
    },
    markIsValid() { this.$emit('isValid') },
    markNotValid() { this.$emit('notValid') },
    async printAttributeForm() {
      const documentsForPrint = await this.$store.dispatch('document/printDocumentAttributeForm', this.documentID)
      // eslint-disable-next-line
      const printJS = require('print-js')
      if (documentsForPrint) {
        printJS({
          printable: documentsForPrint.Value, type: 'pdf', showModal: true, onPrintDialogClose: () => { this.$store.dispatch('workflow/deletePrintedFile', documentsForPrint) },
          onError: () => { 
            this.showSnackbar = true
            this.snackbarTitle = 'Error!'
            this.snackbarSubTitle = 'Error printing file, check "chrome://settings/content/pdfDocuments" and set to "Open PDFs in Chrome"'
            this.snackbarImage = false
          },          
        });
      }
    },
    async saveAttributeData() {
      if (!this.localCurrentAttributeFormId || this.localCurrentAttributeFormId.value <= 0) {
        return true
      }

      const payload = {
        documentId: this.documentID,
        FormId: this.localCurrentAttributeFormId.value,
        FieldValues: {
          Values: [],
        },
      }

      // first build up the fields that changed
      if (this.localFieldValues) {
        this.localFieldValues.forEach((localValue) => {
          payload.FieldValues.Values.push({
            Key: localValue.name,
            Value: this.getStringFromIndexValue(localValue.value),
          })
        })
      }

      if (this.localCurrentAttributeFormId && this.localCurrentAttributeFormId.value === this.documentAttributeFormId) {
        // we're not changing the attribute form, so check to see if anything changed
        if (payload.FieldValues.Values.length === 0) {
          return true // nothing changed, so we're done!
        }
      }

      // now add anything to the list that was previously on the document
      this.documentAttributeFields.forEach((docField) => {
        const foundField = this.localFieldValues.find((local) => {
          return local.name === docField.FieldName
        })

        if (!foundField) {
          payload.FieldValues.Values.push({
            Key: docField.FieldName,
            Value: '',
          })
        }
      })

      const result = await this.$store.dispatch('document/saveDocumentAttributes', payload)
      const saveSuccess = !!result

      if (saveSuccess) {
        this.localFieldValues = [] // can remove any locally modified fields because they've been saved
      }

      return saveSuccess
    },
    updateLocalFieldValues(childComponentValues) {
      this.localFieldValues = childComponentValues
      this.$emit('fieldValueUpdated', this.localFieldValues)
    },
    updateLookupValues(item) {
      this.showMultipleLookupDialog = false
      this.$refs.attributeFieldsComponent.updateFieldsFromLookup(item)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  #attribute-top-section {
    padding: 15px 0px 32px 24px;
  }

  #the-document-data {
    width: 100%;

    #back-button {
      float: left;
    }
  }

  #database-lookup-button {
    margin-top: 15px;
  }

  .index-field-list-padding {
    padding: 24px;
    border-top: 1px solid $grey-200;
  }

</style>
