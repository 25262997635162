<template>
  <div class="vasion-white-background vld-parent document-container">
    <Loading
      :active.sync="isLoading"
      :is-full-page="loadingFullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-if="errorLoadingDocument" class="error-div">
      <div>
        <label class="document-title">
          Unable to load the Document.
        </label>
      </div>
      <div>
        <label class="document-title">
          If you believe you should have access to this Document, please contact your System Administrator.
        </label>
      </div>
      <div class="error-back-div">
        <VasionButton
          id="error-loading-back"
          :classProp="'primary'"
          :isFullWidth="false"
          @vasionButtonClicked="backToPreviousView()"
        >
          Back
        </VasionButton>
        <VasionButton
          v-if="hasAdditionalDocumentIds"
          id="save-attributes-button"
          :classProp="'primary'"
          :isFullWidth="false"
          @vasionButtonClicked="nextDocument()"
        >
          Next
        </VasionButton>
      </div>
    </div>
    <div v-else class="innerDiv">
      <div v-if="documentID <= 0">
        <Loading
          :is-full-page="loadingFullPage"
          :color="loaderColor"
          loader="dots"
          :background-color="loaderBackgroundColor"
        />
      </div>
      <div v-else>
        <div class="headerDiv">
          <div>
            <VasionButton
              v-if="!isSignatureDocLinkView && !isFromSearch"
              id="btnBack"
              name="btnBack"
              title="Back"
              :icon="'VasionArrowLeftIcon'"
              @vasionButtonClicked="backToPreviousView()"
            />
            <div v-else style="width: 10px;" />
          </div>
          <div v-if="(isInSignatureMode || isInSignatureApp) && signatureDocumentGUID" id="signature-document-title-container">
            <div class="vasion-page-title-black" style="font-size: 20px; margin: 0px 0px 4px 0px;">
              {{ documentName }}
            </div>
            <div class="vasion-page-subheader-light" style="font-size: 12px">
              ID: {{ signatureDocumentGUID }}
            </div>
          </div>
          <div v-else class="d-flex">
            <div v-if="!displayNameInEditMode" class="document-title" :title="documentName">
              <span v-if="VersionNumber">{{ VersionNumber }}-</span>{{ documentName.length < 60 ? documentName : `${documentName.substring(0, 60)}...` }}
            </div>
            <div class="d-flex display-name-edition-mode">
              <div v-if="displayNameInEditMode" id="dynamic-input">
                <span id="size-calibration"></span>
                <input
                  id="document-display-name"
                  name="document-display-name"
                  ref="displayNameInput"
                  type="text"
                  v-model="displayNameInputValue"
                >
              </div>
              <md-button
                v-if="!displayNameInEditMode && canRenameDocument"
                id="btn-edit-display-name"
                class="md-dense action-button btn-custom-title"
                title="Edit Display Name"
                @click="setDisplayNameEditionMode('on')"
                >
                <VasionEditIcon />
              </md-button>
              <md-button
                v-if="displayNameInEditMode"
                id="btn-edit-display-name-check"
                class="md-dense action-button btn-custom-title"
                title="Confirm Display Name Change"
                @click="editDisplayName"
                >
                <VasionCheck />
              </md-button>
              <md-button
                v-if="displayNameInEditMode"
                id="btn-edit-display-name-cancel"
                class="md-dense action-button btn-custom-title"
                title="Cancel Display Name Change"
                @click="setDisplayNameEditionMode('off')"
                >
                <VasionCancelIcon />
              </md-button>
            </div>
          </div>
          <div class="md-layout-item vasion-flex-end">
            <div v-if="hasPendingSignatureZones" class="md-layout-item vasion-flex-end headerDiv">
              <div class="required-fields">
                Completed: <div class="zones-counter">
                  {{ signedSignatureZones }} / {{ totalSignatureZones }}
                </div>
              </div>
              <div v-if="hasMoreThanOnePendingSignatureZone" class="alignCenter">
                <VasionButton
                  id="previous-zone-button"
                  name="previous-zone-button"
                  icon="VasionChevronLeft"
                  :classProp="'secondary-grey'"
                  @vasionButtonClicked="goToPreviousZone"
                />
                <VasionButton
                  id="next-zone-button"
                  :classProp="'secondary-grey'"
                  :isFullWidth="false"
                  @vasionButtonClicked="goToNextZone"
                >
                  <div class="align-text-and-icon">
                    Next
                    <VasionChevronRight />
                  </div>
                </VasionButton>
              </div>
              <div v-else>
                <VasionButton
                  id="go-to-zone-button"
                  :classProp="'secondary-grey'"
                  :isFullWidth="false"
                  @vasionButtonClicked="goToPage(pendingSignaturesPageNumbers[0])"
                >
                  Go to Zone
                </VasionButton>
              </div>
            </div>
            <VasionButton
              v-if="showSaveButton"
              id="save-attributes-button"
              :classProp="'primary'"
              :isFullWidth="false"
              :isDisabled="!saveButtonActive"
              @vasionButtonClicked="saveAllTypes(false, true)"
            >
              Save
            </VasionButton>
            <VasionButton
              v-if="hasAdditionalDocumentIds"
              id="save-attributes-button"
              :classProp="'primary'"
              :isFullWidth="false"
              :isDisabled="!saveButtonActive"
              @vasionButtonClicked="saveAllTypes(false, true, true)"
            >
              Save and Next
            </VasionButton>
            <VasionButton
              v-if="hasAdditionalDocumentIds"
              id="save-attributes-button"
              :classProp="'primary'"
              :isFullWidth="false"
              @vasionButtonClicked="nextDocument()"
            >
              Next
            </VasionButton>
          </div>
        </div>

        <!-- START OF TOOLBAR -->
        <div class="toolbar-div" :class="{ 'toolbar-div-not-in-document-view': !isInDocumentView }">
          <!-- PAGINATION -->
          <VasionPager
            v-if="!isNativeDocument && isInDocumentView"
            :currentPageNumber="currentPageNumber"
            :showPageSize="false"
            :totalPages="totalPages"
            @pageChanged="goToPage"
          />

          <!-- ZOOM IN, ZOOM OUT -->
          <div v-if="!isNativeDocument && isInDocumentView" id="toolbar-zoom" class="toolbar-height">
            <div class="toolbar-divider" />
            <VasionButton
              id="btnZoomIn"
              class="toolbar-icon"
              name="btnZoomIn"
              title="Zoom In"
              :icon="'VasionZoomInIcon'"
              :isDisabled="disableZoomIn"
              @vasionButtonClicked="zoomIn()"
            />
            <VasionButton
              id="btnZoomOut"
              class="toolbar-icon"
              name="btnZoomOut"
              title="Zoom Out"
              :icon="'VasionZoomOutIcon'"
              :isDisabled="disableZoomOut"
              @vasionButtonClicked="zoomOut()"
            />
          </div>

          <!-- UPLOAD NEW VERSION, PRINT, DOWNLOAD, EMAIL, GENERATE PDF -->
          <div
            v-if="canUploadNewVersionDocument || canPrintDocument || canDownloadDocument || canEmailDocument || canGeneratePDF"
            id="toolbar-print-upload-email"
            class="toolbar-height"
            :class="{
              'toolbar-height-not-native-document-view': !isInDocumentView || isNativeDocument,
              'toolbar-margin-not-document-view': !isInDocumentView
            }"
          >
            <div v-if="isInDocumentView" class="toolbar-divider" />
            <VasionButton
              v-if="canUploadNewVersionDocument && isInDocumentView && !isSignatureDocument"
              id="btnUploadVersion"
              class="toolbar-icon"
              name="btnUploadVersion"
              title="Upload New Version"
              :icon="'VasionFileCopyIcon'"
              @vasionButtonClicked="showUploadNewVersionModal = !showUploadNewVersionModal"
            />
            <VasionButton
              v-if="canPrintDocument && !isNativeDocument"
              id="btnPrint"
              class="toolbar-icon"
              name="btnPrint"
              title="Print"
              :icon="'VasionPrintIcon'"
              @vasionButtonClicked="handlePrintClick()"
            />
            <VasionButton
              v-if="canDownloadDocument"
              id="btnDownload"
              class="toolbar-icon"
              name="btnDownload"
              title="Download"
              :icon="'VasionDocumentDownloadIcon'"
              @vasionButtonClicked="downloadDocumentClick()"
            />
            <VasionButton
              v-if="canEmailDocument"
              id="email-button"
              class="toolbar-icon"
              name="email-button"
              title="Email"
              :icon="'VasionEmailIcon'"
              @vasionButtonClicked="emailButtonClick()"
            />
            <VasionButton
              v-if="canUploadNewVersionDocument && !isSignatureDocument && !isNativeDocument"
              id="create-version-button"
              class="toolbar-icon"
              name="create-version-button"
              title="Create Version"
              :icon="'VasionCreateVersion'"
              @vasionButtonClicked="createVersionClick"
            />
            <VasionButton
              v-if="canGeneratePDF && !isSignatureDocument && !isNativeDocument &&  generatePDFEnabled"
              id="pdf-button"
              class="toolbar-icon"
              name="pdf-button"
              title="Generate PDF"
              :icon="'VasionPictureAs'"
              @vasionButtonClicked="createPDFVersionClick"
            />
          </div>

          <div v-if="!showToolbar720" id="toolbar-add-delete-pages-and-rotate" class="flex-display">
            <!-- ADD PAGES, DELETE PAGES -->
            <div v-if="canAddOrDelete" id="toolbar-add-delete-pages" class="toolbar-height">
              <div class="toolbar-divider" />
              <VasionButton
                id="btnAddPagesId"
                class="toolbar-icon"
                name="btnAddPages"
                title="Add\Replace Pages"
                :icon="'VasionAddNote'"
                @vasionButtonClicked="() => showAddPagesModal = true"
              />
              <VasionButton
                v-if="canDeletePages"
                id="btnDeletePagesId"
                class="toolbar-icon"
                name="btnDeletePages"
                title="Delete Pages"
                :icon="'VasionRemoveNote'"
                @vasionButtonClicked="showDeletePagesDialog = true"
              />
            </div>

            <!-- ROTATE -->
            <div v-if="!isNativeDocument && isInDocumentView && !isSignatureDocument" id="toolbar-rotate-dropdown" class="toolbar-button">
              <div class="toolbar-divider" />
              <md-button
                class="md-icon-button md-dense double-icon toolbar-icon"
                title="Rotate"
                @click="toggleRotateActions($event)"
              >
                <VasionRotateLeftIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
            </div>
          </div>

          <div v-if="!showEllipsisIcon" id="toolbar-annotations" class="flex-display">
            <!-- ANNOTATIONS: BLACK, WHITE, HIGHLIGHT, TEXT, STAMP, SIGNATURE, SAVE -->
            <div v-if="isInDocumentView && !isSignatureDocument && !isInSignatureMode" class="toolbar-height">
              <div class="toolbar-divider" />
              <!-- // TODO: Get black and while rectangle icons for the below buttons, then convert to VasionButtons -->
              <md-button
                v-if="canAddAnnotation"
                id="btnBlackRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="Black Redaction"
                @click="addNewAnnotation(annotation.redaction.black)"
              >
                <div class="Rectangle-2020" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <md-button
                v-if="canAddAnnotation"
                id="btnWhiteRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="White Redaction"
                @click="addNewAnnotation(annotation.redaction.white)"
              >
                <div class="Rectangle-2020-White" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotation"
                class="toolbar-icon"
                name="btnTextAnnotation"
                title="Highlight"
                :icon="'VasionHighlight'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.highlight)"
              />
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotationCanAdd"
                class="toolbar-icon"
                name="btnTextAnnotationCanAdd"
                title="Text"
                :icon="'VasionTextAnnotationIcon'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.text);"
              />
              <md-button
                v-if="canAddAnnotation"
                id="stampButton"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Stamp"
                @click="toggleShowAddStampList($event);
                        addNewAnnotation(annotation.stamp);"
              >
                <VasionStampIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <md-button
                v-if="canAddAnnotation && textStampAnnotationList.length"
                id="textStampButton"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Text Stamp"
                @click="toggleShowAddTextStampList($event);"
              >
                <VasionTextStampIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <md-button
                v-if="canPerformDigitalSignature"
                id="btnSignature"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Signature"
                @click="clickSignatureToolbarButton($event)"
              >
                <VasionGestureIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && canBurnAnnotations"
                id="btnBurnAnnotations"
                class="toolbar-icon"
                name="btnBurnAnnotations"
                title="Burn Annotations"
                :icon="'VasionFlame'"
                @vasionButtonClicked="toggleData('showBurnAnnotations')"
              />
            </div>

            <!-- SIGNATURE DOCUMENT HISTORY -->
            <div v-if="isInSignatureMode || isSignatureDocument" id="toolbar-history" class="toolbar-height">
              <div class="toolbar-divider" />
              <VasionButton
                id="documentHistory"
                class="toolbar-icon"
                name="documentHistory"
                title="Document History"
                :icon="'VasionDocumentHistory'"
                @vasionButtonClicked="showHistory()"
              />
            </div>

            <div v-if="(isInSignatureMode || isSignatureDocument) && hasCertificate" id="toolbar-certificate" class="toolbar-height">
              <VasionButton
                id="documentCertificate"
                class="toolbar-icon"
                name="documentCertificate"
                title="Document Certificate"
                :icon="'VasionCertificateIcon'"
                @vasionButtonClicked="showCertificate()"
              />
            </div>
          </div>

          <!-- ELLIPSIS ICON -->
          <div v-if="isInDocumentView && showEllipsisIcon" id="toolbar-ellipsis" class="toolbar-height">
            <div class="toolbar-divider" />
            <VasionButton
              id="toolbar-ellipsis-btn"
              class="toolbar-icon"
              name="toolbar-ellipsis"
              title="Show More"
              :icon="'VasionEllipsisIcon'"
              @vasionButtonClicked="showEllipsisToolbar = !showEllipsisToolbar"
            />
          </div>

          <!-- TOGGLE VIEW, SHOW RIGHT PANEL (SMALL SCREENS) -->
          <div id="toggle-btn">
            <div class="md-layout-item vasion-flex-end">
              <VasionButton
                v-if="!isInSignatureMode && !isSignatureDocLinkView && !isInSignatureApp"
                id="toggle-document-view-button"
                name="toggle-document-view-button"
                title="Change View"
                :icon="viewModeIcon"
                @vasionButtonClicked="toggleViewClick"
              />
              <VasionEllipsisMenu
                v-if="showViewModeMenu"
                :buttonConfig="viewMenuButtonConfig"
                :style="'top:70px;'"
                @ellipsis-button-clicked="viewMenuSelectionMade"
                @close="closeViewModeMenu"
              />
            </div>
            <VasionButton
              id="show-sidebar-button"
              :class="{ 'side-panel-expanded' : !rightSidePanelMobile }"
              name="show-sidebar-button"
              title="Show Sidebar"
              :iconFill="rightSidePanelMobile ? 'black' : '#ff4702'"
              :icon="'VasionSideBarIcon'"
              @vasionButtonClicked="rightSidePanelMobile = !rightSidePanelMobile"
            />
          </div>
        </div>
        <!-- END OF TOOLBAR -->

        <!-- START OF: ELLIPSIS TOOLBAR -->
        <div class="center-flex-content">
          <div v-if="isInDocumentView && showEllipsisToolbar" id="ellipsis-toolbar">
            <div v-if="isInDocumentView && showToolbar720" class="flex-display">
              <!-- ADD PAGES, DELETE PAGES -->
              <div v-if="canAddOrDelete" id="toolbar-add-delete-pages" class="toolbar-height">
                <VasionButton
                  id="btnAddPagesId"
                  class="toolbar-icon"
                  name="btnAddPages"
                  title="Add\Replace Pages"
                  :icon="'VasionAddNote'"
                  @vasionButtonClicked="() => showAddPagesModal = true"
                />
                <VasionButton
                  v-if="canDeletePages"
                  id="btnDeletePagesId"
                  class="toolbar-icon"
                  name="btnDeletePages"
                  title="Delete Pages"
                  :icon="'VasionRemoveNote'"
                  @vasionButtonClicked="showDeletePagesDialog = true"
                />
              </div>

              <!-- ROTATE -->
              <div v-if="!isNativeDocument && isInDocumentView && !isSignatureDocument" id="toolbar-rotate-dropdown" class="toolbar-button">
                <div class="toolbar-divider" />
                <md-button
                  class="md-icon-button md-dense double-icon toolbar-icon"
                  title="Rotate"
                  @click="toggleRotateActions($event)"
                >
                  <VasionRotateLeftIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
                </md-button>
              </div>
            </div>

            <!-- ANNOTATIONS: BLACK, WHITE, HIGHLIGHT, TEXT, STAMP, SIGNATURE, SAVE -->
            <div v-if="isInDocumentView && !isSignatureDocument && !isInSignatureMode" class="toolbar-height">
              <div v-if="showToolbar720" class="toolbar-divider" />
              <!-- // TODO: Get black and while rectangle icons for the below buttons, then convert to VasionButtons -->
              <md-button
                v-if="canAddAnnotation"
                id="btnBlackRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="Black Redaction"
                @click="addNewAnnotation(annotation.redaction.black)"
              >
                <div class="Rectangle-2020" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <md-button
                v-if="canAddAnnotation"
                id="btnWhiteRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="White Redaction"
                @click="addNewAnnotation(annotation.redaction.white)"
              >
                <div class="Rectangle-2020-White" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotation"
                class="toolbar-icon"
                name="btnTextAnnotation"
                title="Highlight"
                :icon="'VasionHighlight'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.highlight)"
              />
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotationCanAdd"
                class="toolbar-icon"
                name="btnTextAnnotationCanAdd"
                title="Text"
                :icon="'VasionTextAnnotationIcon'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.text);"
              />
              <md-button
                v-if="canAddAnnotation"
                id="stampButton"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Stamp"
                @click="toggleShowAddStampList($event);
                        addNewAnnotation(annotation.stamp);"
              >
                <VasionStampIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <md-button
                v-if="canPerformDigitalSignature"
                id="btnSignature"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Signature"
                @click="clickSignatureToolbarButton($event)"
              >
                <VasionGestureIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && canBurnAnnotations"
                id="btnBurnAnnotations"
                class="toolbar-icon"
                name="btnBurnAnnotations"
                title="Burn Annotations"
                :icon="'VasionFlame'"
                @vasionButtonClicked="toggleData('showBurnAnnotations')"
              />
            </div>

            <!-- SIGNATURE DOCUMENT HISTORY -->
            <div v-if="isInSignatureMode || isSignatureDocument" id="toolbar-history" class="toolbar-height">
              <div class="toolbar-divider" />
              <VasionButton
                id="documentHistory"
                class="toolbar-icon"
                name="documentHistory"
                title="Document History"
                :icon="'VasionDocumentHistory'"
                @vasionButtonClicked="showHistory()"
              />
            </div>

            <div v-if="(isInSignatureMode || isSignatureDocument) && hasCertificate" id="toolbar-certificate" class="toolbar-height">
              <VasionButton
                id="documentCertificate"
                class="toolbar-icon"
                name="documentCertificate"
                title="Document Certificate"
                :icon="'VasionCertificateIcon'"
                @vasionButtonClicked="showCertificate()"
              />
            </div>
          </div>
        </div>
        <!-- END OF: ELLIPSIS TOOLBAR -->

        <div class="md-layout remainingDocumentSection">
          <div v-if="(!isNativeDocument || canDownloadDocument) && !isInSignatureMode && !isSignatureDocLinkView" class="action-links vasion-white-background">
            <VasionButton
              v-if="canViewDocumentHistory && !isInSignatureMode"
              id="document-history"
              class="action-link"
              name="document-history"
              title="History"
              :icon="'VasionHistoryIcon'"
              :isDense="true"
              @vasionButtonClicked="showHistory()"
            />

            <md-badge v-if="canViewDocumentNotes && !isInSignatureMode && noteCount" :md-content="noteCount" :style="{ width: noteBadgeWidth +'px' }">
              <VasionButton
                id="document-notes"
                class="action-link"
                name="document-notes"
                title="Notes"
                :icon="'VasionNotesIcon'"
                :isDense="true"
                @vasionButtonClicked="showNotesDialog = !showNotesDialog"
              />
            </md-badge>
            <VasionButton
              v-if="canViewDocumentNotes && !isInSignatureMode && !noteCount"
              id="document-notes"
              class="action-link"
              name="document-notes"
              title="Notes"
              :icon="'VasionNotesIcon'"
              :isDense="true"
              @vasionButtonClicked="showNotesDialog = !showNotesDialog"
            />
            <md-badge v-if="canViewDocumentVersions && versionCount > 0 && !isInSignatureMode" :md-content="versionCount" :style="{ width: versionsBadgeWidth +'px' }">
            <VasionButton
              v-if="canViewDocumentVersions && versionCount > 0 && !isInSignatureMode"
              id="document-versions"
              class="action-link"
              name="document-versions"
              title="Versions"
              :icon="'VasionVersionsIcon'"
              :isDense="true"
              @vasionButtonClicked="toggleData('showVersions')"
            />
            </md-badge>

            <md-badge v-if="canViewAttachedDocuments && !isInSignatureMode && attachmentCount" :md-content="attachmentCount" :style="{ width: attachmentBadgeWidth +'px' }">
              <VasionButton
                id="document-attachments"
                class="action-link"
                name="document-attachments"
                title="Attachments"
                :icon="'VasionAttachmentsIcon'"
                :isDense="true"
                @vasionButtonClicked="trackByType('Attachments'); showAttachedDocumentsDialog = true;"
              />
            </md-badge>
            <VasionButton
              v-if="canViewAttachedDocuments && !isInSignatureMode && !attachmentCount"
              id="document-attachments"
              class="action-link"
              name="document-attachments"
              title="Attachments"
              :icon="'VasionAttachmentsIcon'"
              :isDense="true"
              @vasionButtonClicked="trackByType('Attachments'); showAttachedDocumentsDialog = true;"
            />

            <VasionButton
              v-if="tasks && tasks.length && tasks.length > 0 && !isInSignatureMode"
              id="document-tasks"
              class="action-link"
              name="document-tasks"
              title="Tasks"
              :icon="'VasionTasksCheckIcon'"
              :isDense="true"
              @vasionButtonClicked="showTasksDialog = !showTasksDialog"
            />

            <VasionButton
              v-if="document.Workflow"
              id="document-comments"
              class="action-link"
              name="document-comments"
              title="Workflow History"
              :icon="'VasionWorkflowIcon'"
              :isDense="true"
              @vasionButtonClicked="historyMode = 'comments'; showHistoryDialog = !showHistoryDialog; trackByType('Workflow History'); "
            />

            <md-badge v-if="!isInSignatureMode && userComments.length" :md-content="userComments.length" :style="{ width: userCommentsBadgeWidth +'px' }">
              <VasionButton
                id="document-usercomments"
                class="action-link"
                name="document-usercomments"
                title="User Comments"
                :icon="'VasionWorkflowCommentsIcon'"
                :isDense="true"
                @vasionButtonClicked="historyMode = 'userComments'; showHistoryDialog = !showHistoryDialog"
              />
            </md-badge>
            <VasionButton
              v-if="!isInSignatureMode && !userComments.length"
              id="document-usercomments"
              class="action-link"
              name="document-usercomments"
              title="User Comments"
              :icon="'VasionWorkflowCommentsIcon'"
              :isDense="true"
              @vasionButtonClicked="historyMode = 'userComments'; showHistoryDialog = !showHistoryDialog"
            />

            <VasionButton
              id="document-line-tems"
              class="action-link"
              name="document-line-tems"
              title="Line Items"
              :icon="'VasionGridIcon'"
              :isDense="true"
              @vasionButtonClicked="showLineItemsDialog = !showLineItemsDialog"
            />

            <md-badge v-if="hasPackagers && packagersBadge && canViewDocumentPackages" :md-content="packagersBadge" class="badge-packagers">
              <VasionButton
                id="document-packagers"
                class="action-link"
                name="document-packagers"
                title="Packagers"
                :icon="'VasionFolderAdd'"
                :isDense="true"
                @vasionButtonClicked="showPackagersModal = !showPackagersModal"
              />
            </md-badge>
            <VasionButton
              v-if="hasPackagers && !packagersBadge && canViewDocumentPackages"
              id="document-packagers"
              class="action-link"
              name="document-packagers"
              title="Packagers"
              :icon="'VasionFolderAdd'"
              :isDense="true"
              @vasionButtonClicked="showPackagersModal = !showPackagersModal"
            />
            <VasionButton
              v-if="coCStatus !== false"
              id="coc-document"
              class="action-link"
              name="coc-button"
              :title="coCStatus === 'documentNotCertified' ? 'Document not certified.' : 'Certificate of Completion'"
              icon="VasionCertificate"
              :isDense="true"
              :isDisabled="coCStatus === 'documentNotCertified'"
              @vasionButtonClicked="showCoCModal = !showCoCModal"
            />
            <VasionButton
              v-if="aIPValuesPresent"
              id="field-validation"
              class="action-link"
              name="field-validation"
              title="Field Validation"
              :icon="meanConfidenceIcon"
              :isDense="true"
              @vasionButtonClicked="goToFieldValidation"
            />
          </div>
          <div v-if="!isNativeDocument && !isInSignatureMode" class="divider" />
          <div v-if="!isNativeDocument && !isInSignatureMode" class="divider" />
          <div id="image-div" class="md-layout-item">
            <div v-show="isInDocumentView" :id="idWithPageNumber" class="lightestGreyBackground mainImageDiv">
              <DocumentImage
                v-if="!isNativeDocument"
                ref="mainImage"
                :documentID="documentID"
                :pageNumber="currentPageNumber"
                :width="viewerWidth"
                :height="viewerHeight"
                :fileDetail="document.FileDetail"
                :largeDefaultLoadSize="true"
                :canDeleteAnnotations="!isInSignatureMode"
                :documentRequiresPassword="documentRequiresPassword"
                :docPassword="docPassword"
                :isSignatureDocument="isSignatureDocument"
                @imageLoaded="mainImageLoaded"
                @passwordEntered="passwordEntered"
                @backgroundImageLoaded="setZoom"
                @backToPreviousView="backToPreviousView()"
                @password-failed="documentPasswordFailed()"
                @emptyTextAnnotations="setEmptyTextAnnotations"
                @checkRightSidePanelMobile="checkRightSidePanelMobile"
              />
              <div v-else-if="canDownloadDocument">
                <div>
                  Click here to download this file.
                </div>
                <VasionButton
                  id="download-native-button"
                  :classProp="'primary'"
                  @vasionButtonClicked="downloadDocumentClick()"
                >
                  Download
                </vasionbutton>
              </div>
            </div>
            <div v-show="isInDataView" class="lightestGreyBackground data-view-container">
              <div class="data-view">
                <h1 class="data-view-header">
                  {{ formName }}
                </h1>
                <TheDocumentData
                  ref="documentData"
                  :document="document"
                  :currentAttributeFormId="currentAttributeFormID"
                  @fieldValueUpdated="updateLocalFieldValues"
                  @isValid="markIsValid"
                  @notValid="markNotValid"
                  @clean="markClean"
                  @dirty="markDirty('fromTheDocumentData')"
                />
              </div>
            </div>
            <div v-show="supportPDFViewer && isInPDFView" class="pdfiFrame">
              <Loading
                :active.sync="isPdfLoading"
                :is-full-page="false"
                :color="loaderColor"
                loader="dots"
                :background-color="loaderBackgroundColor"
              />
              <iframe
                id="pdfIFrame"
                marginheight="0"
                frameborder="0"
                :src="streamPDFData"
              />
            </div>
          </div>
          <div class="divider" />
          <div v-if="isInDocumentView || isInWorkspaceApp || isInPDFView" class="hide-under-720px-vw">
            <div
              class="action-links vasion-white-background"
            >
              <VasionButton
                v-if="displayDocumentSideBar"
                class="action-link collapse-side-bar"
                name="collapse-side-bar"
                title="Collapse Side Bar"
                :icon="'VasionArrowExpandRight'"
                :isDense="true"
                @vasionButtonClicked="toggleData('displayDocumentSideBar')"
              />
              <VasionButton
                v-else
                class="action-link collapse-side-bar"
                name="expand-side-bar"
                title="Expand Side Bar"
                :icon="'VasionArrowExpandLeft'"
                :isDense="true"
                @vasionButtonClicked="toggleData('displayDocumentSideBar')"
              />
            </div>
          </div>
          <div class="divider" />
          <div
            v-if="(buttonOptions.length || isInDocumentView || isInWorkspaceApp || isInPDFView) && displayDocumentSideBar"
            id="attributesTabDiv"
            :class="{ 'attributes-tab-div-closed': rightSidePanelMobile, 'attributes-tab-div': !rightSidePanelMobile }"
            class="md-layout-item"
          >
            <div class="right-side-panel-content">
              <div v-if="showWorkflowDiv" class="attributes-padding-div">
                <div v-for="(button, index) in buttonOptions" :key="index" class="attributes-padding-div-buttons">
                  <VasionButton
                    :id="button.UniqueName"
                    :classProp="button.Label === 'Approve' ? 'primary' : 'secondary'"
                    :isFullWidth="true"
                    @vasionButtonClicked="setWorkflowAction(button.UniqueName)"
                  >
                    {{ button.Label }}
                  </VasionButton>
                </div>
                <md-field id="new-comment">
                  <label v-if="!newComment">Comment ...</label>
                  <md-textarea v-model="newComment" />
                </md-field>
                <div class="alignRight">
                  <VasionButton
                    :id="'comment'"
                    :classProp="'secondary-grey'"
                    :isDisabled="!newComment"
                    @vasionButtonClicked="commentButtonClicked"
                  >
                    Add Comment
                  </VasionButton>
                </div>
              </div>
              <div v-if="isInSignatureMode" class="attributes-padding-div">
                <VasionButton
                  v-if="parseInt(UserID, 10) === parseInt(signatureDocumentInitiatorUserID, 10)"
                  id="recall-button"
                  classProp="primary"
                  :isFullWidth="true"
                  @vasionButtonClicked="showRecallPrompt = true"
                >
                  Recall
                </VasionButton>
                <VasionButton
                  :id="'complete'"
                  :classProp="'primary'"
                  :isFullWidth="true"
                  :isDisabled="!signatureZonesCompleted"
                  @vasionButtonClicked="showCompleteSignatureModal = !showCompleteSignatureModal"
                >
                  Complete
                </VasionButton>
                <VasionButton
                  v-if="isReviewer"
                  :id="'reviewed'"
                  :classProp="'primary'"
                  :isFullWidth="true"
                  @vasionButtonClicked="reviewedSignatureDocument"
                >
                  Reviewed
                </VasionButton>
                <VasionButton
                  :id="'reject'"
                  :classProp="'secondary'"
                  :isFullWidth="true"
                  :isDisabled="!hasPendingSignatureZones && !isReviewer"
                  @vasionButtonClicked="toggleRejectSignature"
                >
                  Reject
                </VasionButton>
                <VasionButton
                  v-if="signatureDocumentInitiatorUserID.toString() === UserID.toString()"
                  :id="'resend'"
                  :classProp="'secondary'"
                  :isFullWidth="true"
                  @vasionButtonClicked="resendLinkButtonClicked"
                >
                  Resend
                </VasionButton>
                <textarea
                  v-model="signatureComment"
                  :class="{ 'error-state-container': missingCommentForReject }"
                  class="comment-textarea vasion-textarea"
                  :placeholder="missingCommentForReject ? 'a comment is required to reject...' : 'Leave a comment...'"
                  rows="2"
                />
                <div class="alignRight">
                  <VasionButton
                    :id="'comment'"
                    :classProp="'secondary-grey'"
                    :isDisabled="!signatureComment"
                    @vasionButtonClicked="signatureCommentButtonClicked"
                  >
                    Comment
                  </VasionButton>
                </div>
              </div>
              <div>
                <div
                  v-if="isInDocumentView && !isSignatureDocLinkView && !(isInSignatureMode && !hasObjectID)"
                  id="attribute-top-section"
                  :class="{ 'attribute-top-section-with-workflow': showWorkflowDiv, 'attribute-top-section-without-workflow': !showWorkflowDiv }"
                >
                  <VasionButton
                    v-if="showStartWorkflowButton"
                    id="btnStartWorkflow"
                    name="btnStartWorkflow"
                    title="Start Workflow"
                    :classProp="'secondary'"
                    :isFullWidth="true"
                    @vasionButtonClicked="toggleStartWorkflow()"
                  >
                    Start Workflow
                  </VasionButton>
                  <div v-if="attributeForms && attributeForms.length !== 0">
                    <VasionDropList
                      v-slot="slotItem"
                      v-model="currentAttributeFormID"
                      :dataArray="attributeForms"
                      :isDisabled="!showSaveButton || isInSignatureMode"
                      :title="`${$formsLabel.toUpperCase()}`"
                      inputType="plain-list"
                      valueName="value"
                      displayName="name"
                      width="100%"
                      class="attribute-droplist"
                    >
                      {{ slotItem.item.name }}
                    </VasionDropList>
                    <VasionButton
                      v-show="showLookupButton"
                      id="database-lookup-button"
                      :classProp="'secondary'"
                      :isFullWidth="true"
                      @vasionButtonClicked="attributeFieldLookUp"
                    >
                      Database Lookup
                    </VasionButton>
                  </div>
                  <div v-else>
                    <div class="no-objects-warning">
                      <b>Warning:</b> No {{ $formsLabelPlural.toLowerCase() }} were found.
                    </div>
                    <VasionInput
                      class="attribute-droplist"
                      inputType="top-white"
                      isDisabled
                      :placeholder="`No ${$formsLabelPlural.toLowerCase()} found.`"
                      :title="$formsLabel.toUpperCase()"
                      width="100%"
                    />
                  </div>
                </div>
                <div v-if="isInDocumentView || isInPDFView" class="index-field-list-padding">
                  <IndexFieldList
                    :key="attributeFieldsKey"
                    ref="attributeFieldsComponent"
                    :disableAllFields="!showSaveButton"
                    :fields="indexFields"
                    :enforceFieldSecurity="true"
                    :selectorOnly="false"
                    :showSubmit="false"
                    :fieldWidth="225"
                    :isInDocumentView="isInDocumentView"
                    :useMultiSelectLists="true"
                    @fieldValueUpdated="updateLocalFieldValues"
                    @clean="markClean"
                    @dirty="markDirty"
                    @notValid="markNotValid"
                    @isValid="markIsValid"
                  />
                </div>
              </div>
              <div v-if="documentAttributeFields.length && !isSignatureDocLinkView" class="padding-all">
                <VasionInput
                  v-if="document.Workflow"
                  id="workflow-name-text-box"
                  title="WORKFLOW NAME"
                  name="workflow-name-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="document.Workflow.Name"
                />
                <VasionInput
                  v-if="document.Workflow"
                  id="workflow-step-text-box"
                  title="WORKFLOW STEP"
                  name="workflow-step-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="document.Workflow.StepName"
                />
                <VasionInput
                  v-if="canViewStatus"
                  id="workflow-status-text-box"
                  title="WORKFLOW STATUS"
                  name="workflow-status-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="workflowStatus"
                />
                <VasionInput
                  v-if="canViewApprover"
                  id="workflow-approver-text-box"
                  title="WORKFLOW APPROVER"
                  name="workflow-approver-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="workflowApprover"
                />
                <VasionInput
                  v-if="canViewInitiator"
                  id="workflow-initiator-text-box"
                  title="WORKFLOW INITIATOR"
                  name="workflow-initiator-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="workflowInitiator"
                />
                <VasionInput
                  v-if="canViewCreatedBy"
                  id="created-by-text-box"
                  title="CREATED BY"
                  name="created-by-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="objectCreatedBy"
                />
              </div>
              <div class="padding-all">
                <div v-if="canViewDueDate && !isSignatureDocLinkView && !(isInSignatureMode && !hasObjectID)">
                  <div v-if="isDueDateReadOnly || !showSaveButton || isInSignatureMode" class="extra-padding-bottom">
                    <VasionInput
                      v-model="dueDate"
                      class="input-style"
                      inputType="top-white"
                      width="100%"
                      :isDisabled="true"
                      :title="dueDateLabel"
                    />
                  </div>
                  <VasionDateTimeField
                    v-else
                    v-model="dueDate"
                    :label="dueDateLabel"
                    type="date"
                    @close="dirtyDueDateState = true"
                  />
                </div>

                <VasionDropList
                  v-if="!isSignatureDocLinkView && !(isInSignatureMode && !hasObjectID)"
                  v-slot="slotItem"
                  v-model="priority"
                  :isDisabled="!showSaveButton || isInSignatureMode"
                  :dataArray="priorities"
                  :width="'100%'"
                  title="PRIORITY"
                  type="plain-list"
                  displayName="priorityName"
                  valueName="priorityID"
                  @input="dirtyPriorityState = true"
                >
                  {{ slotItem.item.priorityName }}
                </VasionDropList>
              </div>
              <div v-if="isSignatureDocLinkView && isAmplitudeAvailable" class="legal-note">
                <p>We use analytics to improve the application performance, for more information see Vasion's <a href="https://vasion.com/cookie-information/" target="_blank">Cookie</a> and <a href="https://vasion.com/privacy-policy/" target="_blank">Privacy Policy</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="vasion-snackbar"
      ref="vasionSnackbar"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <TheDocumentVersions
      :active.sync="showVersions"
      :documentId="documentID"
      :isReadOnly="isReadOnly"
      @close="showVersions = !showVersions"
      @createVersionClick="createVersionClick"
      @versionSelect="versionSelect"
    />

    <TheDocumentNotes
      :active.sync="showNotesDialog"
      :documentId="documentID"
      @close="showNotesDialog = !showNotesDialog"
      @goToPage="goToPageEmit"
    />

    <TheDocumentAttachments
      :active.sync="showAttachedDocumentsDialog"
      :documentId="documentID"
      :hasLinkedSearch="document.HasLinkedSearch"
      :folderId="documentFolderID"
      @close="showAttachedDocumentsDialog = false"
    />

    <TheDocumentTasks
      :active.sync="showTasksDialog"
      :documentId="documentID"
      :tasks="tasks"
      :workflow="workflow"
      @close="showTasksDialog = !showTasksDialog"
      @tasks-updated="updateTasks"
    />

    <TheDocumentLineItems
      :active.sync="showLineItemsDialog"
      :documentId="documentID"
      @close="showLineItemsDialog = !showLineItemsDialog"
    />

    <TheDocumentPackagers
      v-if="showPackagersModal"
      :active.sync="showPackagersModal"
      :data="document.DocPackageViewer"
      :currentDocId="documentID"
      @close="showPackagersModal = false"
      @submitted="packageSubmitted"
    />

    <TheCoCDocument
      v-if="showCoCModal"
      :active.sync="showCoCModal"
      :documentId="documentID"
      :documentName="documentName"
      @close="showCoCModal = false"
    />

    <md-dialog id="burn-annotations-dialog" :md-active.sync="showBurnAnnotations" :md-click-outside-to-close="false">
      <VasionConfirmationDialog
        :message="'Are you sure you want to Burn Annotations?'"
        @noButtonClick="toggleData('showBurnAnnotations')"
        @yesButtonClick="confirmBurnAnnotations()"
      />
    </md-dialog>

    <VasionGeneralModal
      id="index-field-list-text-stamp"
      :rejectButtonText="'CLOSE'"
      :showConfirmButton="true"
      modalTitle="Index Fields"
      :modalType="'slot'"
      :sync="showTextStampFieldsDialog"
      @noButtonClick="cancelTextStamp"
      @confirmButtonClick="requestTextStampImg"
    >
      <div class="file-pond-block-wrapper non-dialog">
        <IndexFieldList
          id="index-field-list-text-stamp"
          ref="indexFieldListForTextStamp"
          :fields="indexFieldsForTextStamp"
          :selectorOnly="false"
          :showSubmit="false"
          :useMultiSelectLists="true"
          :enforceFieldSecurity="true"
          @fieldValueUpdated="updateLocalFieldValues"
          @isValid="markFieldAsValid"
          @notValid="markFieldAsNotValid"
          :disableAllFields="!showSaveButton"
          :isInDocumentView="isInDocumentView"
          @clean="markClean"
          @dirty="markDirty"
        />
      </div>
    </VasionGeneralModal>

    <md-dialog id="apply-signature-dialog" :md-active.sync="showAddSignatureDialog" :md-click-outside-to-close="false">
      <VasionApplySignature
        :currentSignatureImage="currentSignatureImage"
        :documentID="documentID"
        :geolocation="geolocation"
        :ipAddress="ipAddress"
        :showLegalBound="!isInSignatureMode"
        :annotationType="signatureAnnotationType"
        :allowAdoption="pendingSignatureZonesCounter > 1"
        :savedSignatureOrInitials="savedSignatureOrInitials"
        :applyTimestamp="applyTimestamp"
        @ok-click="applyNewSignature"
        @cancel-click="toggleShowAddSignatureDialog"
      />
    </md-dialog>

    <md-dialog id="print-document-modal" :md-active.sync="showPrintModal" :md-click-outside-to-close="false">
      <VasionPrintOptions
        :supportIncludeHistory="signatureDocumentGUID != '' && !isInSignatureMode"
        @okClick="printSelectedDocuments"
        @cancelClick="showPrintModal = !showPrintModal"
      />
    </md-dialog>

    <md-dialog id="download-document-modal" :md-active.sync="showDownloadDocumentDialog" :md-click-outside-to-close="false">
      <VasionDownloadOptions
        :fileExtension="fileExtension"
        :supportIncludeHistory="signatureDocumentGUID != '' && !isInSignatureMode"
        @okClick="downloadDocument"
        @cancelClick="showDownloadDocumentDialog = !showDownloadDocumentDialog"
      />
    </md-dialog>

    <md-dialog id="email-modal" :md-active.sync="showEmailModal" :md-click-outside-to-close="false" class="email-modal-div">
      <DocumentEmail
        :documentIDString="documentIDString"
        :isInDataView="isInDataView"
        :supportBurnAnnotations="isTiffOrPDF"
        :supportIncludeHistory="signatureDocumentGUID != '' && !isInSignatureMode"
        @email-result="showEmailSnackbar"
        @close="closeDocumentEmail"
      />
    </md-dialog>

    <md-dialog id="reassign-forward-modal" :md-active.sync="showGroupModal" :md-click-outside-to-close="false" class="email-modal-div">
      <VasionUserGroupSelect
        :modalTitle="modalTitle"
        :groupFilterId="userGroupAssignConfig.groupId"
        :userOnly="userGroupAssignConfig.userOnly"
        @noButtonClick="showGroupModal = !showGroupModal"
        @yesButtonClick="handleForwardReassign"
      />
    </md-dialog>

    <md-dialog id="multipleLookupDialog" :md-active.sync="showMultipleLookupDialog" :md-click-outside-to-close="false">
      <div>
        <MultipleLookups
          :tableValues="multipleLookupResults"
          @yesButtonClick="updateLookupValues"
          @noButtonClick="handleCancelMultipleLookups"
        />
      </div>
    </md-dialog>

    <VasionRotateDropDown
      v-if="showRotateActions && !isInSignatureMode"
      :style="dropdownLocation"
      :isReadOnly="isReadOnly"
      @loseFocus="showRotateActions = !showRotateActions"
      @rotateLeft="rotateLeft"
      @rotateRight="rotateRight"
      @permanentlyRotateLeft="permanentlyRotateLeft"
      @permanentlyRotateRight="permanentlyRotateRight"
    />

    <VasionGeneralModal
      id="upload-new-version-modal"
      :rejectButtonText="'CLOSE'"
      :showConfirmButton="false"
      modalTitle=""
      :modalType="'slot'"
      :sync="showUploadNewVersionModal"
      @noButtonClick="showUploadNewVersionModal = !showUploadNewVersionModal"
    >
      <div class="file-pond-block-wrapper non-dialog">
        <file-pond
          ref="pond"
          class="new-document"
          :server="serverOptions"
          :label-idle="filePondLabel"
        />
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'CLOSE'"
      :showConfirmButton="false"
      modalTitle=""
      :modalType="'slot'"
      :sync="showUploadNewPageModal"
      @noButtonClick="toggleUploadAndPage"
    >
      <div class="file-pond-block-wrapper">
        <file-pond ref="pondPage" :server="serverOptionsPages" />
      </div>
    </VasionGeneralModal>

    <div v-if="addInitialsDropListActive" class="initials-drop-list" :style="dropdownLocation">
      <ul class="vasion-ul">
        <li v-if="userSignature" @click="addSavedSignature">
          <div class="signature-group">
            <img class="signature-image" :src="userSignature" alt="User Signature">
            <VasionRemoveCircleIcon class="circle-icon-color" />
          </div>
        </li>
        <li v-if="userInitials" @click="addSavedSignatureInitials">
          <div class="signature-group">
            <img class="signature-image" :src="userInitials" alt="User Initials">
            <VasionRemoveCircleIcon class="circle-icon-color" />
          </div>
        </li>
        <li class="add-new-signature" @click="toggleShowAddSignatureDialog">
          <div class="flex-display">
            <VasionGestureIcon class="gesture-gray" :class="{ 'vasion-icon-disabled': isReadOnly }" />
            <div>Add New Signature</div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="addStampsDropListActive" class="stamps-drop-list" :style="dropdownLocation">
      <ul class="vasion-ul">
        <li v-for="(stamp, index) in annotationImageList" :key="index" @click="addNewStamp(stamp)">
          <div class="stamp-group">
            <span>{{ stamp.name }}</span><br>
            <img class="stamp-image" :src="`${base64DataPrefix}${stamp.base64String}`" alt="Stamp">
          </div>
        </li>
      </ul>
    </div>

    <div v-if="addTextStampsDropListActive" class="stamps-drop-list text-stamps" :style="dropdownLocation">
      <ul class="vasion-ul">
        <li v-for="(stamp, index) in textStampAnnotationList" :key="index" @click="initializeNewTextStamp(stamp)">
          <div class="stamp-group">
            <span>{{ stamp.StampName }}</span><br>
          </div>
        </li>
      </ul>
    </div>

    <md-dialog
      id="customActionsDialog"
      :class="{'dropDownOpen': dropDownOpen}"
      :md-active.sync="showIndexPrompt"
      :md-click-outside-to-close="false"
    >
      <div class="document-title index-field-title">
        Field Information needed
      </div>
      <div class="custom-actions">
        <IndexFieldList
          ref="attributeFieldsComponent"
          :fields="actionsFieldList"
          :enforceFieldSecurity="true"
          :sideView="true"
          :selectorOnly="false"
          :showSubmit="true"
          :fieldWidth="225"
          :isInDocumentView="isInDocumentView"
          :useMultiSelectLists="true"
          :forPrompt="true"
          @fieldValueUpdated="updateLocalFieldValues"
          @isValid="markFieldAsValid"
          @notValid="markFieldAsNotValid"
          @submitted="handleIndexPrompt"
          @submitCancelled="showIndexPrompt = !showIndexPrompt"
          @openDropDown="changeModalClass"
        />
      </div>
    </md-dialog>

    <VasionLegallyBindingConsentModal
      id="consent-modal"
      :documentID="documentID"
      :geolocation="geolocation"
      :ipAddress="ipAddress"
      :sync="showCompleteSignatureModal"
      @confirmButtonClick="completeSignatureDocument"
      @noButtonClick="showCompleteSignatureModal = false"
    />

    <VasionGeneralModal
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are you sure you want to Reject?"
      :sync="showRejectSignatureModal"
      @confirmButtonClick="rejectSignatureDocument"
      @noButtonClick="showRejectSignatureModal = !showRejectSignatureModal"
    />

    <VasionGeneralModal
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are you sure you want to Recall?"
      :sync="showRecallPrompt"
      @confirmButtonClick="recallSignatureDocument"
      @noButtonClick="showRecallPrompt = false"
    />

    <VasionGeneralModal
      ref="addPageModal"
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Upload'"
      modalTitle="Add Pages"
      :modalType="'slot'"
      :confirmButtonDisabled="!arePagesReadyForUpload"
      :sync="showAddPagesModal"
      @noButtonClick="clearPageSelectionAndInsertOption"
      @confirmButtonClick="uploadDocument"
    >
      <AddPages
        ref="addPage"
        :currentPageNumber="currentPageNumber"
        :totalPages="totalPages"
        :useStore="useStoreAddPage"
        @add="uploadNewPageToggle"
        @upload="uploadPage"
        @success="showUploadMessage(); reloadDocument($route.params.documentID); clearPageSelectionAndInsertOption()"
      />
    </VasionGeneralModal>

    <VasionGeneralModal
      id="prompt-password"
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :modalType="'slot'"
      :message="'Are you sure'"
      :sync="showPromptPassword"
      @noButtonClick="enteredPassword = ''; showPromptPassword = !showPromptPassword"
      @confirmButtonClick="comparePasswords"
    >
      <div class="prompt-password-dialog">
        <div class="password-label">
          Please type the correct password
        </div>
        <div class="entered-password">
          <VasionInput
            v-model="enteredPassword"
            class="input-style"
            inputType="top-white"
            placeholder="Enter Password..."
            type="password"
            autocomplete="off"
          />
        </div>
      </div>
    </VasionGeneralModal>

    <md-dialog
      id="start-workflow-select-dialog"
      :md-click-outside-to-close="false"
      :md-active.sync="showWorkflowList"
    >
      <VasionSelectWorkflow
        :workflowList="workflowList"
        :showErrorLabel="showErrorLabel"
        @cancelButtonClick="showWorkflowList = !showWorkflowList"
        @continueButtonClick="continueWorkflowSelected"
      />
    </md-dialog>
    <md-dialog id="reassign-forward-modal" :md-active.sync="showUserSelectModal" :md-click-outside-to-close="false" class="email-modal-div">
      <VasionUserGroupSelect
        :modalTitle="'Select User'"
        :groupFilterId="userGroupAssignConfig.groupId"
        :userOnly="true"
        @noButtonClick="showUserSelectModal = !showUserSelectModal"
        @yesButtonClick="handleUserSelected"
      />
    </md-dialog>
    <md-dialog id="action-password-prompt" :md-active.sync="showActionPasswordDialog" :md-click-outside-to-close="false">
      <PasswordPrompt
        ref="passwordPrompt"
        inputTitle="Enter Workflow Password"
        @close="showActionPasswordDialog = false"
        @passwordEntered="setActionPassword"
      />
    </md-dialog>
    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Delete'"
      :confirmButtonDisabled="disableDeletePagesButton"
      modalTitle="Delete Pages"
      :modalType="'slot'"
      :sync="showDeletePagesDialog"
      @confirmButtonClick="showDeletePagesConfirmDialog = true"
      @noButtonClick="showDeletePagesDialog = !showDeletePagesDialog"
    >
      <DocumentDeletePages
        :documentID="documentID"
        :pageNumber="currentPageNumber"
        :fileDetail="document.FileDetail"
        @updateSelectedPages="updateSelectedPages"
      />
    </VasionGeneralModal>
    <VasionGeneralModal
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are you sure you want to Delete these pages?"
      :sync="showDeletePagesConfirmDialog"
      @confirmButtonClick="deletePages"
      @noButtonClick="showDeletePagesConfirmDialog = !showDeletePagesConfirmDialog"
    />
    <VasionGeneralModal
      :modalType="'slot'"
      :hideButtons="true"
      :sync="showHistoryDialog"
      :modalTitle="historyModalTitle"
      @noButtonClick="showHistoryDialog = !showHistoryDialog"
    >
      <div v-if="signatureDocumentGUID && !isInSignatureMode && historyMode !== 'comments'" id="history-tab-buttons" class="md-layout">
        <VasionButton :classProp="documentTabClass" @vasionButtonClicked="signatureTabSelected = false">
          Document
        </VasionButton>
        <VasionButton :classProp="signatureTabClass" @vasionButtonClicked="signatureTabSelected = true">
          Signature
        </VasionButton>
      </div>
      <DocumentSignatureHistory
        v-if="signatureDocumentGUID && signatureTabSelected"
        :documentHistory="signatureDocumentHistoryValues"
        @close="showHistoryDialog = !showHistoryDialog"
        @exportToPdf="exportSigDocumentToPDF()"
      />
      <TheDocumentHistory
        v-if="!signatureTabSelected"
        :comments="(historyMode === 'userComments') ? userComments : workflowComments"
        :documentId="documentID"
        :mode="historyMode"
        @close="showHistoryDialog = !showHistoryDialog"
      />
    </VasionGeneralModal>
    <VasionGeneralModal
      id="cancel-confirmation-modal"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="'Your changes have not been saved, are you sure you want to navigate away?'"
      :sync="showDirtyModal"
      @confirmButtonClick="dirtyModalOKClick()"
      @noButtonClick="dirtyModalCancelClick()"
    />

    <DefaultDocumentViewSelectionModal
      :show="showDefaultDocumentViewSelectionModal"
      :currentSelection="defaultDocumentView"
      @cancelButtonClicked="showDefaultDocumentViewSelectionModal = false"
      @saveButtonClicked="showDefaultDocumentViewSelectionModal = false"
    />
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
// Out of order due to the fact that loading needs to happen before Apply Signature
import Loading from 'vue-loading-overlay';
import DefaultDocumentViewSelectionModal from '@/components/document/DefaultDocumentViewSelectionModal.vue'
import DocumentEmail from '@/components/document/DocumentEmail.vue';
import DocumentDeletePages from '@/components/document/DocumentDeletePages.vue';
import DocumentSignatureHistory from '@/components/document/DocumentSignatureHistory.vue';
import TheDocumentData from '@/views/Document/TheDocumentData.vue';
import AddPages from '@/components/storage/AddPages.vue';
import DocumentImage from '@/components/document/DocumentImage.vue';
import IndexFieldList from '@/components/IndexFieldList.vue';
import MultipleLookups from '@/components/vault/MultipleLookups.vue'
import TheCoCDocument from '@/components/document/TheCoCDocument.vue'
import TheDocumentAttachments from '@/components/document/TheDocumentAttachments.vue';
import TheDocumentHistory from '@/components/document/TheDocumentHistory.vue';
import TheDocumentLineItems from '@/components/document/TheDocumentLineItems.vue';
import TheDocumentNotes from '@/components/document/TheDocumentNotes.vue';
import TheDocumentPackagers from '@/components/document/TheDocumentPackagers.vue';
import TheDocumentTasks from '@/components/document/TheDocumentTasks.vue';
import TheDocumentVersions from '@/components/document/TheDocumentVersions.vue';
import { toBase64 } from '@/store/helperModules/storage.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import PasswordPrompt from '@/components/security/PasswordPrompt.vue'
import { removeIllegalChars, testDownloadHasFileExtension, testIsGuid, openUrlInNewBrowserWindow } from '@/store/helperModules/common.module'
import { DocumentViewOptions } from '@/enums/documentViewOptions.js'
import publicIp from 'public-ip'
import { cloneDeep, isEmpty, find as lodashFind } from 'lodash'
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Document',
  components: {
    AddPages,
    DefaultDocumentViewSelectionModal,
    DocumentDeletePages,
    DocumentEmail,
    DocumentImage,
    DocumentSignatureHistory,
    IndexFieldList,
    Loading,
    MultipleLookups,
    PasswordPrompt,
    TheCoCDocument,
    TheDocumentAttachments,
    TheDocumentData,
    TheDocumentHistory,
    TheDocumentLineItems,
    TheDocumentNotes,
    TheDocumentPackagers,
    TheDocumentTasks,
    TheDocumentVersions,
  },
  async beforeRouteLeave(to, from, next) {
    to.params.previousPage = this.$route.params.previousPage
    await this.$store.dispatch('common/setIsSignatureDocLinkView', false)
    await this.$store.dispatch('document/setTextStampAnnotationList', [])
    if (this.streamPDFRequestController != null) {
      this.streamPDFRequestController.abort()
    }
    if (this.isDirty && to.name !== 'Document') {
      this.routeNext = next
      this.showDirtyModal = true
    } else {
      document.title = 'Vasion'
      next(true)
    }
  },
  data: function () {
    return {
      action: '',
      actionPassword: '',
      actionPasswordRequired: false,
      actionsFieldList: [],
      actionTaken: '',
      addInitialsDropListActive: false,
      additionalDocumentIds: '',
      addStampsDropListActive: false,
      addTextStampsDropListActive: false,
      adoptedInitials: '',
      adoptedSignature: '',
      allowPerformAction: true,
      annotation: {
        highlight: { type: 'highlight', color: 'rgba(254, 172, 0, 0.2)', subType: '' },
        redaction: {
          black: { type: 'redaction', color: 'black', subType: '' },
          white: { type: 'redaction', color: 'white', subType: '' },
          yellow: { type: 'redaction', color: 'yellow', subType: '', textStamp: true },
        },
        signature: { type: 'signature', color: '', subType: '' },
        stamp: { type: 'stamp', color: '', subType: '' },
        text: { type: 'text', color: '', subType: '' },
        checkbox: { type: 'text', color: '', subType: '' },
      },
      applyTimestamp: true,
      aIPValuesPresent: false,
      attributeFieldsKey: 0,
      buttonOptions: [],
      contextApp: 'Annotations',
      currentActiveSideBar: '',
      currentAttributeFormID: {},
      currentPageNumber: this.$route.params.startingPage,
      currentPageRotation: 0,
      currentSignatureImage: '',
      currentTextStampAnnID: null,
      currentWorkflowAction: '',
      currentZonePage: 1,
      defaultDocumentView: '',
      deleteAttachedFile: false,
      dirtyAttributeState: false,
      dirtyDueDateState: false,
      dirtyPriorityState: false,
      disableDeletePagesButton: true,
      displayDocumentSideBar: true,
      displayNameInputValue: this.documentName,
      docPassword: '',
      document: {},
      documentGuid: '',
      documentRequiresPassword: false,
      dropdownLocation: '',
      dropDownOpen: false,
      downloadLimitsReached: false,
      dueDate: '',
      dueDateLabel: 'Workflow Due Date',
      emptyTextAnnotations : 0,
      enableSignatureAnnotations: false,
      generatePDFEnabled: false,
      enteredPassword: '',
      errorLoadingDocument: false,
      firstRun: true,
      firstTimeImageLoad: true,
      formName: '',
      geolocation: '',
      hasMoreThanOnePendingSignatureZone: false,
      hasPendingSignatureZones: false,
      historyMode: '',
      indexFields: [],
      indexFieldsForTextStamp: [],
      indexPromptName: '',
      initialAttributeFieldListSize: 0,
      invalidFields: [],
      ipAddress: '',
      isAmplitudeAvailable: false,
      isDirtyFromDisplayName: false,
      isDirtyFromTheDocumentData: false,
      isLoading: true,
      isPdfLoading: true,
      isReviewer: false,
      isValid: true,
      isViewer: false,
      loaderBackgroundColor,
      loaderColor,
      loadingFullPage: false,
      localBackRoute: '',
      localFieldValues: [],
      meanConfidenceIcon: '',
      modalTitle: '',
      missingCommentForReject: false,
      multipleLookupResults: [],
      newComment: '',
      pages: [],
      pendingSignatureAnnotationIDsForCurrentUser: [],
      pendingSignatureZonesArray: [],
      pendingSignaturesPageNumbers: [],
      promptData: {},
      rightSidePanelMobile: true,
      savedSignatureOrInitials: '',
      selectedAttachedDocumentId: 0,
      selectedPagesForDelete: [],
      serverOptions: { process: this.processHandler },
      serverOptionsPages: { process: this.processPagesHandler },
      showActionPasswordDialog: false,
      showAddPagesModal: false,
      showAddSignatureDialog: false,
      showAttachedDocumentsDialog: false,
      showBurnAnnotations: false,
      showCoCModal: false,
      showCompleteSignatureModal: false,
      showConfirmAttachedDocumentsMergeDeleteDialog: false,
      showDeletePagesConfirmDialog: false,
      showDeletePagesDialog: false,
      showDirtyModal: false,
      showDownloadDocumentDialog: false,
      showEllipsisIcon: false,
      showEllipsisToolbar: false,
      showEmailModal: false,
      showErrorLabel: false,
      showGroupModal: false,
      showHistoryDialog: false,
      showIndexPrompt: false,
      showLineItemsDialog: false,
      showLookupButton: false,
      showMultipleLookupDialog: false,
      showNotesDialog: false,
      showPackagersModal: false,
      showPrintModal: false,
      showPromptPassword: false,
      showRecallPrompt: false,
      showRejectSignatureModal: false,
      showRotateActions: false,
      showSnackbar: false,
      showUserSelectModal: false,
      showTasksDialog: false,
      showTextStampFieldsDialog: false,
      showToolbar720: false,
      showUploadNewPageModal: false,
      showUploadNewVersionModal: false,
      showVersions: false,
      showDefaultDocumentViewSelectionModal: false,
      showViewModeMenu: false,
      showWorkflowList: false,
      signatureAnnotationType: 'Signature',
      signatureComment: '',
      signatureDocumentHistoryValues: [],
      signatureDocumentInitiatorUserID: null,
      signatureIconClickEvent: {},
      signatureTabSelected: false,
      signingSignatureAnnotation: false,
      snackbarImage: true,
      snackbarSubTitle: 'Attributes Saved!',
      snackbarTitle: '',
      startWorkflowPayload: {},
      streamPDFData: '',
      streamPDFRequestController: null,
      tasks: [],
      textStampSize: {width: 0, height: 0},
      thumbnails: [],
      displayNameInEditMode: false,
      totalSignatureZones: 0,
      uploadIndex: 0,
      userAssignPayload: {
        Values: [
          {
            Key: '',
            Value: {
              Values: [
                {
                  Key: '',
                  Value: '',
                },
              ],
            },
          },
        ],
      },
      userComments: [],
      userGroupAssignConfig: {
        type: '',
        userOnly: false,
        groupId: 0,
      },
      userPassword: '',
      useStoreAddPage: false,
      viewerHeight: 2112,
      viewerWidth: 1632,
      viewMenuButtonConfig: {},
      viewMode: this.$route.name === 'DocumentData' ? DocumentViewOptions.Data : DocumentViewOptions.Document,
      viewMode_Data: DocumentViewOptions.Data,
      viewMode_Data_Icon: 'VasionColumnViewIcon',
      viewMode_DefaultSelector: 'defaultSelector',
      viewMode_DefaultSelector_Icon: 'VasionEditIcon',
      viewMode_Document: DocumentViewOptions.Document,
      viewMode_Document_Icon: 'VasionCarouselHorizontalIcon',
      viewMode_PDF: DocumentViewOptions.Pdf,
      viewMode_PDF_Icon: 'VasionPdfViewIcon',
      viewportWidth720: 1293,   // Each time a new piece is added to the toolbar, this needs to be revisisted
      viewportWidth1280: 1574, // Each time a new piece is added to the toolbar, this needs to be revisisted
      workflow: {},
      workflowComments: [],
      workflowID: '',
      workflowList: [],
    }
  },
  computed: {
    coCStatus() {
      let status = false
      const certifyDocument = this.document.CertifyDocument
      const coCIncluded = this.$store.state.document.coCIncluded
      if (!certifyDocument && !coCIncluded) return status
      if (certifyDocument && !coCIncluded) status = "documentNotCertified"
      if (certifyDocument && coCIncluded) status = "documentCertified"
      return status
    }, 
    filteredFieldOptions() { return this.$store.state.document.filteredFieldOptions },
    activeSideBarSelection() {
      return this.$store.state.mainViews.mainNav
    },
    arePagesReadyForUpload() {
      const pagesArray = this.$store.state.document.uploadedPagesToAdd
      let isReady = false
      if (this.$store.state.document.uploadedPagesPageNumber !== '' && this.$store.state.document.uploadedPagesInsertOption !== '' 
        && this.$store.state.document.uploadedPagesPageNumber <= this.totalPages && this.$store.state.document.uploadedPagesPageNumber > 0) {
        isReady = true
      }

      if (pagesArray.length > 0 && isReady) {
        isReady = pagesArray[0].fileName !== ''
      } else {
        isReady = false
      }
      return isReady
    },
    areRequiredFieldsFilled(){
      let result = true;
      if (this.indexFields)
      {
        this.indexFields.forEach(field => {
          if (field.required)
          {
            let found = false;
            if (this.localFieldValues)
            {
              for (let i = 0; i< this.localFieldValues.length; i++) {
                if (this.localFieldValues[i].name === field.value) {
                  found = true
                  if (this.localFieldValues[i].value.length === 0) {
                    result = false
                  }
                }
              }
            }
            if (!found) {
              result = false
            }
          }
        })
      }
      return result
    },
    annotationImageList() { return this.$store.state.document.annotationImageList },
    attachmentCount() { return !this.$store.state.document.linkedDocuments || this.$store.state.document.linkedDocuments?.tableRows?.length },
    attachmentBadgeWidth() { return this.calculateBadgeWidth(this.attachmentCount) },
    attributeForms() {
      let returnArray = []
      returnArray = this.document.IndexForms?.Values?.map((v) => {
        return {
          name: v.Value,
          value: v.Key,
        }
      })

      return returnArray ?? []
    },
    base64DataPrefix() { return this.$store.state.digitalSignature.base64DataPrefix },
    canAddAnnotation() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canAddAnnotation'] },
    canAddPages() { return this.document?.CanAddPages },
    canAddOrDelete() {
      return !this.isReadOnly
        && this.isTiffOrPDF
        && this.canMergeDocuments
        && this.canAddPages
        && this.isInDocumentView
        && !this.isSignatureDocument
    },
    canAssignToWorkflow() { return this.$store.getters['common/canAssignToWorkflow'] },
    canBurnAnnotations() { return this.$store.getters['common/canBurnAnnotations'] },
    canDeletePages() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canDeletePages'] },
    canDownloadDocument() {
      return (!this.document.readOnlyFolderInfo || this.document.readOnlyFolderInfo.CanExport)
        && this.$store.getters['common/canDownloadDocument']
        && !this.downloadLimitsReached
    },
    canEditFieldData() { return this.$store.getters['common/canEditDocumentFieldValues'] },
    canEmailDocument() { return (!this.document.readOnlyFolderInfo || this.document.readOnlyFolderInfo.CanEmail) && this.$store.getters['common/canEmailDocument'] },
    canGeneratePDF() { return this.$store.getters['common/canGeneratePDF'] && this.isReadOnly === false },
    canMergeDocuments() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canMergeDocuments'] },
    canPerformDigitalSignature() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canPerformDigitalSignature'] },
    canPrintDocument() { return (!this.document.readOnlyFolderInfo || this.document.readOnlyFolderInfo.CanPrint) && this.$store.getters['common/canPrintDocument'] },
    canRenameDocument() { return this.$store.getters['common/canRenameDocument'] },
    canUploadNewVersionDocument() { return this.isReadOnly === false && this.$store.getters['common/canUploadNewVersionDocument'] },
    canViewApprover() { return this.canViewField('Workflow_Approver'); },
    canViewAttachedDocuments() { return this.$store.getters['common/canViewLinkedDocuments'] },
    canViewDocumentHistory() { return this.$store.getters['common/canViewDocumentHistory'] },
    canViewDocumentNotes() { return this.$store.getters['common/canViewDocumentNotes'] },
    canViewDocumentPackages() {return this.$store.getters['common/canViewDocumentPackages']},
    canViewDocumentVersions() { return this.$store.getters['common/canViewDocumentVersions'] },
    canViewDueDate() { return this.canViewField('Workflow_Due_Date'); },
    canViewInitiator() { return this.canViewField('Workflow_Initiator'); },
    canViewStatus() { return this.canViewField('Workflow_Status'); },
    canViewCreatedBy() { return this.canViewField('CreatedBy') },
    createdDate() { return !this.document || !this.document.CreatedDateUTC ? '' : this.document.CreatedDateUTC },
    currentApp() { return this.isInWorkspaceApp ? 'Workspace' : 'Storage'},
    currentPageData() {
      if (this.currentPageNumber <= 0 || !this.pages || this.pages.length < this.currentPageNumber) {
        return {
          pageNumber: 0,
          imageSource: '',
        }
      }

      return this.pages[this.currentPageNumber - 1]
    },
    disableZoomIn() {
      return this.zoomValue > 3
    },
    disableZoomOut() {
      return this.zoomValue <= 0.1
    },
    documentAttributeFields() {
      return !this.document || !this.document.IndexFormFields || !this.document.IndexFormFields.Values
        ? []
        : this.document.IndexFormFields.Values
    },
    documentAttributeFormID() { return !this.document || !this.document.FormId ? 0 : this.document.FormId },
    documentFolderID() { return !this.document || !this.document.FolderId ? 0 : this.document.FolderId },
    documentID() { return !this.document || !this.document.DocumentId ? 0 : this.document.DocumentId },
    documentIDString() { return this.documentID.toString() },
    documentName() { return !this.document || !this.document.DisplayName ? '' : this.document.DisplayName },
    documentTabClass() { return this.signatureTabSelected ? 'tab' : 'tab-selected' },
    docViewType() {
      if (this.isInDataView) return 'Data'
      else if (this.isInPDFView) return 'PDF'
      return 'Document'
    },
    downloadToken() { return !this.document || !this.document.DownloadToken ? '' : this.document.DownloadToken },
    fileExtension() {
      if (!this.document || !this.document.DocumentName) {
        return ''
      }

      const lastIndex = this.document.DocumentName.lastIndexOf('.')
      if (lastIndex < 0) {
        return ''
      }

      return this.document.DocumentName.substring(lastIndex)
    },
    filePondLabel() {
      return `<span class="filepond-drag">Drag File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Upload New Document</span>`
    },
    fileType() { return this.fileExtension ? this.fileExtension.slice(1) : ''},
    hasAdditionalDocumentIds() { return this.$route.params.additionalDocumentIds ? true : false },
    hasCertificate() { return false }, // TODO: The logic still needs to be layed out for determining if the document has a certificate
    hasObjectID() { return this.currentAttributeFormID?.value > 0 },
    hasPackagers() { return this.$store.state.document?.packagers?.length > 0 ? true : false},
    historyModalTitle() {
      if (this.signatureTabSelected) {
        return `Signature History ${this.signatureDocumentGUID}`
      } else if (this.historyMode === 'history') {
          return 'Document History'
      }
      return ''
    },
    idWithPageNumber() { return `rotateMe${this.currentPageNumber}` },
    isDirty() { return this.isDirtyFromTheDocumentData || this.dirtyPriorityState || this.dirtyDueDateState || this.dirtyAttributeState || this.isDirtyFromDisplayName },
    isDueDateReadOnly() { return this.isFieldReadOnly('Workflow_Due_Date') },
    isFromSearch() { return Boolean(this.$route.query.fromSearch) },
    isInDataView() { return this.viewMode === this.viewMode_Data },
    isInDocumentView() { return this.viewMode === this.viewMode_Document },
    isInPDFView() { return this.viewMode === this.viewMode_PDF },
    isInSignatureApp() { return this.$store.state.document.backRoute.toLowerCase().includes('signature') },
    isInSignatureMode() { return this.document && this.document.pendingSignatureDocumentZones && this.document.pendingSignatureDocumentZones.length > 0 },
    isInWorkspaceApp() { return this.$store.state.document.backRoute.toLowerCase().includes('workspace') },
    isNativeDocument() { return !this.document || !this.document.FileDetail || this.document.FileDetail.Type !== 0 },
    isReadOnly() { return this.document.IsReadOnly },
    isSignatureDocLinkView() { return this.$store.state.common.isSignatureDocLinkView },
    isSignatureDocument() { return this.signatureDocumentGUID !== '' },
    isTiffOrPDF() {
      const localFileExtension = this.fileExtension.toLowerCase()
      return localFileExtension === '.tif' || localFileExtension === '.tiff' || localFileExtension === '.pdf'
    },
    maxImageWidth() { return window.innerWidth - this.subtractWindowWidth },
    modifiedDate() { return !this.document || !this.document.ModifiedDateUTC ? '' : this.document.ModifiedDateUTC },
    noteCount() { return !this.$store.state.document.notes || this.$store.state.document.notes.tableRows.length },
    noteBadgeWidth() { return this.calculateBadgeWidth(this.noteCount) },
    objectCreatedBy() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'CreatedBy') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    packagersBadge() {
      if (!this.hasPackagers) return 0
      const totalPackagersQty = this.$store.state.document?.packagers?.length

      if (totalPackagersQty === 1) {
        let requiredDocsQty = 0
        let docsQty = 0
        this.$store.state.document.packagers[0].FieldValuesViewer.forEach(field => {
          if (field.IsRequired) requiredDocsQty++
          if (field.IsRequired && field.DocNames.length > 0) docsQty++
        })

        return `${docsQty}/${requiredDocsQty}`
      }

      let submittedPackagersQty = 0

      this.$store.state.document?.packagers.forEach(packager => {
        if (packager.IsSubmitted) submittedPackagersQty++
      })

      return `${submittedPackagersQty}/${totalPackagersQty}`
    },
    pageReadyToLoad() { return (this.priorities?.length && this.documentID > 0 && (!this.document.Workflow || this.priority)) },
    pendingRequiredSignatureZonesCounter() {
      if (!this.$store.state.document.pendingSignatureAnnotationIDsForCurrentUser) return 0;
      return this.document.pendingSignatureDocumentZones.filter(s => this.$store.state.document.pendingSignatureAnnotationIDsForCurrentUser.includes(s.annotationID) && s.isRequired).length
    },
    pendingSignatureZonesCounter() {
      return this.$store.state.document.pendingSignatureAnnotationIDsForCurrentUser
        ? this.$store.state.document.pendingSignatureAnnotationIDsForCurrentUser.length
        : 0
    },
    priorities() { return this.$store.state.workflow.priorities },
    priority: {
      get: function () {
        return this.$store.state.document.priority ? this.$store.state.document.priority : {
        priorityID: 1,
        priorityName: 'High',
      }
      },
      set: function (newValue) {
        this.$store.dispatch('document/setDocumentPriority', newValue)
      },
    },
    priorityValue() { return this.$store.state.document.priority ? this.$store.state.document.priority.priorityID : 1 },
    rectangleForTextStamp() { return this.$store.state.document.rectangleForTextStamp },
    refreshThumbnailKey() { return this.$store.state.document.refreshThumbnailKey },
    saveButtonActive() { return ((this.dirtyAttributeState || this.dirtyDueDateState || this.dirtyPriorityState || this.isDirtyFromTheDocumentData || this.isDirtyFromDisplayName) && this.isValid && this.areRequiredFieldsFilled )},
    saveAndNextButtonActive() { return ((this.dirtyAttributeState || this.dirtyDueDateState || this.dirtyPriorityState) && this.isValid) },
    selectedAnnotation() { return this.$store.state.digitalSignature.selectedAnnotation },
    signatureTabClass() { return this.signatureTabSelected ? 'tab-selected' : 'tab' },
    showStartWorkflowButton() {
      return !this.isReadOnly
      && this.canAssignToWorkflow
      && this.isInDocumentView
      && !this.isInSignatureMode
      && !this.hasPendingSignatureZones
      && !this.showWorkflowDiv
      && !this.isInSignatureApp
    },
    showSaveButton() { return this.isReadOnly === false && this.canEditFieldData && !this.isSignatureDocLinkView},
    showWorkflowDiv() { return this.workflow && this.workflow.InWorkflow },
    signatureDocumentGUID() { return !this.document || !this.document.signatureDocumentGUID ? '' : this.document.signatureDocumentGUID },
    signatureDocumentID() {
      return !this.pendingSignatureZonesArray.length > 0 ? -1 : this.pendingSignatureZonesArray[0].signatureDocumentID
    },
    signatureZonesCompleted() {
      return this.isInSignatureMode
        && this.pendingRequiredSignatureZonesCounter === 0
        && this.emptyTextAnnotations === 0
    },
    signedSignatureZones() { return this.totalSignatureZones - this.pendingSignatureZonesCounter - this.emptyTextAnnotations },
    subtractWindowWidth() {
      let result = 730
      if (this.isNativeDocument) {
        result -= 200
      }

      return result
    },
    supportPDFViewer() { return this.document && this.document.LocalPDFName },
    taskCount() { return !this.document || !this.document.Tasks || !this.document.Tasks.Values ? 0 : this.document.Tasks.Values.length },
    textStampAnnotationList() { return this.$store.state.document.textStampAnnotationList },
    totalPages() { return !this.document || !this.document.FileDetail || !this.document.FileDetail.PageCount ? 1 : this.document.FileDetail.PageCount },
    userCommentsBadgeWidth() { return this.calculateBadgeWidth(this.userComments.length) },
    UserID() { return this.$store.state.common.userID },
    userInitials() { return this.$store.state.digitalSignature.userInitials ? `${this.base64DataPrefix}${this.$store.state.digitalSignature.userInitials}` : '' },
    userSignature() { return this.$store.state.digitalSignature.userSignature ? `${this.base64DataPrefix}${this.$store.state.digitalSignature.userSignature}` : '' },
    versionsBadgeWidth() { return this.calculateBadgeWidth(this.versionCount) },
    versionCount() {
      return !this.document || !this.$store.state.document.documentVersionData?.tableRows
        ? 0
        : this.$store.state.document.documentVersionData?.tableRows.length
    },
    VersionNumber() { return !this.document || !this.document.VersionNumber ? '' : this.document.VersionNumber },
    viewModeIcon() {
      switch (this.viewMode) {
        case this.viewMode_PDF:
          return this.viewMode_PDF_Icon;
        case this.viewMode_Data:
          return this.viewMode_Data_Icon;
        default:
          return this.viewMode_Document_Icon;
      }
    },
    workflowApprover() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'Workflow_Approver') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    workflowInitiator() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'Workflow_Initiator') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    workflowStatus() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'Workflow_Status') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    activeSideBarSelection: function(newVal, oldVal) {
      this.checkAndChangeCurrentActiveSideBar(newVal, oldVal)
    },
    currentAttributeFormID: async function () {
      this.isLoading = true
      this.showLookupButton = false
      if (!this.currentAttributeFormID || this.currentAttributeFormID.value <= 0) {
        this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', 0)
        this.showLookupButton = false
        this.dirtyAttributeState = false
        this.isLoading = false
        return
      }
      this.setFormName()

      this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.currentAttributeFormID.value)
      let result = await this.$store.dispatch('document/getDefaultLookupId', this.currentAttributeFormID.value)
      if (result && result.Value) {
        this.showLookupButton = Number(result.Value) > 0
      }

      await this.getTextStampAnnotationList()
      if (!this.firstRun) this.dirtyAttributeState = true
      this.firstRun = false

      if (this.document.FormId !== this.currentAttributeFormID.value) {
        this.isDirtyFromTheDocumentData = true
      }

      this.isLoading = false
    },
    displayDocumentSideBar: function () {
      this.$store.dispatch('admin/setUserSetting', {
        settingName: 'displayDocumentSideBar',
        settingValue: this.displayDocumentSideBar,
      })
    },
    indexFields: function () {
      this.initialAttributeFieldListSize = 0
      if (this.indexFields) {
        this.indexFields.map((field) => {
          const foundField = this.documentAttributeFields.find((f) => {
            return f.FieldName === field.value
          })

          field.filterValue = this.getValueFromDocField(foundField)
          if (field.filterValue !== '' && field.filterValue !== null) {
            this.initialAttributeFieldListSize += 1
          }
          field.readOnly = field?.filterValue === '' || field?.filterValue === null ? false : field.readOnly
          return true
        })
      }
      this.localFieldValues = []
    },
    pendingSignatureZonesCounter: async function (newCounter, oldCounter) {
      if (this.isInSignatureMode && this.signatureDocumentGUID && newCounter < oldCounter) {
        this.saveSignatureDocumentHistory('Signed')
      }
    },
    rectangleForTextStamp: async function(newVal) {
      if (newVal?.width && newVal?.height) {
        this.textStampSize.width = newVal?.width
        this.textStampSize.height = newVal?.height
        this.indexFieldsForTextStamp.length ? this.toggleData('showTextStampFieldsDialog') : this.requestTextStampImg()
      } else {
        await this.$store.dispatch('document/setDeleteCurrentAnnotation', true)
      }
    },
    selectedAnnotation: async function () {
      this.savedSignatureOrInitials = null
      if (!this.selectedAnnotation || !this.selectedAnnotation.id || !this.selectedAnnotation.src) {
        return
      }

      const canSignAnnotation = this.pendingSignatureZonesArray.some(e => e.annotationID === this.selectedAnnotation.id && String(e.signatureUserID) === String(this.UserID))
      if (!canSignAnnotation || !this.selectedAnnotation?.propertiesSubType) {
        return
      }

      this.currentSignatureImage = this.selectedAnnotation.recentlySigned ? this.selectedAnnotation.src : ''
      if (this.selectedAnnotation?.propertiesSubType === 'timestamp') {
        this.isLoading = true
        this.signingSignatureAnnotation = true
        const response = await this.$store.dispatch('digitalSignature/getDateAsImage', this.selectedAnnotation?.includeTime)
        if (!response) {
          return
        }

        const dateImage = `data:image/png;base64,${response}`
        await this.addNewSignature(dateImage)
        this.isLoading = false
        return
      } else if (this.selectedAnnotation?.propertiesSubType === 'text') {
        this.addNewAnnotation({ color: '', subType: '', type: 'text', applyOnZone: true })
        const copySelected = this.selectedAnnotation
        copySelected.isSigned = true
        this.signingSignatureAnnotation = false
        this.addInitialsDropListActive = false
        await this.$store.dispatch('digitalSignature/setSelectedAnnotation', copySelected)
        return
      } else if (!this.currentSignatureImage && this.adoptedInitials && this.selectedAnnotation?.propertiesSubType === 'initial') {
        this.applyAdoptedSignatureOrInitials(this.adoptedInitials)
        return
      } else if (!this.currentSignatureImage && this.adoptedSignature && this.selectedAnnotation?.propertiesSubType === 'signature') {
        this.applyAdoptedSignatureOrInitials(this.adoptedSignature)
        return
      }

      if (this.selectedAnnotation?.propertiesSubType === 'initial') {
        this.savedSignatureOrInitials = this.userInitials
        this.signatureAnnotationType = 'Initials'
      } else {
        this.savedSignatureOrInitials = this.userSignature
        this.signatureAnnotationType = 'Signature'
      }

      if (!this.enableSignatureAnnotations) {
        await this.checkForUserPassword()
      }
      if (this.enableSignatureAnnotations) {
        this.signingSignatureAnnotation = true
        this.showAddSignatureDialog = true
      }
    },
    signatureDocumentGUID: async function (newSignatureDocumentGUID, oldSignatureDocumentGUID) {
      if (!newSignatureDocumentGUID || newSignatureDocumentGUID === oldSignatureDocumentGUID || !this.isInSignatureMode) {
        return
      }

      // getGeoLocation is an async call which prompts the User to allow access to their geo location
      // the User can ignore that prompt, which is fine, but if we wait for a response, we'll never log that the document was viewed
      // so instead, we're initiating here with the hope they grant access for subsequent calls
      // but we just log the "Viewed" with their IP Address
      this.getGeoLocation()

      this.ipAddress = await publicIp.v4()
      this.saveSignatureDocumentHistory('Viewed')
    },
  },
  async created() {
    if (this.isSignatureDocLinkView) {
      await this.$amplitude.checkAmplitudeAvailability(true)
      this.isAmplitudeAvailable = await this.$amplitude.isAvailable()
    }
    // Make calculations for toolbar design
    let viewportWidth = window.innerWidth
    this.showEllipsisIcon = viewportWidth <= this.viewportWidth1280
    this.showToolbar720 = viewportWidth <= this.viewportWidth720
    window.addEventListener('resize', () => {
      viewportWidth = window.innerWidth;
      this.showEllipsisIcon = viewportWidth <= this.viewportWidth1280
      this.showToolbar720 = viewportWidth <= this.viewportWidth720
      if (!this.showEllipsisIcon) {
        this.showEllipsisToolbar = false
      }
    })
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
    this.localBackRoute = !this.$store.state.document.backRoute ? '/' : this.$store.state.document.backRoute
    this.additionalDocumentIds = this.hasAdditionalDocumentIds ? this.$route.params.additionalDocumentIds.split(',') : []
      await Promise.all([
        this.reloadDocument(this.$route.params.documentID),
        this.$store.dispatch('document/resetZoomValue'),
        this.$store.dispatch('digitalSignature/getUserSignatureForStore'),
        this.$store.dispatch('digitalSignature/getUserInitialsForStore'),
        this.$store.dispatch('document/getAnnotationImageList'),
        this.$store.dispatch('workflow/getWorkflowPriorities'),
    ])

    if (this.userSignature.length > 0) {
      this.savedSignatureOrInitials = this.userSignature
    } else if (this.userInitials.length > 0) {
      this.savedSignatureOrInitials = this.userInitials
    }
    this.determineDownloadLimitsReached()
    const allUserSettings = (await this.$store.dispatch('admin/getAllUserSettings')).Values;
    if (allUserSettings.displayDocumentSideBar === 'false') {
      this.displayDocumentSideBar = false;
    }
    if(!this.isInWorkspaceApp && !this.isInSignatureApp && !this.isInSignatureMode && !this.isSignatureDocLinkView && Object.values(DocumentViewOptions).includes(allUserSettings.defaultDocumentView)) {
      this.defaultDocumentView = allUserSettings.defaultDocumentView;
      const canUseDefaultView = allUserSettings.defaultDocumentView !== DocumentViewOptions.Pdf || this.fileExtension.toLowerCase() === '.pdf';
      this.viewMode = canUseDefaultView ? allUserSettings.defaultDocumentView : DocumentViewOptions.Document;
    }
    this.checkAndChangeCurrentActiveSideBar(this.activeSideBarSelection, '')
    if (this.$route.query.ShowAttachments) {
      this.showAttachedDocumentsDialog = true
    }

    this.generatePDFEnabled = this.$store.state.systemSettings.featureFlags.enableGeneratePdf

    await this.getTextStampAnnotationList()

    if (!this.$route.query.fromPackage && this.$route.query.packageId) this.showPackagersModal = true
  },
  methods: {
    addNewAnnotation(config) { this.$store.dispatch('document/setAnnotationConfig', config) },
    async addNewSignature(signature) {
      this.showAddSignatureDialog = false
      this.addInitialsDropListActive = false

      if (this.signingSignatureAnnotation) {
        this.annotation.signature.subType = 'signature'
        this.addSignature(signature)

        // overwrite default annotation that was set in addSignature
        this.addNewAnnotation({ color: '', subType: '', type: 'signature', applyOnZone: true })

        const copySelected = this.selectedAnnotation
        copySelected.isSigned = true
        this.signingSignatureAnnotation = false
        this.addInitialsDropListActive = false
        await this.$store.dispatch('digitalSignature/setSelectedAnnotation', copySelected)
      } else {
        this.annotation.signature.subType = 'signature'
        this.addSignature(signature)
        this.addInitialsDropListActive = false
      }
    },
    addSavedSignature() {
      if (this.isSignatureDocLinkView || this.isInSignatureMode) {
        this.toggleShowAddSignatureDialog()
      }
      this.annotation.signature.subType = 'signature'
      this.addSignature(this.userSignature)
      this.addInitialsDropListActive = false
    },
    addSavedSignatureInitials() {
      if (this.isSignatureDocLinkView || this.isInSignatureMode) {
        this.toggleShowAddSignatureDialog()
      }
      this.annotation.signature.subType = 'initial'
      this.addSignature(this.userInitials)
      this.addInitialsDropListActive = false
    },
    async addNewStamp(stamp) {
      this.annotation.stamp.subType = 'stamp'
      this.addStampsDropListActive = false
      this.addTextStampsDropListActive = false

      if (stamp.applyTimestamp) {
        let newStamp = await this.$store.dispatch('digitalSignature/burnTimestampToImageBase64', `${this.base64DataPrefix}${stamp.base64String}`)
        await this.$store.dispatch('document/activeStamp', newStamp)
        return
      }
      this.$store.dispatch('document/activeStamp', `${this.base64DataPrefix}${stamp.base64String}`)
    },
    addNewWhiteRedaction() { this.$refs.mainImage.placeWhiteRedaction() },
    addSignature(img) {
      this.$store.dispatch('document/activeSignature', img)
      this.addNewAnnotation(this.annotation.signature)
    },
    async applyAdoptedSignatureOrInitials(signatureOrInitials) {
      let signature = signatureOrInitials
      this.isLoading = true
      if (this.applyTimestamp) {
        signature = await this.$store.dispatch('digitalSignature/burnTimestampToImageBase64', signature)
      }
      this.signingSignatureAnnotation = true
      this.currentSignatureImage = signature
      await this.addNewSignature(signature)
      this.isLoading = false
    },
    async applyNewSignature(signature, adoptedSignature, signatureWithoutTimestamp, applyTimestamp) {
      if (adoptedSignature) {
        if (this.selectedAnnotation?.propertiesSubType === 'initial') {
          this.adoptedInitials = adoptedSignature ? signatureWithoutTimestamp : ''
        } else if (this.selectedAnnotation?.propertiesSubType === 'signature') {
          this.adoptedSignature = adoptedSignature ? signatureWithoutTimestamp : ''
        }
        this.applyTimestamp = applyTimestamp
      }
      this.currentSignatureImage = signature
      await this.addNewSignature(signature)
    },
    async attributeFieldLookUp() {
      const FieldValues = {
        Values: this.getLocalIndexFieldValuePairs(),
      }
      FieldValues.Values.push({
        Key: "DocumentID",
        Value: this.documentID
      })
      this.initialAttributeFieldListSize = 0

      const results = await this.$store.dispatch('attributeForm/attributeFieldLookUp', {
        IndexFormId: this.currentAttributeFormID ? this.currentAttributeFormID.value : this.document.FormId,
        FieldValues,
      })

      switch (results.Rows?.length) {
        case 0:
          this.showSnackbar = true
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'No Results Found.'
          this.snackbarImage = false
          return
        case 1:
          this.$refs.attributeFieldsComponent.updateFieldsFromLookup(results)
          this.dirtyAttributeState = true
          break
        default:
          this.multipleLookupResults = results
          this.showMultipleLookupDialog = true
          break
      }
    },
    async backToPreviousView() {
      if (this.$route.query.packageId) {
        const query = { fromPackage: false, packageId: this.$route.query.packageId }
        this.$router.push({ name: 'Document', params: { documentID: this.$route.query.documentId }, query })
        return
      }
      if (this.$route.query.folderId) {
        const storageQuery = {
          folderId: parseInt(this.$route.query.folderId),
          ...(parseInt(this.$route.query.pageSize) && { pageSize: parseInt(this.$route.query.pageSize) }),
          ...(parseInt(this.$route.query.page) && { page: parseInt(this.$route.query.page) }),
        }
        this.$router.push({ path: '/storage/vaults', query: storageQuery })
        return
      }
      if (this.$route.query.workspaceType) {
        const workspaceQuery = {
          workspaceType: parseInt(this.$route.query.workspaceType) ? parseInt(this.$route.query.workspaceType) : 0,
          ...(parseInt(this.$route.query.pageSize) && { pageSize: parseInt(this.$route.query.pageSize) }),
          ...(parseInt(this.$route.query.page) && { page: parseInt(this.$route.query.page) }),
        }
        this.$router.push({ path: '/workspace/inbox', query: workspaceQuery })
        return
      }
      if (this.$route.query.recentDocuments) {
        const promise1 = this.$store.dispatch('workflow/getWorkflowRecentDocuments')
        const promise2 = this.$store.dispatch('workflow/setIsDataRecentDocuments', true)
        await Promise.all([promise1, promise2])
        const recentDocumentsQuery = {
          recentDocuments: Boolean(this.$route.query.recentDocuments),
          ...(parseInt(this.$route.query.pageSize) && { pageSize: parseInt(this.$route.query.pageSize) }),
          ...(parseInt(this.$route.query.page) && { page: parseInt(this.$route.query.page) }),
        }
        this.$router.push({ name: 'TheWorkspaceHome', query: recentDocumentsQuery })
        return
      }
      await this.$store.dispatch('document/setBackRoute', '/')
      this.$router.push({ path: this.localBackRoute })
    },
    calculateBadgeWidth(value) {
      return value > 10 ? '26' : '20'
    },
    async cancelTextStamp() {
      this.toggleData('showTextStampFieldsDialog')
      await this.$store.dispatch('document/setDeleteCurrentAnnotation', true)
      this.indexFieldsForTextStamp = []
      this.markClean()
    },
    canViewField(fieldName) {
      const field = this.documentAttributeFields?.find(f => f.FieldName === fieldName) ?? null;
      const isHidden = field?.Hidden ?? true;
      const hasValue = !isEmpty(field?.TextValue) || !isEmpty(field?.DateValueUTC);

      return !isHidden || (hasValue ? this.canViewHiddenFieldsWithValue() : this.canViewHiddenFieldsWithoutValue());
    },
    canViewHiddenFieldsWithValue() { return this.$store.getters['common/canViewHiddenFieldsWithValue']; },
    canViewHiddenFieldsWithoutValue() { return this.$store.getters['common/canViewHiddenFieldsWithoutValue']; },
    changeModalClass(val) {
      this.dropDownOpen = val
    },
    async checkForUserPassword(event) {
      this.signatureIconClickEvent = event
      const response = await this.$store.dispatch('digitalSignature/getUserInfo')
      this.userPassword = response.SignaturePassword

      if (response?.SignaturePassword !== '' && !this.enableSignatureAnnotations) {
        this.showPromptPassword = true
      } else {
        this.enableSignatureAnnotations = true
      }
    },
    checkAndChangeCurrentActiveSideBar(newVal, oldVal) {
      if (oldVal === '') {
        this.currentActiveSideBar = newVal
      }
    },
    checkRightSidePanelMobile() {
      if (this.pendingSignatureZonesCounter === 0 && this.emptyTextAnnotations === 0) {
        this.rightSidePanelMobile = false
      }
    },
    async clickSignatureToolbarButton($event) {
      if (!this.addInitialsDropListActive) {
        await this.checkForUserPassword($event);
      }

      if (this.enableSignatureAnnotations) {
        this.toggleShowAddSignatureList($event)
      }
    },
    async comparePasswords(event) {
      if (this.userPassword === this.enteredPassword) {
        this.showPromptPassword = false
        this.enableSignatureAnnotations = true
        if (!this.isInSignatureMode) {
          this.toggleShowAddSignatureList(event)
        } else {
          const copySelected = this.selectedAnnotation
          await this.$store.dispatch('digitalSignature/setSelectedAnnotation', {})
          await this.$store.dispatch('digitalSignature/setSelectedAnnotation', copySelected)
          this.$store.dispatch('digitalSignature/setDrawSignature', true)
        }
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'The password was incorrect'
        this.snackbarImage = false
      }
    },
    async completeSignatureDocument() {
      this.showCompleteSignatureModal = false
      if (!this.signatureZonesCompleted) return
      this.isLoading = true

      const payload = {
        documentId: this.documentID,
      }

      await Promise.all([
        this.$store.dispatch('document/approveSignatureDocument', payload),
        this.saveSignatureDocumentHistory('Completed'),
        this.saveSignatureDocumentComment(),
      ])
      this.isLoading = false

      await this.$amplitude.trackEvent('Signature Doc Reviewed', {
        'signatureAction': 'Completed',
      })

      if (this.isSignatureDocLinkView) {
        this.$router.push({
          name: 'TheSignatureConfirmation',
          params: {
            confirmationMessage: 'Document Completed',
            confirmationSubMessage: 'Thank you for using Vasion',
          },
        })
      } else {
        this.backToPreviousView()
      }
    },
    async confirmBurnAnnotations() {
      this.isLoading = true

      const response = await this.$store.dispatch('document/burnAnnotations', { Value: this.documentIDString })
      this.toggleData('showBurnAnnotations')
      this.isLoading = false
      if (response.ResultStatus === 1 && response.Versions) {
        this.showSnackbar = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Annotations burned successfully'
        this.snackbarImage = true
        this.reloadDocument(this.documentID)
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error burning the annotations.'
        this.snackbarImage = false
      }
    },
    clearPageSelectionAndInsertOption() {
      this.$store.dispatch('document/uploadedPagesInsertOption', '')
      this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      this.$store.dispatch('document/clearUploadedPagesToAdd')
      this.showAddPagesModal = false;
      this.useStoreAddPage = false;
    },
    closeDocumentEmail() { this.showEmailModal = false },
    closeViewModeMenu() { this.showViewModeMenu = false },
    async createPDFVersionClick() {
      this.isLoading = true
      await this.$refs.documentData.createVersionAttributeFormPDF()
      this.isLoading = false
    },
    async createUserAndGroupData(userList, groupList) {
      const newUserList = []
      const newGroupList = []

      if (this.userGroupAssignConfig.type === 'prmt_ChooseFromGroup'
          && this.userAssignPayload.Values[0]?.Value.Values[0]?.Key === 'cfg_GroupID'
          && this.userAssignPayload.Values[0]?.Value.Values[0]?.Value !== '') {
            this.userGroupAssignConfig.groupId = Number(this.userAssignPayload.Values[0]?.Value.Values[0]?.Value)
        userList = await this.$store.dispatch('security/getGroupChildren', this.userGroupAssignConfig.groupId)
      }

      Object.keys(userList).map((key) => {
        let user
        if (userList && userList.length > 0 && userList[key]) {
          user = userList[key]

          newUserList.push({
            name: `${user.FirstName || ''} ${user.LastName || ''}`,
            value: Number(user.UserId),
            username: user.Username,
          });
        }
        return true;
      });

      Object.keys(groupList).map((key) => {
        let group
        if (groupList.length > 0 && groupList[key]) {
          group = groupList[key]

          newGroupList.push({
            name: group.Name,
            value: Number(group.GroupId),
          });
        }
        return true;
      });

      const dataList = {
        userList: newUserList,
        groupList: newGroupList,
      }

      return dataList
    },
    async createVersionClick() {
      const result = await this.$store.dispatch('document/createVersion', { DocumentId: this.document.DocumentId })
      if (result.ResultStatus === 1) {
        this.showSnackbar = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Version created successfully!'
        this.snackbarImage = true
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error creating the version.'
        this.snackbarImage = false
      }
    },
    async commentButtonClicked() {
      const payload = {
        documentID: this.documentID,
        stepID: this.$route.params.workflowStepId,
        commentText: this.newComment,
      }
      const response = await this.$store.dispatch('workflow/addWorkflowComment', payload)
      
      const status200 = response?.status === 200 ? true : false
      const successfulMessage = response?.data?.ResultStatus === 1 ? true : false
      let validComments = response?.data?.Comments ? true : false
      validComments = validComments && Array.isArray(response?.data?.Comments)
      const successfulRequest = status200 && successfulMessage && validComments

      if (successfulRequest) {
        this.showSnackbar = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Comment was saved successfully!'
        this.snackbarImage = true
        this.workflowComments = response.data.Comments
        this.userComments = response.data.UserComments
        await this.$amplitude.trackEvent('Workflow Doc Commented', {})
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error saving the comment'
        this.snackbarImage = false
      }
      this.newComment = ''
    },
    async continueWorkflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID

      if (this.selectedWorkflowID <= 0) {
        this.showWorkflowList = false
        return
      }
      this.startWorkflowForSelectedDocuments()
    },
    async deletePages() {
      const payload = {
        documentID: this.documentID,
        pageNumbers: this.selectedPagesForDelete,
      }
      const response = await this.$store.dispatch('document/deleteDocumentPages', payload)
      if (response) {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = response
        this.snackbarImage = false
      } else {
        this.showDeletePagesDialog = false
        this.reloadDocument(this.$route.params.documentID)
      }
      this.showDeletePagesConfirmDialog = false
    },
    async determineDownloadLimitsReached() {
      this.downloadLimitsReached = false

      if (await testIsGuid(this.documentGuid)) {
        const countData = await this.getDocumentDownloadCount(this.documentGuid)
        if (countData && countData.data && (countData.data.maxDownloads > 0 && (countData.data.currentDownloads >= countData.data.maxDownloads))) {
          this.downloadLimitsReached = true
        }
      }
    },
    dirtyModalCancelClick () {
      this.showDirtyModal = false
      this.$store.dispatch('mainViews/changeMainNav', this.currentActiveSideBar)
    },
    dirtyModalOKClick () {
      this.routeNext()
      document.title = 'Vasion'
    },
    async downloadDocument(downloadOptions) {
      const downloadPayload = {
        T: this.downloadToken,
        BurnAnnotations: downloadOptions ? downloadOptions.burnAnnotations : false,
        UserID: this.UserID,
        IncludeWatermark: false,
        IncludeHistory: downloadOptions ? downloadOptions.includeSigDocHistory : false,
        ExtractPages: downloadOptions ? downloadOptions.extractPages : '',
        ConvertToPDF: downloadOptions ? downloadOptions.convertToPDF : false,
        StreamPDF: '',
      }

      this.showDownloadDocumentDialog = false
      this.isLoading = true
      const downloadResult = await this.$store.dispatch('document/downloadVasionDocument', downloadPayload)

      if (downloadResult && !this.downloadLimitsReached) {
        const newFileName = await removeIllegalChars(this.documentName)

        // eslint-disable-next-line
        const fileDownload = require('js-file-download')
        const localFileExtension = downloadPayload.ConvertToPDF === true ? '.pdf' : this.fileExtension

        testDownloadHasFileExtension(downloadResult, newFileName, localFileExtension)

        if (await testIsGuid(this.documentGuid)) {
          await this.incrementDocumentDownloadCount(this.documentGuid)
          this.determineDownloadLimitsReached()
        }
        await this.$amplitude.trackEvent('Document Shared', {'shareType': 'Download', 'app': this.currentApp, })

      } else {
        this.snackbarText = 'Unable to download the Document'
      }

      this.isLoading = false
    },
    documentPasswordFailed() {
      this.showSnackbar = true
      this.snackbarTitle = 'Error!'
      this.snackbarSubTitle = 'Password was incorrect'
      this.snackbarImage = false
    },
    async downloadDocumentClick() {
      if (this.canDownloadDocument === false || this.downloadToken === '') {
        return
      }

      if (this.coCStatus) {
        this.downloadDocument()
        return
      }

      if (this.isInDataView) {
        this.$refs.documentData.downloadAttributeFormDocument(this.documentName)
        return
      }

      if (this.isTiffOrPDF === false) {
        this.downloadDocument()
      } else {
        this.showDownloadDocumentDialog = !this.showDownloadDocumentDialog
      }
    },
    async editDisplayName() {
      if (!this.displayNameInputValue) {
        this.snackbarTitle = 'Field error'
        this.snackbarSubTitle = 'Please enter a valid Document Name.'
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }
      if(this.showSnackbar) this.$refs.vasionSnackbar.closeSnackbar()

      const payload = {
        DocumentId: this.documentID,
        NewDisplayName: this.displayNameInputValue
      }
      const response = await this.$store.dispatch('document/renameDisplayName', payload)

      if (!response || !response?.ResultStatus === 1) {
        this.snackbarTitle = 'Field error'
        this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : `An error occurred during save`
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      this.document.DisplayName = this.displayNameInputValue
      document.title = this.document.DisplayName
      this.displayNameInEditMode = false
      this.isDirtyFromDisplayName = false
    },
    
    emailButtonClick() { this.showEmailModal = true },
    async exportSigDocumentToPDF() {
     await this.$store.dispatch('document/exportSignatureDocumentHistory', this.documentID)
     this.showHistoryDialog = !this.showHistoryDialog
    },
    async getDocumentDownloadCount(documentId) {
      const documentDownloadData = await this.$store.dispatch('document/getDocumentDownloadCount', documentId)
      return documentDownloadData
    },
    async getDocumentDetails(documentId) {
      if (await testIsGuid(documentId)) {
        const canPerformWFAction = await this.$store.dispatch('workflow/canPerformWFAction', documentId)
        if (canPerformWFAction === 'True') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'workspace')
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
        } else if (canPerformWFAction === 'False') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'storage')
        }
        this.documentGuid = documentId
        return this.$store.dispatch('document/getDocumentDetailsByGuid', this.documentGuid)
      } else if (this.$route.params.version) {
        let returnValue = await this.$store.dispatch('document/getDocumentDetailsWithVersion', { documentID: documentId, version: this.$route.params.version }) //
        this.documentRequiresPassword = returnValue.FileDetail.Security.Required
        return returnValue
      } else {
        let payload = { documentID: documentId, workflowStepID: this.$route.params.workflowStepId,
          flags: {
            IncludeMeanConfidence: true
          }
        }
        if (this.documentRequiresPassword && this.docPassword) {
          payload.password = this.docPassword
        }
        let returnValue = await this.$store.dispatch('document/getDocumentDetails', payload)
        if (returnValue && returnValue.FileDetail && returnValue.FileDetail.Security) {
          this.documentRequiresPassword = returnValue.FileDetail.Security.Required
        }
        return returnValue
      }
    },
    async getExternalIpAndGeoLocation() {
      const ip = await publicIp.v4()
      this.ipAddress = ip
      await this.getGeoLocation()
    },
    getGeoLocation() {
      if (!navigator.geolocation) {
        return
      }

      navigator.geolocation.getCurrentPosition(pos => {
        const lat = pos.coords.latitude
        const lng = pos.coords.longitude
        const acc = pos.coords.accuracy
        this.geolocation = `${lat}, ${lng}, ${acc}`
      }, err => {
        console.error(err)
      }, { timeout: 1000 })
    },
    getLocalIndexFieldValuePairs() {
      if (!this.localFieldValues) {
        return []
      }

      return this.localFieldValues.map(e => {
        if (Object.prototype.toString.call(e.value) === '[object Array]') {
          const dropdownValues = []
          e.value.forEach(v => {
            if (v.name) {
              dropdownValues.push(v.name)
            }
            else {
              dropdownValues.push(v)
            }
          })
          const dropDownSingleString = dropdownValues.join(';')
          return {
            Key: e.name,
            Value: dropDownSingleString,
          }
        } else {
          return {
            Key: e.name,
            Value: e.value,
          }
        }
      })
    },
    getStringFromIndexValue(indexValue) {
      let text = ''

      if (!indexValue) {
        return text
      }

      if (Array.isArray(indexValue)) {
        indexValue.map((value) => {
          if (value.name) {
            // .value is an array of objects, each with it's own name and value properties.
            // This is in support of multi-select VasionDroplists
            text += `${value.name};`
          } else {
            // .value is a string array ...
            text += `${value};`
          }
          return true
        })

        if (text !== '') {
          // remove the trailing semi-colon
          text = text.substring(0, text.length - 1)
        }
      } else {
        text = indexValue
      }

      return text
    },
    async getTextStampAnnotationList() {
      const payloadForTextStamp = {
        documentId: this.documentID,
        indexFormId: this.currentAttributeFormID ? this.currentAttributeFormID.value : this.document.FormId,
        includeImages: false,
        includeFieldValues: false,
      }
      await this.$store.dispatch('document/getTextStampAnnotationList', payloadForTextStamp)
    },
    getValueFromDocField(docField) {
      let ret = ''

      try {
        if (docField) {
          if (docField.Control === 2 || docField.Control === 9 || docField.Control === 10) {
            ret = docField.DropDownSelectedValues.Values
          } else if (docField.Control === 3) {
            ret = docField.DateValueUTC
          } else {
            ret = docField.TextValue
          }
        }
      } catch {
        ret = ''
      }

      return ret
    },
    goToFieldValidation() {
      this.$router.push({ name: 'TheFieldValidationView', params: { folderID: this.documentFolderID, documentID: this.documentID, documentIDs: [this.documentID], fromDocViewer: true } })
    },
    goToNextZone() {
      if (this.currentZonePage < this.pendingSignaturesPageNumbers.length) {
        this.currentZonePage = this.pendingSignaturesPageNumbers.findIndex(i => {
          return i > this.currentPageNumber
        })
        if (this.currentZonePage === -1) {
          this.currentZonePage = 0
        }
        this.goToPage(this.pendingSignaturesPageNumbers[this.currentZonePage])
      }
    },
    goToPage(pageNumber) {
      setTimeout(() => {
        if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
          this.currentPageNumber = pageNumber
          this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
        }
      }, 200);
    },
    goToPageEmit(payload) {
      this.goToPage(payload.pageNumber)
    },
    goToPreviousZone() {
      let zonePageNumbers = []
      if (this.currentZonePage > 0) {
        this.pendingSignaturesPageNumbers.forEach(i => {
          if (i < this.currentPageNumber) {
            zonePageNumbers.push(i)
          }
        })
        this.currentZonePage = zonePageNumbers.length - 1
      } else {
        this.currentZonePage = this.pendingSignaturesPageNumbers.length - 1
      }
        this.goToPage(this.pendingSignaturesPageNumbers[this.currentZonePage])
    },
    handleCancelMultipleLookups() {
      this.showMultipleLookupDialog = !this.showMultipleLookupDialog
      this.multipleLookupResults = []
    },
    handleForwardReassign(selected) {
      const { value, type } = selected
      const cugType = type === 'users' ? 'cug_User' : 'cug_Group'

      // prmt_ChooseGroupUser = Need to Reassign or Forward
      // prmt_ChooseFromGroup = Need to assign to a user within a specific group
      if (this.userGroupAssignConfig.type === 'prmt_ChooseFromGroup') {
        this.promptData = {
          Values: [
            {
              Key: this.userGroupAssignConfig.type,
              Value: {
                Values: [
                  {
                    Key: 'cfg_UserID',
                    Value: value,
                  },
                ],
              },
            },
          ],
        }
      } else {
        this.promptData = {
          Values: [
            {
              Key: this.userGroupAssignConfig.type,
              Value: {
                Values: [
                  {
                    Key: 'cug_SelectedType',
                    Value: cugType,
                  },
                  {
                    Key: 'cug_SelectedID',
                    Value: value,
                  },
                ],
              },
            },
          ],
        }
      }
      this.workflowAction(this.action)
    },
    handleIndexPrompt() {
      if (this.invalidFields.length && this.invalidFields.includes(12)) {
        this.snackbarTitle = 'Field(s) error'
        this.snackbarSubTitle = 'Please enter a valid email address.'
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      const indexFieldValues = this.getLocalIndexFieldValuePairs()
      this.promptData = {
        Values: [
          {
            Key: this.indexPromptName,
            Value: {
              Values: indexFieldValues,
            },
          },
        ],
      }
      this.showIndexPrompt = !this.showIndexPrompt
      this.workflowAction(this.action)
    },
    handlePrintClick() {
      if (this.isInDocumentView || this.isInPDFView) {
        this.showPrintModal = !this.showPrintModal
      } else {
        this.printAttributeForm()
      }
    },
    async handleUserSelected(user) {
      if (!user || !user.value) {
        this.showUserSelectModal = false
        return
      }
      this.startWorkflowPayload.documentID = this.documentID.toString()
      this.startWorkflowPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = user.value

      this.continueWorkflowSelected()
    },
    async incrementDocumentDownloadCount(documentId) {
      const payload = {
        Value: documentId,
      }
      return this.$store.dispatch('document/incrementDownloadCounter', payload)
    },
    async initializeNewTextStamp(stamp) {
      this.annotation.stamp.subType = 'stamp'
      this.addStampsDropListActive = false
      this.addTextStampsDropListActive = false

      this.indexFieldsForTextStamp = await cloneDeep(this.indexFields).filter( (field) => {
        return stamp.StampText.includes(field.value)
      })

      if (this.isDirty) {
        await this.indexFieldsForTextStamp.map((element) => {
          const sameField = this.localFieldValues.find( (field) => field.name === element.value)
          if (sameField && sameField?.value !== element?.filterValue) element.filterValue = sameField.value
          return element
        })
      }

      this.currentTextStampAnnID = stamp.TextStampAnnID
      this.addNewAnnotation(this.annotation.redaction.yellow)
    },
    isFieldReadOnly(fieldName) {
      const field = this.documentAttributeFields?.find(f => f.FieldName === fieldName) ?? null
      const isReadOnly = field?.ReadOnly ?? false
      return isReadOnly
    },
    loadThumbnails() {
      const length = this.totalPages
      this.thumbnails = [length]

      for (let i = 0; i < length; i += 1) {
        const pageNumber = (i + 1)
        this.thumbnails[i] = {
          pageNumber: pageNumber,
        }
      }
    },
    markFieldAsNotValid(field) {
      if (this.invalidFields.includes(field.type)) return
      this.invalidFields.push(field.type)
    },
    markFieldAsValid(field) {
      const indexToBeDeleted = this.invalidFields.indexOf(field.type)
      if (indexToBeDeleted !== -1)
        this.invalidFields.splice(indexToBeDeleted, 1)
    },
    async passwordEntered(password) {
      this.docPassword = password
      await this.reloadDocument(this.documentID)
    },
    mainImageLoaded(imageData) {
      if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
        || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
          // need to flip the bounds
          const temp = this.viewerHeight
          this.viewerHeight = this.viewerWidth
          this.viewerWidth = temp
      }

      if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
        this.viewerHeight = imageData.height
        this.viewerWidth = imageData.width
      }

      while (this.viewerWidth > this.maxImageWidth && !this.disableZoomOut) {
        this.zoomOut()
      }
    },
    markClean() {
      this.dirtyAttributeState = false
      this.isDirtyFromTheDocumentData = false
    },
    markDirty(from) {
      if (from === 'fromTheDocumentData') {
        this.isDirtyFromTheDocumentData = true
      } else {
        this.dirtyAttributeState = true
      }
    },
    markIsValid() { this.isValid = true },
    markNotValid() { this.isValid = false },
    packageSubmitted() {
        this.reloadDocument(this.documentID)
    },
    permanentlyRotateLeft() {
      this.rotateLeft()
      this.saveRotate()
    },
    permanentlyRotateRight() {
      this.rotateRight()
      this.saveRotate()
    },
    printAttributeForm() { this.$refs.documentData.printAttributeForm() },
    async printSelectedDocuments(settings) {
      this.showPrintModal = !this.showPrintModal
      const payload = {
        T: [this.documentID],
        BurnAnnotations: settings.burnAnnotations,
        ConvertToPDF: true,
        IncludeHistory: settings.includeSigDocHistory,
        UserID: this.UserID,
      }
      const documentsForPrint = await this.$store.dispatch('workflow/downloadVasionDocumentsForPrint', payload)

      // eslint-disable-next-line
      const printJS = require('print-js')

      if (!documentsForPrint.Value) {
        this.showSnackbar = true
        this.snackbarTitle = 'Error!'
        this.snackbarSubTitle = 'The file(s) could not be printed.'
        this.snackbarImage = false
      } else if (documentsForPrint) {
        printJS({
          printable: documentsForPrint.Value, type: 'pdf', showModal: true, 
          onPrintDialogClose: () => { 
            this.$store.dispatch('workflow/deletePrintedFile', documentsForPrint) 
            this.$amplitude.trackEvent('Document Shared', {'shareType': 'Print', 'app': this.currentApp, })
          },
          onError: () => { 
            this.showSnackbar = true
            this.snackbarTitle = 'Error!'
            this.snackbarSubTitle = 'Error printing file, check "chrome://settings/content/pdfDocuments" and set to "Open PDFs in Chrome"'
            this.snackbarImage = false
          },
        });
      }
    },
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        this.uploadedFileName = file.name
        let fileDataString = await toBase64(file)
        const indexOf = fileDataString.indexOf(';base64,')
        if (indexOf >= 0) {
          fileDataString = fileDataString.substring(indexOf + 8)
        }

        const payload = {
          DocumentID: this.documentID,
          FileName: file.name,
          Base64FileString: fileDataString,
        }
        const response = await this.$store.dispatch('storage/uploadNewVersion', payload)
        if (!response) {
          this.showSnackbar = true
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'Unable to upload new version.'
          this.snackbarImage = false
        } else {
          progress(true, 0, 1024);
          load(file.name)
          this.showUploadNewVersionModal = !this.showUploadNewVersionModal
          this.reloadDocument(this.$route.params.documentID)
          await this.$amplitude.trackEvent('Document Added', {
            'source': 'Upload',
            'exportLocation': 'Storage',
          })
        }
      return {
        abort: () => {
          if (abort !== undefined) {
            abort();
          }
        },
      }
      } catch (err) {
        console.warn(err)
      }
    },
    // eslint-disable-next-line
    processPagesHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        this.errorText = ''
        const TiffIndex = file.name.toLowerCase().indexOf('.tiff')
        const TifIndex = file.name.toLowerCase().indexOf('.tif')
        const pdfIndex = file.name.toLowerCase().indexOf('.pdf')
        if (TiffIndex < 0 && TifIndex < 0 && pdfIndex < 0) {
          const errorText = 'Only Tiff, Tif, and pdf file types are supported. Please try uploading one of those file types.'
          this.showSnackbar = true
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'Unable to upload this file type'
          this.snackbarImage = false
          file = {}
          console.warn(errorText)
          this.errorText = errorText
          this.toggleUploadAndPage()
        } else {
          const uploadedImage = await toBase64(file)
          const stringIndex = uploadedImage.indexOf('base64,')
          const payload = {
            fileName: file.name,
            base64String: uploadedImage.substring(stringIndex + 7),
            uploadIndex: this.uploadIndex,
          }
          this.$store.dispatch('document/addPageToList', payload)
          this.uploadNewPageToggle()
          progress(true, 0, 1024);
          load(file.name)
        }
        this.showUploadDialog = false
        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        }
      } catch (e) {
        console.warn(e)
      }
    },
    async recallSignatureDocument() {
      this.showRecallPrompt = false
      this.isLoading = true

      const payload = {
        documentID: this.documentID,
        userIPAddress: this.ipAddress ? this.ipAddress : '',
        userGeoLocation: this.geolocation ? this.geolocation : '',
      }

      const response = await this.$store.dispatch('document/recallSignatureDocument', payload)
      this.isLoading = false

      if (response.Value === 'True') {
        this.showRecallSnackbar(true)

        await this.$amplitude.trackEvent('Signature Doc Reviewed', {
          'signatureAction': 'Recalled',
        })

        setTimeout(() => {
          if (this.isSignatureDocLinkView) {
            this.$router.push({
              name: 'TheSignatureConfirmation',
              params: {
                confirmationMessage: 'Document Recalled',
                confirmationSubMessage: 'Thank you for using Vasion',
              },
            })
          } else {
            this.backToPreviousView()
          }
        }, 1500)
      } else { this.showRecallSnackbar(false) }
    },
    async rejectSignatureDocument() {
      this.showRejectSignatureModal = false
      this.isLoading = true

      const payload = {
        documentId: this.documentID,
      }
     
      var response
      await Promise.all([
          response = this.$store.dispatch('document/rejectSignatureDocument', payload),
          this.saveSignatureDocumentHistory('Rejected'),
          this.saveSignatureDocumentComment(),
        ])

      if (response) {
        this.isLoading = false
        this.showRejectSnackbar(true)

        await this.$amplitude.trackEvent('Signature Doc Reviewed', {
          'signatureAction': 'Rejected',
        })

        setTimeout(() => {
          if (this.isSignatureDocLinkView) {
            this.$router.push({
              name: 'TheSignatureConfirmation',
              params: {
                confirmationMessage: 'Document Rejected',
                confirmationSubMessage: 'Thank you for using Vasion',
              },
            })
          } else {
            this.backToPreviousView()
          }
        }, 1500)
      } else {
        this.isLoading = false
        this.showRejectSnackbar(false)
      }
    },
    async reloadDocument(documentID) {
      this.currentPageRotation = 0
      this.currentPageNumber = parseInt(this.$route.params.startingPage, 10)
      this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      this.document = {}
      this.thumbnails = []
      this.localFieldValues = []
      this.pages = []
      this.currentAttributeFormID = {
        value: 0,
        name: '',
      }
      this.viewMenuButtonConfig = {}
      this.streamPDFData = ''

      let data = await this.getDocumentDetails(documentID)
      if (!data || !data.DocumentId) {
        this.errorLoadingDocument = true
        this.isLoading = false
        return
      }

      this.errorLoadingDocument = false
      this.document = data
      this.signatureDocumentInitiatorUserID = data.signatureDocumentInitiatorUserID
      if (!this.isInSignatureMode && data.DisplayName) {
        document.title = data.DisplayName
      }
      this.$store.dispatch('document/getDocumentVersions', this.document.DocumentId)
      this.$store.dispatch('document/getDocumentPriority', this.document.DocumentId)

      this.viewMenuButtonConfig = {
        buttons: [
          {
            id: this.viewMode_Document,
            name: 'Document View',
            icon: this.viewMode_Document_Icon,
          },
          {
            id: this.viewMode_Data,
            name: 'Data View',
            icon: this.viewMode_Data_Icon,
          },
        ],
      }

      if (this.supportPDFViewer) {
        this.viewMenuButtonConfig.buttons.push({
          id: this.viewMode_PDF,
          name: 'PDF View',
          icon: this.viewMode_PDF_Icon,
        })

        this.streamPDFRequestController = new AbortController()
        let payload = {
          documentID: this.document.LocalPDFName,
          requestController: this.streamPDFRequestController
        }
        let streamData = await this.$store.dispatch('document/tryStreamPDF', payload)
        this.streamPDFRequestController = null
        const file = new Blob([streamData], { type: 'application/pdf' })
        this.streamPDFData = URL.createObjectURL(file)
        this.isPdfLoading = false
      }

      if(!this.isInWorkspaceApp) {
        this.viewMenuButtonConfig.buttons.push({
          id: this.viewMode_DefaultSelector,
          name: 'Customize Default',
          icon: this.viewMode_DefaultSelector_Icon,
        })
      }

      await this.$amplitude.trackEvent('Document Viewed', {
        'docViewType': this.docViewType,
        'fileType': this.fileType,
        'app': this.currentApp,
      })

      this.isLoading = false
      this.pendingSignatureZonesArray = this.document.pendingSignatureDocumentZones
      const pendingZonesPerUser = this.pendingSignatureZonesArray.filter(a => String(a.signatureUserID) === String(this.UserID) && a.roleName !== 'Viewer' && a.roleName !== 'Reviewer')
      this.totalSignatureZones = pendingZonesPerUser.length
      this.$store.dispatch('document/setPendingSignatureAnnotationIDsForCurrentUser', this.document.pendingSignatureAnnotationIDsForCurrentUser)
      this.$store.dispatch('document/setPendingSignatureDocumentZones', this.document.pendingSignatureDocumentZones)
      this.pages = [this.document.totalPages]
      this.hasPendingSignatureZones = this.pendingSignatureZonesArray && this.pendingSignatureZonesArray.some(a => String(a.signatureUserID) === String(this.UserID) && a.roleName !== 'Viewer' && a.roleName !== 'Reviewer')
      this.isReviewer = this.pendingSignatureZonesArray && this.pendingSignatureZonesArray.some(a => String(a.signatureUserID) === String(this.UserID) && a.roleName === 'Reviewer')
      this.isViewer = this.pendingSignatureZonesArray && this.pendingSignatureZonesArray.some(a => String(a.signatureUserID) === String(this.UserID) && a.roleName === 'Viewer')
      const pendingSignatureZones = this.pendingSignatureZonesArray.filter(a => String(a.signatureUserID) === String(this.UserID))
      pendingSignatureZones.filter(p => {
        if(!this.pendingSignaturesPageNumbers.find(a => a === p.pageNumber)) {
          this.pendingSignaturesPageNumbers.push(p.pageNumber)
        }
      })
      this.hasMoreThanOnePendingSignatureZone = this.pendingSignaturesPageNumbers.length > 1
      if (data.Tasks) {
        this.tasks = data.Tasks && data.Tasks.Values ? data.Tasks.Values : []
      }

      if (data.Workflow) {
        this.workflow = data.Workflow
        this.buttonOptions = data.Workflow.Actions && data.Workflow.Actions.Values ? data.Workflow.Actions.Values : []
        this.workflowComments = data.Workflow.Comments && data.Workflow.Comments.Values ? data.Workflow.Comments.Values : []
        this.userComments = data.Workflow.OnlyUserComments && data.Workflow.OnlyUserComments.Values ? data.Workflow.OnlyUserComments.Values : []
        this.workflowID = data.Workflow.WorkflowId

        const payload = {
          DocumentID: this.documentID,
          WorkflowID: data.Workflow.WorkflowId,
        }

        // load the due date from the server - it's possible it's different than what's on the document index field
        this.dueDate = await this.$store.dispatch('document/getDocumentDueDate', payload)

        if (data.IndexFormFields && data.IndexFormFields.Values && data.IndexFormFields.Values.length > 0) {
          const workflowDueDateField = data.IndexFormFields.Values.find(field => field.FieldName === 'Workflow_Due_Date')
          if (workflowDueDateField) {
            this.dueDateLabel = workflowDueDateField.DisplayName
          }
        }

        if (this.workflow.PasswordProtected) {
          this.actionPasswordRequired = true
        }
      }

      for (let i = 0; i < this.totalPages; i += 1) {
        this.pages[i] = {
          pageNumber: (i + 1),
          imageSource: '',
        }
      }

      const foundForm = this.attributeForms.find((t) => t.value === this.documentAttributeFormID)
      if (foundForm) {
        this.currentAttributeFormID = foundForm
      }
      if (this.$route.params.startingPage) {
        this.currentPageNumber = parseInt(this.$route.params.startingPage, 10)
      } else {
        this.currentPageNumber = 1
      }
      this.loadThumbnails()

      if (data.signatureDocumentGUID) {
        this.applyTimestamp = data.applySignatureTimestamp
      } else {
        this.applyTimestamp = true
      }

      this.$store.dispatch('document/setCoCIncluded', this.document.CoCIncluded)

      if (data.meanConfidence) {
        const color = getMeanConfidenceColor(data.meanConfidence)
        if (color === 'LawnGreen') {
          this.meanConfidenceIcon = 'VasionFieldValidationGreen'
        } else if (color === 'Yellow') {
          this.meanConfidenceIcon = 'VasionFieldValidationYellow'
        } else if (color === 'Red') {
          this.meanConfidenceIcon = 'VasionFieldValidationRed'
        }
        
        this.aIPValuesPresent = true
      }
    },
    async requestTextStampImg() {
      let fields = []
      for (let index = 0; index < this.localFieldValues.length; index++) {
        const element = this.localFieldValues[index];
        fields.push({Key: element.name, Value: element.value })
      }

      const payload = {
        documentId: this.documentID,
        textStampAnnImageId: this.currentTextStampAnnID,
        width: parseInt(this.textStampSize.width),
        height: parseInt(this.textStampSize.height),
        fieldValues: { Values: fields },
      }
      let response = await this.$store.dispatch('document/getTextStampAnnotationImage', payload)

      if (!response || !response?.ResultStatus === 1) {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = response?.ResultMessage ? response.ResultMessage : 'The stamp could not be generated.'
        this.snackbarImage = false
        this.toggleData('showTextStampFieldsDialog')
        await this.$store.dispatch('document/setDeleteCurrentAnnotation', true)
        return
      }

      await this.indexFields.map((indexField) => {
        const sameField = this.localFieldValues.find( (field) => field.name === indexField.value)
        if (sameField && sameField?.value !== indexField?.filterValue) indexField.filterValue = sameField.value
        return indexField
      })
      this.attributeFieldsKey++

      if (this.indexFieldsForTextStamp.length) this.toggleData('showTextStampFieldsDialog')
      await this.$store.dispatch('document/setTextStampToReplace', response.Value)
    },
    async resendLinkButtonClicked() {
      if (this.signatureDocumentID === -1) {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Cannot resend email without pending signature zones'
        this.snackbarImage = false
        return
      }
      const resendPayload = {
        DocumentId: this.signatureDocumentID,
      }
      let resendSuccess = false
      const returnValue = await this.$store.dispatch('document/resendSignatureDocumentLink', resendPayload)
      if (returnValue.includes('succeeded')) {
        const historyPayload = {
          documentID: this.documentID,
          UserType: 'Sender',
          geolocation: this.geolocation ? this.geolocation : '',
          ipAddress: this.ipAddress ? this.ipAddress : '',
          actionTaken: 'Resend',
          comment: '',
        }
        const signatureValue = await this.$store.dispatch('document/addSignatureDocumentHistory', historyPayload)
        if (signatureValue) {
          resendSuccess = true
        }
      }

      this.showSnackbar = true
      this.snackbarTitle = resendSuccess ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = returnValue
      this.snackbarImage = resendSuccess
    },
    async reviewedSignatureDocument() {
      this.isLoading = true

      const payload = {
        documentId: this.documentID,
      }

      await Promise.all([
        this.$store.dispatch('document/approveSignatureDocument', payload),
        this.saveSignatureDocumentHistory('Reviewed'),
        this.saveSignatureDocumentComment(),
      ])
      this.isLoading = false

      if (this.isSignatureDocLinkView) {
        this.$router.push({
          name: 'TheSignatureConfirmation',
          params: {
            confirmationMessage: 'Document Reviewed',
            confirmationSubMessage: 'Thank you for using Vasion',
          },
        })
      } else {
        this.backToPreviousView()
      }
    },
    rotate(direction) {
      const rightOrLeft = direction === 'right' ? 90 : -90
      this.currentPageRotation += rightOrLeft
      if (Math.abs(this.currentPageRotation) == 360) {
        this.currentPageRotation = 0
      }
      const imgContainer = document.getElementsByClassName('rotateMeImage')[0]
      const img = imgContainer.childNodes[1];
      img.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the main document
      if (imgContainer.childNodes[2])
      {
        const annotations = imgContainer.childNodes[2].firstChild;
        annotations.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the main document's annotations
      }
      if(document.getElementsByClassName('thumbnailImageSelected')[0])
      {
        const activeThumbnailContainer = document.getElementsByClassName('thumbnailImageSelected')[0].firstChild.firstChild
        const activeThumbnail = activeThumbnailContainer.childNodes[1];
        activeThumbnail.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the selected document's thumbnail
        const activeThumbnailAnnotations = activeThumbnailContainer.childNodes[2].firstChild
      activeThumbnailAnnotations.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the selected document's thumbnail's annotations
      }
    },
    rotateLeft() { this.rotate('left') },
    rotateRight() { this.rotate('right') },
    async nextDocument() {
      this.isLoading = true
      let nextDocumentId = this.additionalDocumentIds[0]
      let remainingDocuments = this.additionalDocumentIds.shift()
      remainingDocuments = this.additionalDocumentIds.join(',')
      this.additionalDocumentIds.length === 0 ? this.$router.replace({ path: `/document/${nextDocumentId}`}) :
        this.$router.replace({ name: 'DocumentMultiple', params: { documentID: nextDocumentId, additionalDocumentIds: remainingDocuments } })
      this.isLoading = false
      this.showNextDocumentSnackbar(true, 'Document')
    },
    async saveAllTypes(hideUI, reloadDocument, nextDocument = false) {
      if (this.isDirtyFromDisplayName) {
        await this.editDisplayName()
        if (!this.isDirty) {
          this.showSavedSnackbar(true, 'The Display name was')
          return
        }
      }
      if (!hideUI) {
        this.isLoading = true
      }
      // we want to save the attribute data *before* the workflow things are saved
      const attributeSuccess = await this.saveAttributeData()
      const wfSaveSuccess = await Promise.all([this.saveDueDate(), this.savePriority()])

      const saveSuccess = attributeSuccess && !wfSaveSuccess.includes(false)

      if (reloadDocument && !nextDocument) {
        this.reloadDocument(this.documentID)
      }
      else if (reloadDocument && nextDocument) {
        this.nextDocument()
      }

      if (!hideUI) {
        this.isLoading = false
        this.showSavedSnackbar(saveSuccess, 'Document Attributes')
      }
      this.markClean()
      this.firstRun = true
    },
    async saveAttributeData() {
      if ((!this.currentAttributeFormID || this.currentAttributeFormID.value <= 0) && !this.isDirtyFromTheDocumentData) {
        return true
      }
      if (this.isDirtyFromTheDocumentData) {
        const saveResult = await this.$refs.documentData.saveAttributeData()
        if (saveResult) {
          this.dirtyAttributeState = false
          this.isDirtyFromTheDocumentData = false
        }

        if (this.viewMode === DocumentViewOptions.Data) {
          return saveResult
        }
      }

      const payload = {
        DocumentId: this.documentID,
        FormId: this.currentAttributeFormID.value,
        FieldValues: {
          Values: [],
        },
      }

      // first build up the fields that changed
      if (this.localFieldValues) {
        this.localFieldValues.forEach((localValue) => {
          payload.FieldValues.Values.push({
            Key: localValue.name,
            Value: this.getStringFromIndexValue(localValue.value),
          })
        })
      }

      if (this.currentAttributeFormID && this.currentAttributeFormID.value === this.documentAttributeFormID) {
        // we're not changing the attribute form, so check to see if anything changed
        if (payload.FieldValues.Values.length === 0) {
          return true // nothing changed, so we're done!
        }
      }

      // now add anything to the list that was previously on the document
      this.documentAttributeFields.forEach((docField) => {
        const foundField = this.localFieldValues.find((local) => {
          return local.name === docField.FieldName
        })

        if (!foundField) {
          payload.FieldValues.Values.push({
            Key: docField.FieldName,
            Value: this.getStringFromIndexValue(this.getValueFromDocField(docField)),
          })
        }
      })

      const result = await this.$store.dispatch('document/saveDocumentAttributes', payload)
      const saveSuccess = !!result

      if (saveSuccess) {
        this.dirtyAttributeState = false
        this.isDirtyFromTheDocumentData = false
        this.localFieldValues = [] // can remove any locally modified fields because they've been saved
        await this.$amplitude.trackEvent('Document Saved', {'docSaveViewType': this.docViewType, })
      }

      return saveSuccess
    },
    async saveSignatureDocumentHistory(actionTaken) {
      const payload = {
        documentID: this.documentID,
        UserType: 'Signer',
        geolocation: this.geolocation ? this.geolocation : '',
        ipAddress: this.ipAddress ? this.ipAddress : '',
        actionTaken: actionTaken,
        comment: actionTaken === 'Comment' && this.signatureComment ? this.signatureComment : '',
        ...(actionTaken === 'Signed' && this.selectedAnnotation?.propertiesSubType === 'initial' && { initialsImage: this.currentSignatureImage } ),
        ...(actionTaken === 'Signed' && this.selectedAnnotation?.propertiesSubType === 'signature' && { signatureImage: this.currentSignatureImage } ),
      }
      this.currentSignatureImage = ''

      return await this.$store.dispatch('document/addSignatureDocumentHistory', payload)
    },
    async saveDueDate() {
      if (!this.dirtyDueDateState || !this.workflowID) { return true }

      const payload = {
        documentID: this.documentID,
        workflowID: this.workflowID,
        dueDate: this.dueDate,
      }
      const result = await this.$store.dispatch('workflow/saveWorkflowDueDate', payload)
      const saveSuccess = result.Value === 'True'

      if (saveSuccess) {
        this.dirtyDueDateState = false
      }

      return saveSuccess
    },
    async saveSignatureDocumentComment() {
      if (!this.signatureComment) {
        return
      }

      const payload = {
        DocumentId: this.documentID,
        NoteText: this.signatureComment,
        PageNumber: this.currentPageNumber,
      }

      await Promise.all([
        this.$store.dispatch('document/saveNote', payload),
        this.saveSignatureDocumentHistory('Comment'),
      ])
    },
    async savePriority() {
      if (!this.dirtyPriorityState) { return true }
      const payload = {
        documentID: this.documentID,
        priorityID: this.priorityValue,
      }
      const result = await this.$store.dispatch('document/savePriority', payload)
      const saveSuccess = result.Value === 'True'
      if (result.Value === 'True') {
        this.dirtyPriorityState = false
      }

      return saveSuccess
    },
    async saveRotate() {
      let pageRotation = this.currentPageRotation >= 0 ? this.currentPageRotation : 360 + this.currentPageRotation
      const payload = {
        DocumentId: this.documentID,
        PageNumber: this.currentPageNumber,
        RotationDegrees: pageRotation,
      }
      const result = await this.$store.dispatch('document/rotateDocument', payload)
      if(result.Value) {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Unable to Save Rotate'
        this.snackbarImage = false
      }
      else {
        this.reloadDocument(this.documentID)
      }
    },
    setActionFields(values) {
      this.actionsFieldList = values.map(v => {
        const dropValues = []
        const dropSelectedValues = []
        if (v.DropDownValues) {
          v.DropDownValues.Values.forEach((d, i) => {
            dropValues.push({
              name: d,
              value: i,
            })
          })
        }
        if (v.DropDownSelectedValues) {
          v.DropDownSelectedValues.Values.forEach(d => {
            dropSelectedValues.push(d)
          })
        }
        let filterValue = v.DateValueUTC ? v.DateValueUTC : v.TextValue
        if (!filterValue) {
          filterValue = dropSelectedValues
        }
        return {
          allowMultiples: v.AllowMultiples,
          dropdownValues: dropValues,
          fieldID: v.FieldID,
          filterValue,
          hidden: v.Hidden,
          name: v.DisplayName,
          readOnly: v.ReadOnly,
          type: v.Control,
          value: v.FieldName,
          required: v.Required,
          decimalPlaces: v.DecimalPlaces,
          showCommas: v.ShowCommas,
          currencyFormat: v.CurrencyFormat,
        }
      })
    },
    setActionPassword(value) {
      if (value) {
        this.actionPassword = value
      }
      this.workflowAction(this.currentWorkflowAction)
    },
    setDisplayNameEditionMode(mode) {
      const optionsAllowed = ["on", "off"]
      if (!optionsAllowed.includes(mode)) return

      const inEditMode = mode === "on" ? true : false
      this.displayNameInEditMode = inEditMode
      this.isDirtyFromDisplayName = inEditMode
      this.displayNameInputValue = this.document.DisplayName

      setTimeout(() => {
        if (inEditMode) {
          this.updateDisplayInputSize()
          this.$refs.displayNameInput.focus()
          this.$refs.displayNameInput.oninput = this.updateDisplayInputSize
        }
      }, 20);
    },
    setDropdownLocation(x, y, offsetX, offsetY) {
      const bonusWhiteSpaceY = 66
      const leftNavWidthOffset = window.innerWidth > 950 ? 330 : 0 // 950 is the viewport width that the left side nav hides itself
      let newX = x - offsetX - leftNavWidthOffset
      let newY = y - offsetY - bonusWhiteSpaceY
      this.dropdownLocation = `top: ${newY}px; left: ${newX}px;`
    },
    setDueDate(event) {
      this.dueDate = event
    },
    setEmptyTextAnnotations(count) {
      this.emptyTextAnnotations = Number(count)
    },
    setFormName() { this.formName = this.attributeForms.find((t) => t.value === this.currentAttributeFormID.value).name },
    setWorkflowAction(action) {
      this.currentWorkflowAction = action
      if (this.actionPasswordRequired) {
        this.showActionPasswordDialog = true
      } else {
        this.workflowAction(action)
      }
    },
    async setZoom(width) {
      let tempZoomValue = this.zoomValue
      await this.$store.dispatch('document/resetZoomValue')
      if(this.firstTimeImageLoad){
        this.firstTimeImageLoad = false
        const zoomWidth = document.getElementById('image-div').offsetWidth / (width + 75)
       this.$store.dispatch('document/setZoomValue', zoomWidth)
      } else {
        this.$store.dispatch('document/setZoomValue', tempZoomValue)
      }
    },
    showCertificate() {
      // TODO: Implement this method to display the certificate on button click
    },
    showEmailSnackbar(value) {
      this.showSnackbar = true
      this.snackbarTitle = value[0] ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value[0] ? 'Email Sent!' : 'Unable to Send Email'
      // eslint-disable-next-line prefer-destructuring
      this.snackbarImage = value[0]
      if (value[0]) this.trackByType('Email'); 
    },
    showRecallSnackbar(value) {
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? 'Document Recalled' : 'Unable to Recall'
      this.snackbarImage = value
    },
    showRejectSnackbar(value) {
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? 'Document Rejected' : 'Unable to Reject'
      this.snackbarImage = value
    },
    showSavedSnackbar(value, type) {
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? `${type} Saved!` : `Unable to Save ${type}`
      this.snackbarImage = value
    },
    showNextDocumentSnackbar(value, type){
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? `${type} Loading!` : `Unable to Load ${type}`
      this.snackbarImage = value
    },
    async showHistory() {
      this.signatureTabSelected = this.isInSignatureMode
      this.$store.dispatch('document/getDocumentHistory', this.documentID)
      this.signatureDocumentHistoryValues = await this.$store.dispatch('document/getSignatureDocumentHistory', this.documentID)
      this.historyMode = 'history'
      this.showHistoryDialog = !this.showHistoryDialog
    },
    showUploadMessage() {
      this.showSnackbar = true
      this.snackbarTitle = 'SUCCESS'
      this.snackbarSubTitle = 'Document saved!'
      this.snackbarImage = true
    },
    async signatureCommentButtonClicked() {
      const response = await this.saveSignatureDocumentHistory('Comment')
      this.signatureComment = ''

      if (response) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Comment saved successfully!'
        this.snackbarImage = true
        this.showSnackbar = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Something went wrong while trying to save your comment.'
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    async startWorkflowForSelectedDocuments() {
      this.showErrorLabel = false
      const docID = this.documentID.toString()
      const workflowID = this.selectedWorkflowID
      if (Object.keys(this.startWorkflowPayload).length === 0) {
        this.startWorkflowPayload = {
          documentId: docID,
          workflowId: workflowID,
          userId: parseInt(this.UserID, 10),
        }
      }
      const response = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.startWorkflowPayload)
      if (response === null) {
        this.showWorkflowList = false
        this.showUserSelectModal = false
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Workflow has been started'
        this.snackbarImage = true
        if (this.hasAdditionalDocumentIds){
           setTimeout(() => {
            this.nextDocument()
          }, 2000)
        }
        else {
          setTimeout(() => {
            this.backToPreviousView()
          }, 2000)
        }

      } else if (response?.userAssignPayload.retPromptKey === 'prmt_ChooseFromGroup') {
        this.userGroupAssignConfig.groupId = response.assignUserGroupFilterId
        this.startWorkflowPayload = response.userAssignPayload
        this.showWorkflowList = false
        this.showUserSelectModal = true
      } else {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error starting the Workflow'
        this.snackbarImage = false
      }
    },
    toggleData(key) {
      this[key] = !this[key]
    },
    toggleRejectSignature() {
      if (this.signatureComment === '' && !this.showRejectSignatureModal) {
        this.missingCommentForReject = true
      } else if (this.signatureComment !== '' && !this.showRejectSignatureModal) {
        this.showRejectSignatureModal = true
      } else {
        this.showRejectSignatureModal = false
      }
    },
    toggleRotateActions(event) {
      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.showRotateActions = !this.showRotateActions
    },
    toggleShowAddSignatureDialog() {
      if (!this.isInSignatureMode) {
        this.addInitialsDropListActive = !this.addInitialsDropListActive
      }
      this.showAddSignatureDialog = !this.showAddSignatureDialog
    },
    toggleShowAddSignatureList(event) {
      if (!event) { event = this.signatureIconClickEvent }
      this.addStampsDropListActive = false
      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.addInitialsDropListActive = !this.addInitialsDropListActive
    },
    toggleShowAddStampList(event) {
      this.addInitialsDropListActive = false
      this.showAddSignatureDialog = false
      this.addTextStampsDropListActive = false

      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.addStampsDropListActive = !this.addStampsDropListActive
    },
    toggleShowAddTextStampList(event) {
      this.addInitialsDropListActive = false
      this.showAddSignatureDialog = false
      this.addStampsDropListActive = false

      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.addTextStampsDropListActive = !this.addTextStampsDropListActive
    },
    async toggleStartWorkflow() {
      if (!this.currentAttributeFormID || this.currentAttributeFormID.value === undefined || this.currentAttributeFormID.value <= 0) {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = `Please select an ${$formsLabel}.`
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }
      const payload = {
        includeStartFromEform: false,
        includeScan: true,
        includeBrowse: true,
        includeUpload: true,
        formID: this.currentAttributeFormID?.value,
      }

      this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItemsFiltered', payload)
      if (!this.workflowList || this.workflowList.length <= 0) {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = `No Workflows Configured for the ${$formsLabel}`
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      this.showWorkflowList = true
    },
    toggleUploadAndPage() {
      this.showUploadNewPageModal = !this.showUploadNewPageModal;
      this.showAddPagesModal = !this.showAddPagesModal;
    },
    toggleViewClick() {
      this.showViewModeMenu = !this.showViewModeMenu
    },
    async trackByType(docType) {
      await this.$amplitude.trackEvent('Document Viewed', {
        'docViewType': docType,
        'fileType': this.fileType,
        'app': this.currentApp,
      })
    },
    updateLocalFieldValues(childComponentValues) { this.localFieldValues = childComponentValues },
    updateLookupValues(item) {
      this.showMultipleLookupDialog = false
      this.multipleLookupResults = []
      if (this.indexPromptName === 'prmt_ChooseLookupResult') {
        //coming from WF Action button
        this.promptData =  {
          Values: [
            {
              Key: this.indexPromptName,
              Value: {
                Values: [
                  {
                    Key: 'data',
                    Value: JSON.stringify(item),
                  },
                ],
              },
            },
          ],
        }
        this.workflowAction(this.action)
      } else {
        this.$refs.attributeFieldsComponent.updateFieldsFromLookup(item)
        this.dirtyAttributeState = true
      }
    },
    updateSelectedPages(selectedList) {
      this.selectedPagesForDelete = selectedList
      if (this.selectedPagesForDelete.length > 0) {
        this.disableDeletePagesButton = false
      } else {
        this.disableDeletePagesButton = true
      }
    },
    updateDisplayInputSize() {
      const span = document.getElementById('size-calibration');
      const input = document.getElementById('document-display-name')
      span.innerText = `${input.value.substring(0, 60)}  `;
    },
    async updateFilteredFieldsOptions(filteredData, fields) {
      let newFields = cloneDeep(fields)
      for (const key in fields) {
        const field = fields[key]
        const filteredField = lodashFind(filteredData, ['value', field.FieldName])
        if (filteredField && filteredField?.type === 2 && filteredField.dropdownValues) {
          const dropdownValues = filteredField.dropdownValues.map(value => value.name)
          if (newFields[key] && newFields[key].DropDownValues) {
            newFields[key].DropDownValues.Values = dropdownValues
          }
        }
      }
      return newFields;
    },
    updateTasks(newTaskList) { this.tasks = newTaskList },
    async uploadDocument() { await this.$store.dispatch('document/uploadNewPages') },
    uploadNewPageToggle(index) {
      this.uploadIndex = index
      this.showAddPagesModal = !this.showAddPagesModal
      this.showUploadNewPageModal = !this.showUploadNewPageModal
      this.useStoreAddPage = true;
    },
    uploadPage(newPage) { this.$store.dispatch('document/insertReplacePages', newPage) },
    versionSelect(payload) { this.document = payload.data },
    viewMenuSelectionMade(payload) {
      this.closeViewModeMenu()
      if (!payload || !payload.buttonId || payload.buttonId === 'close') {
        return
      }

      if(payload.buttonId === this.viewMode_DefaultSelector){
        this.showDefaultDocumentViewSelectionModal = true;
        return;
      }

      this.viewMode = payload.buttonId

      this.$amplitude.trackEvent('Document Viewed', {
        'docViewType': this.docViewType,
        'fileType': this.fileType,
        'app': this.currentApp,
      })
    },
    async workflowAction(action) {
      this.isLoading = true
      await this.saveAllTypes(true, false)

      this.action = action
      const payload = {
        CheckListComplete: true,
        UniqueName: action,
        DocumentId: this.documentID,
        Password: this.actionPassword,
        Comment: this.newComment,
        PromptData: this.promptData,
        StepID: this.$route.params.workflowStepId,
      }

      const response = await this.$store.dispatch('workflow/doWorkflowAction', payload)
      this.isLoading = false
      if (response && response.StepComplete && response.PromptName !== 'prmt_Open_URL') {
        this.showSnackbar = true
        this.snackbarTitle = 'SUCCESS'
        this.snackbarSubTitle = `${action} successful`
        this.snackbarImage = true
        this.$router.push({ name: 'TheWorkspaceHome' })
        this.showActionPasswordDialog = false
        this.promptData = {}
        await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: action, isMultipleDocs: false})
      } else if (response?.PromptName === 'prmt_ChooseGroupUser' || response?.PromptName === 'prmt_ChooseFromGroup') {
        // prmt_ChooseGroupUser = Need to Reassign or Forward
        // prmt_ChooseFromGroup = Need to assign to a user within a specific group
        this.showActionPasswordDialog = false
        this.userGroupAssignConfig.type = response?.PromptName
        if (this.userGroupAssignConfig.type === 'prmt_ChooseFromGroup') {
          this.userAssignPayload = response?.PromptData
          this.userGroupAssignConfig.userOnly = true
          this.modalTitle = 'Select User'
        } else if (this.action === "ReAssign"){
          this.modalTitle = "Reassign"
        } else {
          this.modalTitle = this.action
        }

        const userList = response.UserList && response.UserList.Values && response.UserList.Values.length > 0
                ? response.UserList.Values
                : []
        const groupList = response.GroupList && response.GroupList.Values && response.GroupList.Values.length > 0
                ? response.UserList.Values
                : []
        const dataList = await this.createUserAndGroupData(userList, groupList)
        this.$store.dispatch('common/setGroupAndUsers', dataList)

        this.showGroupModal = true;
      } else if (response?.PromptName === 'prmt_IndexDocument') {
        this.indexPromptName = response?.PromptName
        const updatedFields = await this.updateFilteredFieldsOptions(this.filteredFieldOptions, response.FieldList.Values)
        this.setActionFields(updatedFields)
        this.showActionPasswordDialog = false
        this.showIndexPrompt = true
      } else if (!response?.StepComplete && response?.PromptName === 'prmt_Password') {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Invalid Password'
        this.snackbarImage = false
        this.$refs.passwordPrompt.ref_setErrorClass()
      } else if (response?.PromptName === 'prmt_Note') {
        this.showSnackbar = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'In order to complete this step, a Workflow Comment is required.'
        this.snackbarImage = false
      } else if (response?.PromptName === 'prmt_Complete_Tasks') {
        this.showSnackbar = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'Please complete the list of tasks in order to complete the step.'
        this.snackbarImage = false
      } else if (response?.PromptName === 'prmt_Open_URL') {
        if (response?.StepComplete) {
          this.showSnackbar = true
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = `${action} successful`
          this.snackbarImage = true
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.showActionPasswordDialog = false
          this.promptData = {}
        }
        let url = response?.PromptData?.Values[0]?.Value?.Values[0]?.Value
        if (url.length > 0) {
          if (!url.startsWith('http')) {
            url = `http://${url}`
          }
          let openInNewWindow = response?.PromptData?.Values[0]?.Value?.Values[1]?.Value
          openInNewWindow = openInNewWindow ? openInNewWindow.toLowerCase() === "true" : false

          if (openInNewWindow) 
            openUrlInNewBrowserWindow(url)
          else
            window.open(url, '_blank')
          
          await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: action, isMultipleDocs: false})
        } else {
          this.showSnackbar = true
          this.snackbarTitle = 'Unable to continue'
          this.snackbarSubTitle = 'Tried to open URL, but URL was blank.'
          this.snackbarImage = false
        }
      } else if (response?.PromptName === 'prmt_ChooseLookupResult') {
        this.indexPromptName = response?.PromptName
        const rowsForTable = response.LookupResults.Rows.Values.map(v => {
          return v.Values
        })
        this.multipleLookupResults =  {
          Columns: response.LookupResults.Columns.Values,
          Rows: rowsForTable,
        }
        this.showMultipleLookupDialog = true
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = response && response.Message ? response.Message : `${action} failed`
        this.snackbarImage = false
      }
    },
    zoomIn() {
      if (!this.disableZoomIn) {
        this.$store.dispatch('document/setZoomValue', true)
      }
    },
    zoomOut() {
      if (!this.disableZoomOut) {
        this.$store.dispatch('document/setZoomValue', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .collapse-side-bar {
    position: absolute;
    bottom: 0px;
  }

  .no-objects-warning {
    margin: 7px;
    padding: 8px 16px;
    border-left: 5px solid #F1C40F;
    background-color: #fff3c3;
    color: #cca610;
  }

  .md-layout {
    flex-wrap: nowrap !important;
  }

  .file-pond-block-wrapper {
    width: 400px;
    padding: 0;
  }

  .error-back-div {
    margin-top: 24px;
  }

  .error-div {
    padding: 48px 12px 0px 12px;
    width: 100%;
    text-align: center;
  }

  .error-state-container {
    border: 1px solid $error-red !important;
  }


  .comment-textarea{
    margin: 6px 8px;
    width: 95%;
    padding: 10px;
    height: 124px;
    z-index: 5;
  }

  .comment-textarea:focus{
    outline: none;
  }

  .bottom-margin {
    margin-bottom: 10px;
  }
  .circle-icon-color {
    fill: #838587;
    position: absolute;
    left: 242px;
  }
  .document-container {
    border: 1px solid $surface;
    background: $surface-container;
    padding: 24px 10px 24px 0px;
    height: calc(100vh - 57px);
    overflow: hidden;
  }
  @media (min-width: $breakpoint-md) {
    .document-container {
      border-radius: 16px;
      height: calc(100vh - 116px);
    }
  }
  .custom-actions {
    overflow: auto;
    padding: 0px 25px 25px 25px;
    height: 100%;
  }
  #customActionsDialog {
    width: 50%;
    max-width: 80%;
    max-height: 80%;
  }
  .dropDownOpen {
    min-height: 50%;
  }
  .data-view-container {
    display: flex;
    justify-content: center;
    overflow: auto;
    height: calc(100vh - 160px);
    padding: 0 16px 16px 16px;
  }
  .data-view {
    width: 90%;
    height: calc(100vh - 240px);
    overflow: auto;
    background-color: white;
    margin-top: 16px;
    box-shadow: 0px 7px 7px 0px $shadow;
    border-radius: 8px;
  }
  .data-view-header {
    margin: 0;
    padding: 33px 0px 31px 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $grey-50;
  }
  .document-title {
    font-family: $font-family-bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-500;
    text-align: left;
    padding-top: 5px
  }
  .double-icon {
    width: 40px;
    border-radius: 3px;
  }
  .padding-all {
    padding: 0 20px;
  }
  .index-field-list-padding {
    padding: 0 20px 15px;
  }
  .action-links {
    width: 64px;
    height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    position: relative;

    .action-link {
      margin: 13px 17px;
      cursor: pointer;
      z-index: 3;
    }
  }

  #show-sidebar-button {
    display: none;
  }

  .headerDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  .alignCenter {
    display: flex;
    align-items: center;
  }

  .index-field-title {
    padding: 25px;
  }

  .signature-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .flex-display {
    display: flex;
  }

  .stamp-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  #image-div {
    width: 0;
  }
  .mainImageDiv {
    padding: 16px;
    overflow: auto;
    height: calc(100vh - 260px);
    text-align: center;
  }
  @media (max-width: $phone) {
    .mainImageDiv {
      padding: 20px;
    }
  }
  .required-fields {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .zones-counter {
    margin-left: 5px;
    margin-right: 5px;
    width: 48px;
    height: 24px;
    opacity: .7;
    border-radius: 12px;
    background-color: #e6f0fc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .extra-padding-bottom {
    padding-bottom: 12px;
  }

  .initials-drop-list {
    position: absolute;
    left: 730px;
    top: 106px;
    z-index: 11;
    background-color: white;
    box-shadow: 0 1px 3px 0 $shadow;
    border-radius: 8px;
    ul {
      margin: 0;
      padding-left: 0;
      :hover {
        background-color: $grey-75;
        cursor: pointer;
      }
    }
    li {
      padding: 0 16px;
      width: 280px;
      border-bottom: 1px solid #d4d4d4;
      height: 96px;
      display: flex;
      align-items: center;
    }
    .add-new-signature {
      height: 60px;
      color: $grey-500;
      font-family: $font-family-book;
    }
  }

  .stamps-drop-list {
    position: absolute;
    z-index: 11;
    background-color: white;
    box-shadow: 0 1px 3px 0 $shadow;
    border-radius: 8px;
    ul {
      margin: 0;
      padding-left: 0;
      :hover {
        background-color: $grey-75;
        cursor: pointer;
      }
      max-height: 300px;
      overflow-y: auto;
    }
    li {
      padding: 0 16px;
      width: 280px;
      border-bottom: 1px solid #d4d4d4;
      height: 96px;
      display: flex;
      align-items: center;
    }
  }

  .stamps-drop-list.text-stamps {
    li {
      width: 210px;
      height: 52px;
    }
  }

  .gesture-gray {
    fill: $grey-400;
    margin-right: 16px;
  }

  .innerDiv {
    padding-top: 10px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .remainingDocumentSection {
    -webkit-flex: 1; /* Chrome */
    flex: 1; /* NEW, */
    overflow: auto;
    background-color: $white;
  }

  .divider {
    width: 1px;
    background-color: $divider-light-grey;
  }

  .alignRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .Rectangle-2020 {
    width: 16px;
    height: 16px;
    background-color: $input-text;
    border-radius: 2px;
  }

  .Rectangle-2020-White {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1px $input-text;
    background-color: $white;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3px;
    padding-right: 100px;
    width: 100%;
  }

  .lightestGreyBackground {
    background-color: $grey-75;
  }

  .signature-image {
    width: auto;
    max-height: 85px;
  }

  .stamp-image {
    width: auto;
    max-height: 85px;
    max-width: 175px;
  }

  .attribute-top-section-with-workflow {
    padding: 15px 15px 15px 15px !important;
    border-top: 2px solid #e4e5e6;
    border-bottom: 2px solid #e4e5e6;
  }

  .attribute-top-section-without-workflow {
    padding: 0px 15px 15px 15px !important;
    border-bottom: 2px solid #e4e5e6;
  }

  #new-comment {
    width: calc(100% - 12px);
    margin: 7px;
  }

  .attribute-droplist {
    margin: 5px;
  }

  .attributes-tab-div, .attributes-tab-div-closed {
    width: 300px;
    max-width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 260px);
    transition: 0.275s;
  }

  .attributes-padding-div {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .attributes-padding-div-buttons {
    width: 100%;
  }

  .alignRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .align-text-and-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .email-modal-div {
    overflow: auto;
  }

  .toolbar-button {
    margin: 0 0 5px 0;
  }

  .toolbar-height {
    height: 24px;
  }
  .toolbar-height-not-native-document-view {
    margin-bottom: 10px;
  }
  .toolbar-margin-not-document-view {
    margin-left: 5px;
  }

  .pdfiFrame {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
    height: calc(100vh - 160px);
    padding: 0 16px 16px 16px;
}

  .pdfiFrame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #confirmCompleteSignature {
    max-width: 615px;
    margin: 10px 0px 10px 0px;
  }
  .prompt-password-dialog {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .prompt-password-dialog > * {
    margin: 5px 0px;
  }
  .password-label {
    margin-top: 10px;
  }
  .entered-password {
    margin-bottom: 20px;
  }
  .side-panel-expanded {
    background-color: #ffefe6;
    fill: $orange-300 !important;
    color: $orange-300 !important;
  }
  #documentHistory {
    fill: black;
  }
  #history-tab-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #signature-document-title-container {
    display: flex;
    flex-direction: column;
  }

  .toolbar-divider {
    border-left: solid 1px $grey-100;
    height: 24px;
    margin-top: 5px;
    position: absolute;
    margin-left: 10px;
  }
  .toolbar-divider + .toolbar-icon {
    margin-left: 20px;
  }

  #toggle-btn {
    width: 100%;
    position: absolute;
    padding-right: 25px;
  }

  .drop-arrow-icon {
    margin-bottom: 4px;
  }

  .center-flex-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #apply-signature-dialog {
    width: 982px;
    height: 394px;
  }

  @media(min-height: 1200px) {
    .data-view {
      height: calc(100vh - 250px)
    }
  }

  @media(max-width: 1410px) { // TODO: Change this to 1280 once the zoom buttons are removed from the toolbar and match zeplin
    #ellipsis-toolbar {
      padding: 5px 5px 15px 5px !important;
      gap: 5px 0;
      border-radius: 8px;
      background: white;
      position: absolute;
      z-index: 100;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    }
  }

  @media(max-width: 1170px) { // TODO: Change this to 1280 once the zoom buttons are removed from the toolbar and match zeplin
    .toolbar-div {
      padding-bottom: 15px;
      gap: 15px 0;
    }
  }

  @media(max-width: 950px) { // 950px is where the mobile layout kicks in, and the left nav is removed
    .action-links {
      width: 40px;

      .action-link
      {
        margin-left: 4px;
        margin-right: 4px;
      }
    }    
  }

  @media(max-width: 875px) {
    #ellipsis-toolbar { // TODO: Move the padding design to @media(max-width: 720px) once the zoom buttons are removed from the toolbar and match zeplin
      padding: 5px !important;
    }
  }

  @media(max-width: 720px) {
    #show-sidebar-button {
    display: block;
    border-radius: 32px;
    margin-right: 45px;
    margin-top: -35px;
    float: right;
    }
    .attributes-padding-div {
      padding: 16px;
    }
    .attributes-tab-div{
      position: absolute;
      right: 0;
      background-color: white;
      border-left: 1px #e1e8ed solid;
      z-index: 10;
    }
    .attributes-tab-div-closed {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: center;
      width: 0px;
      overflow-y: auto;
      height: calc(100vh - 161px);
      transition: 0.275s;
      background-color: white;
      border-left: 1px #e1e8ed solid;

      .right-side-panel-content{
        display: none;
      }
    }
  }
  .right-side-panel-content{
    padding-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media(max-width: 720px) {
    .hide-under-720px-vw {
      display: none;
    }
    #apply-signature-dialog {
      height: 450px;
    }
  }

  @media (max-width: 500px) {
    #toolbar-print-upload-email,
    .toolbar-divider,
    #toolbar-add-delete-pages-and-rotate,
    #toolbar-annotations,
    #toolbar-ellipsis {
      display: none;
    }

    .toolbar-div {
      flex-wrap: nowrap;
      padding-right: 4px;
      .toolbar-icon {
        margin-left: 0px;
      }
    }

    .toolbar-div-not-in-document-view {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 460px) {
    #ellipsis-toolbar {
      padding-bottom: 15px !important;
    }
    #toggle-btn {
      padding-right: 0px;
    }
    #show-sidebar-button {
      margin-right: 0px;
      margin-top: 0px;
    }
  }

  @media screen and (orientation:landscape) and (max-width: 991.98px) {
    .hide-under-720px-vw {
      display: none;
    }
    #show-sidebar-button {
      display: block;
      border-radius: 32px;
      margin-right: 4px;
      margin-top: 0px;
      float: right;
    }
    .attributes-tab-div{
      position: absolute;
      right: 0;
      background-color: white;
      border-left: 1px #e1e8ed solid;
      z-index: 10;
    }
    .attributes-tab-div-closed {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: center;
      width: 0px;
      overflow-y: auto;
      height: calc(100vh - 161px);
      transition: 0.275s;
      background-color: white;
      border-left: 1px #e1e8ed solid;

      .right-side-panel-content{
        display: none;
      }
    }
    #apply-signature-dialog {
      overflow-y: auto;
      max-height: 90% !important;
    }

  }

  @media (max-width: $phone) {
    .file-pond-block-wrapper{
      width: 200px;
    }

  }

  .display-name-edition-mode {
    font-family: $font-family-bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-500;
    text-align: left;
    padding-top: 5px
  }
  .btn-custom-title {
    margin: 0px 4px;
    min-width: 24px;
    min-height: 24px;
    background-color: $grey-75;
    border-radius: 8px;
  }
  ::v-deep .btn-custom-title .md-button .md-ripple {
    padding: 0px 4px;
  }

  ::v-deep .btn-custom-title .md-button-content {
    display: flex;
  }

  #dynamic-input {
    position: relative;
    min-width: 1em;
    width: min-content;
  }

  #dynamic-input>span#size-calibration {
    visibility: hidden;
    white-space: pre;
  }

  #dynamic-input>input#document-display-name {
    position: absolute;
    left: 0;
    width: 100%;
  }
  ::v-deep .badge-packagers .md-badge {
    width: 29px;
    font-size: 10px !important;
  }
  .legal-note {
    margin-top: auto;
    padding-inline: 8px;
  }
</style>
