<template>
  <div class="main-div">
    <div class="md-layout top-div">
      <VasionInput
        v-model="password"
        inputType="top-white"
        class="input-style"
        specialType="password"
        :width="'405'"
        :title="inputTitle"
        :isInErrorState="passwordError"
      />
    </div>
    <div class="bottom-div">
      <VasionButton id="cancel-button" :classProp="'primary-light'" @vasionButtonClicked="closePrompt()">
        CANCEL
      </VasionButton>
      <VasionButton
        id="ok-button"
        :classProp="'primary'"
        :isDisabled="disableOKButton"
        @vasionButtonClicked="okClick()"
      >
        OK
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordPrompt',
  props: {
    defaultPasswordProp: {
      type: String,
      required: false,
      default: '',
    },
    inputTitle: {
      type: String,
      required: false,
      default: 'Password',
    },
    requirePasswordEntry: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      miscKey: 'ChangePasswordValue',
      password: this.defaultPasswordProp,
      passwordError: false,
    }
  },
  computed: {
    disableOKButton() { return this.requirePasswordEntry && this.password === '' },
  },
  methods: {
    closePrompt() {
      this.$emit('close')
    },
    okClick() {
      this.$emit('passwordEntered', this.password)
    },
    ref_setErrorClass() {
      this.passwordError = true
    },
  },
}
</script>

<style lang="scss" scoped>

  .main-div {
    width: 100%;
    height: 100%;
    margin: 32px 24px 10px 24px;
  }

  #show-hide-password{
    padding-top: 17px;
  }

  .top-div {
    width: 405px;
  }

  .bottom-div {
    bottom: 24px;
    width: calc(100% - 48px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
    margin-top: 2rem;
  }

  .alignRight {
    text-align: right;
  }

</style>
